/*eslint-disable */
import { States } from '../../../store/states'

export default class Part {
  constructor ({comment,
    id,
    price,
    firstPrice = null,
    status,
    qty,
    number = '',
    rrp = 0, additionalSuppId = null,
    firstRrp = null,
    name = '', linkType = '',
    gstTax = 10, originalPartId = 0, stock = 0, altPartName = '',
    stockComment = '', linkId = null, type = '',
    deliveryName = '', deliveryZone = '', listOrderNumber = null, listOrderNumber2 = null,
    optionalParentPartId = null, optionalLink = null, nonReturnableAt = null, isExcludeFromDirectOrder = false,
    groupSelectId = null, nonReturnableBy = null,oePartNumber = null,
    isNotApplicable = false, nonReturnable = false, rebate = null, oemListPrice = null,parentSuppId  = null,
    isRemovedByRepairer = false, oemPartNumber, beforeMarkNotApplicable = null, isManualByOemSupplier = false,
    originalQty = 1, isParentPart = false, parentRfqToSupplierStatus = '', isAdditional = false, additionalRfqToSupplierStatus = '',photoForPart = null, condition = null
               }) {


    this.id = Number(id)
    this.price = Number(price)
    this.price2 = price
    this.firstPrice = (firstPrice) ? Number(firstPrice) : null
    this.oemListPrice = (_.isNumber(oemListPrice) || _.isString(oemListPrice)) ? oemListPrice : null
    this.status = status
    this.nonReturnableAt = nonReturnableAt
    this.isExcludeFromDirectOrder = isExcludeFromDirectOrder
    this.qty = qty ? Number(qty) : 1
    this.originalQty = Number(originalQty)
    this.additionalSuppId = additionalSuppId
    this.parentSuppId = parentSuppId
    this.comment = comment
    this.name = name
    this.rebate = rebate
    this.nonReturnableBy = nonReturnableBy
    this.partNumber = (number) ? number.toUpperCase() : ''
    this.rrp = rrp
    this.firstRrp = (firstRrp) ?? null
    this.gstTax = gstTax
    this.beforeMarkNotApplicable = beforeMarkNotApplicable
    this.stock = Number(stock)
    this.linkType = linkType
    this.linkId = Number(linkId)
    this.stockComment = stockComment
    this.oePartNumber = oePartNumber
    if (oemPartNumber) {
      this.oemPartNumber = oemPartNumber
    }
    this.isNotApplicable = isNotApplicable
    this.nonReturnable = nonReturnable
    this.isManualByOemSupplier = isManualByOemSupplier
    this.alternativePartName = altPartName
    this.photoForPart = photoForPart
    this.condition = condition
    this.originalPartId = Number(originalPartId) // || Number(partId);
    //this.isManual = isManual;  /moved to method
    this.listOrderNumber = listOrderNumber
    this.listOrderNumber2 = listOrderNumber2
    this.isParentPart = isParentPart
    this.isAdditional = isAdditional
    this.parentRfqToSupplierStatus = parentRfqToSupplierStatus
    this.additionalRfqToSupplierStatus = additionalRfqToSupplierStatus
    this.type = type
    if (type === 'delivery') {
      this.deliveryName = deliveryName
      this.deliveryZone = deliveryZone
    }
    if (type === 'optional') {
      this.optionalParentPartId = optionalParentPartId //double of optionalLink
      this.optionalLink = optionalLink
    }
    this.groupSelectId = groupSelectId
    this.isRemovedByRepairer = isRemovedByRepairer
    this.isClickedOnWatingText = false
  }

  /**
   * Get part number
   * @return {string|string}
   */
  get number () {
    return (this.partNumber) ? this.partNumber.toUpperCase() : ''
  }

  /**
   * Get alt part name
   * @return {string|string}
   */
  get altPartName () {
    return (this.alternativePartName) ? this.alternativePartName.toUpperCase() : ''
  }

  /**
   *  Is price set
   * @returns {boolean}
   */
  get priceCompleted () {
    return this.price >= 0
  }

  /**
   * Percent a part in stock
   * @returns boolean
   */
  get isInStock () {
    return (this.stock === 1 || this.stock === 2 || this.stock === 6)
  }

  /**
   * Percent a part in stock
   * @returns boolean
   */
  get isNoStock () {
    return (this.stock === 3)
  }

  get isNoLongerAvailable () {
    return (this.stock === 5)
  }

  /**
   * Is type delivery
   * @return {boolean}
   */
  get isDelivery () {
    return this.type === 'delivery'
  }

  /**
   * Is type optional
   * @return {boolean}
   */
  get isOptional () {
    return this.type === 'optional'
  }

  /**
   * Is type manual
   * @return {boolean}
   */
  get isManual () {
    return this.type === 'manual'
  }

  /**
   * Is type Original Part from Repairer
   * @return {boolean}
   */
  get isOriginalPart () {
    return !this.isManual && !this.isOptional && !this.isDelivery
  }

  /**
   * Return price with count
   * @return {number}
   */
  get priceWithCount () {
    return Number(this.price) * this.qty
  }

  /**
   * Get total part price
   * @returns number
   */
  get total () {
    return parseFloat(Math.abs((this.priceWithCount * 1.1).toFixed(5)).toFixed(2))
  }

  /**
   * Get gst sum
   * @return {number}
   */
  get gst () {
    return this.gstTax * (this.priceWithCount / 100)
  }

  /**
   * Rrp value for OEM
   * @returns string
   */
  rrpOEM () {
    if (this.rrp === -1) {
      return ''
    }
    this.rrp = Number(this.rrp)
    return this.rrp.toFixed(2)
  }

  /**
   * Get selected option
   * @return {{label: string, value: number}}
   */
  get selectedOption () {
    let result = JSON.parse(JSON.stringify(States.stockOptions[0]))
    _.forEach(States.stockOptions, (option) => {
      if (option.value === this.stock && this.stock !== 0) result = JSON.parse(JSON.stringify(option))
    })
    result.comment = this.stockComment
    return result
  }

  /**
   * Check is part linked
   * @return {boolean}
   */
  get isLinked () {
    return Number(this.linkId) > 0
  }

  get isLinkedWithCost () {
    return this.isLinked && this.linkType === 'withCost';
  }

  get isLinkedWithNoCost () {
    return this.isLinked && this.linkType === 'withNoCost';
  }

  get isNotApplicable () {
    return !!this.isNotApplicable2
  }

  set isNotApplicable (val) {
    this.isNotApplicable2 = val
  }

  /**
   * Set selected option
   * @param val
   */
  set selectedOption (val) {
    this.stock = val.value
    if (!_.isEmpty(val.comment)) this.stockComment = val.comment
  }


  /**
   *  Set part number
   * @param val
   */
  set number (val) {
    this.partNumber = val
  }

  /**
   *  Set alt part name
   * @param val
   */
  set altPartName (val) {
    this.alternativePartName = val
  }

  /**
   * flagged
   * when other than 'in stock' status selected
   */
  get flagged() {
    return  this.selectedOption.value !== 1 && this.selectedOption.value !== 4 && this.selectedOption.value !== 5 && this.selectedOption.value !== 6 && this.selectedOption.value !== 0; // NOT 'in stock' are flagged
  }

  /**
   * isReady
   *
   * determine if part can be ordered
   * it is only with status:
   * - 1 - 'in stock'
   * - 2 - 'low stock'
   * ref: states.js
   */
  get isReady () {
    return this.selectedOption.value === 1 || this.selectedOption.value === 2 || this.selectedOption.value === 6 || this.selectedOption.value === 4
  }

}
