/* eslint-disable */
import Part from './Part'
import Offer from './Offer'
import store from '../../../store'

export default class Supplier {
  /**
   * Constructor
   *
   * @param id
   * @param name
   * @param type
   * @param parts
   * @param offers
   * @param totals
   * @param pricingCompleted
   * @param pricingCompletedStr
   * @param discountsApplied
   * @param partsDeliveryETA
   * @param logo
   * @param rfqToSupplierStatus
   * @param isRemovedByRepairer
   */
  constructor ({
    id,
    name,
    type,
    partsType,
    parts,
    offers,
    totals,
    pricingCompleted,
    parentRfqToSupplierStatus,
    additionalRfqToSupplierStatus,
    pricingCompletedStr,
    discountsApplied,
    quoteValidityPeriod,
    partsDeliveryETA, isAdditional, isParent,
    logo,
    rfqToSupplierStatus,
    supplierQuoteNumber,
    repairerQuoteNumber,
      nonReturnableRules,
    dmsQuoteNumber,
    profitOrdered,
    supplierId,
    quoteValidTill,
    quoteDateValidTill,
    clickedParts,
    unClickedParts,
    declineReason, additionalIds, parentIds,
    rebate,
    checkedParts,
    isSentRequestToUpdate,
      rrp,
  }) {
    let partsObj = {}
    parts = _.orderBy(parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
    _.forEach(parts, part => {
      let partObj = new Part(part)
      if (!partObj.isManual && !partObj.isDelivery && !partObj.isOptional) {
        partsObj[part.originalPartId] = partObj
      } else {
        // for manual parts, collect in "0" key
        partsObj[part.originalPartId] = (partsObj[part.originalPartId] || {})
        partsObj[part.originalPartId][part.id] = partObj
      }
    })

    let offersObj = []
    offers = _.orderBy(offers, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
    _.forEach(offers, offer => {
      let offerObj = new Offer(offer)
      offersObj.push(offerObj)
    })

    this.id = id
    this.name = name
    this.type = type
    this.partsType = partsType
    this.additionalIds = additionalIds
    this.parentIds = parentIds
    if (store?.getters?.['currentUser/isUserTypeRepairer']) {
      this.partsType = type
    }
    this.instruction = 'Complete'
    this.rebate = rebate
    this.parentRfqToSupplierStatus = parentRfqToSupplierStatus
    this.additionalRfqToSupplierStatus = additionalRfqToSupplierStatus
    this.supplierId = supplierId
    this.isAdditional = isAdditional
    this.isParent = isParent
    this.parts = partsObj
    this.declineReason = declineReason
    this.offers = offersObj
    this.pricingCompleted = Number(pricingCompleted)
    this.pricingCompletedStr = pricingCompletedStr
    this.discount = Number(discountsApplied) / 100
    this.lowestTotalPrice = false
    this.partsDeliveryETA = partsDeliveryETA
    this.priceTotal = Number(totals)
    this.reward = ''
    this.logo = logo
    this.rrp = rrp
    this.orderNumber = ''
    this.orderId = ''
    this.orderIds = []
    this.rfqToSupplierStatus = rfqToSupplierStatus
    this.dmsQuoteNumber = dmsQuoteNumber
    this.quoteValidTill = quoteValidTill
    this.quoteDateValidTill = quoteDateValidTill
    this.supplierQuoteNumber = supplierQuoteNumber
    this.repairerQuoteNumber = repairerQuoteNumber
    this.nonReturnableRules = !_.isEmpty(nonReturnableRules) ? nonReturnableRules : {
      requireAcceptTncs: false,
      displayTncs: false,
      requireESign: false,
      requireNameAndPosition: false,
      tncsText: '',
    }
    this.profitOrdered = profitOrdered
    this.quoteValidityPeriod = quoteValidityPeriod ? quoteValidityPeriod : null

    this.selectedParts = []
    this.clickedParts = clickedParts ? clickedParts : []
    this.unClickedParts = unClickedParts ? unClickedParts : []
    this.isSentRequestToUpdate = isSentRequestToUpdate ? true : false
  }

  getPartsLength () {
    let r = 0
    _.forIn(this.parts, (p, id) => {
      if (id == 0) {
        _.forIn(p, (mP) => {
          r++
        })
      } else {
        r++
      }
    })
    return r
  }

  get priceTotalCalc () {
    if (this.rfqToSupplierStatus == 'New' || this.rfqToSupplierStatus == 'Closed') return 0
    if (this.isSupplierTypeOEM || this.isSupplierTypeRECO) {
      return this.priceTotal
    }
    let priceTotal = 0
    _.forEach(this.offers, (offer) => {
      let id = offer.originalPartId
      if (offer.type === 'delivery' || offer.type === 'delivery') {
        id = offer.id
      }
      let part = this.getPart(id)
      if (part && (part.isParentPart || part.isAdditional)) {
        return
      } else if (offer.isOptional && offer.isOfferSelected(this)) { //Опциональный и выбран - тогда плюсуем
        priceTotal += offer.price
      } else if (offer.isOptional && !offer.isOfferSelected(this)) { //Опциональный и не выбран - тогда пропускаем
        //      console.log('notSelected and notOptional', offer.id);
      } else if (offer.hasOptional(this) && offer.isSelectedOfferOfPart(this)) { //Имеет опциональный иесть выбранный из опциональных
        //  console.log('notSelected', offer.id);
      } else {
        priceTotal += offer.price
      }
    })

    return priceTotal
  }

  /**
   * getOffer by part
   * @param Part part
   * @returns Offer
   */
  getOffer (part) {
    let offer = {}
    if (!part) {
      return false
    }
    if (part.isOriginalPart) {
      offer = _.find(this.offers, function (i) {
        return Number(i.originalPartId) === Number(part.id)
      })
    } else {
      offer = _.find(this.offers, function (i) {
        return Number(i.id) === Number(part.id)
      })
    }
    if (!offer) {
      return false
    }
    return offer
  }

  /**
   * getAllOptionalParts - return all Optional Offers of this Part and Supplier
   *
   * @param Offer currentOffer
   * @return Offer[]
   */
  getAllOptionalParts (currentOffer) {
    let offers = []
    _.forEach(this.offers, (offer) => {
      if (currentOffer.isOptional) {
        if (Number(currentOffer.optionalLink) > 0 && Number(currentOffer.optionalLink) === Number(offer.optionalLink)) {
          offers.push(offer)
        }
      } else {
        if (Number(currentOffer.originalPartId) > 0 && Number(currentOffer.originalPartId) === Number(offer.optionalLink)) {
          offers.push(offer)
        }
      }
    })
    return offers
  }

  /**
   * Profit
   * @returns {number}
   */
  get priceSaving () {
    let listPrice = 0,
      profit = 0

    if (this.rfqToSupplierStatus == 'New' || this.rfqToSupplierStatus == 'Closed') return 0

    _.forEach(this.parts, part => {
      if ((!part.stock || part.stock == 3 || part.stock == 5) && !(part.price > 0)) return
      let rrp = Number(part.rrp)
      if (rrp > 0) listPrice += Number(part.rrp) * Number(part.qty)
    })

    if (!_.isEmpty(this.parts[0])) {
      _.forEach(this.parts[0], part => {
        if ((!part.stock || part.stock == 3 || part.stock == 5) && !(part.price > 0)) return
        let rrp = Number(part.rrp)
        if (rrp > 0) listPrice += Number(part.rrp) * Number(part.qty)
      })
    }

    profit = listPrice - this.priceTotalCalc

    console.log('profit = listPrice - this.priceTotalCalc', profit, listPrice, this.priceTotalCalc, this.id)
    return (profit >= 0) ? profit : 0
  }

  /**
   * Profit for selected
   * The profit is the difference between the list price minus the part price
   * @returns {number}
   */
  get priceSavingSelected () {
    let listPrice = 0,
      profit = 0
    if (this.selectedParts.length) {
      this.selectedParts.forEach(id => {
        listPrice += Number(this.getPart(id).rrp) * this.getPart(id).qty
      })
    }
    profit = listPrice - this.priceSelected

    return (profit >= 0) ? profit : 0 //* this.discount;
  }

  /**
   * Profit for ordered
   * The profit is the difference between the list price minus the part price
   * @returns {number}
   */
  get priceSavingOrdered () {
    let listPrice = 0,
      profit = 0
    if (this.orderedParts && this.orderedParts.length) {
      this.orderedParts.forEach(op => {
        if (this.getPart(op.partId)) {
          listPrice += (Number(this.getPart(op.partId).rrp) - op.price) * this.getPart(op.partId).qty
        }
      })
    }
    profit = listPrice

    return (profit >= 0) ? profit : 0 //* this.discount;
  }

  /**
   * Price of selected parts
   * @returns {number}
   */
  get priceSelected () {
    if (this.selectedParts.length) {
      let result = 0
      this.selectedParts.forEach(id => {
        let part = this.parts[id]
        if (_.isEmpty(part)) part = this.parts[0][id]
        if (part && (part.isParentPart || part.isAdditional)) {
          return
        }
        result += part.price * part.qty
      })
      return result
    }
    return 0
  }

  /**
   * Price of selected parts with discount
   * @returns {number}
   */
  get priceSelectedWithDiscount () {
    return (this.discount) ? this.priceSelected - this.priceSelected * this.discount : this.priceSelected
  }

  /**
   * Count of parts
   *
   * @returns {number}
   */
  get countParts () {
    let manualPartsCnt = (!_.isEmpty(this.parts[0])) ? _.filter(this.parts[0], (part) => !part.isParentPart && !part.isAdditional && !part.isNotApplicable && !part.linkId && !part.isDelivery || (part.isDelivery && part.price)).length : 0
    return _.filter(this.parts, (part) => (!part.isParentPart && !part.isAdditional && !part.isNotApplicable && !part.linkId && part.isOriginalPart === true && part.stock > 0 && part.price > 0)).length + manualPartsCnt
  }

  get countPartForInStock () {
    let manualPartsCnt = (!_.isEmpty(this.parts[0])) ? _.filter(this.parts[0], (part) => !part.isParentPart && !part.isAdditional && (!part.isNotApplicable && !part.isDelivery)).length : 0
    return _.filter(this.parts, (part) => (!part.isParentPart && !part.isAdditional && part.id) && ((!part.isNotApplicable && part.isOriginalPart === true && part.stock > 0))).length + manualPartsCnt
  }

  get countPartsForSelection () {
    let manualPartsCnt = 0
    if (!_.isEmpty(this.parts[0])) {
      manualPartsCnt = _.filter(this.parts[0], (part) => {
        return !part.isParentPart && !part.isAdditional && !part.isNotApplicable && !part.isDelivery
      }).length
    }

    return _.filter(this.parts, (part) => {
      if (part.isLinked) {
        return !part.isParentPart && !part.isAdditional && !part.isNotApplicable
      } else {
        return !part.isParentPart && !part.isAdditional && !part.isNotApplicable && part.isOriginalPart === true && part.stock > 0
      }
    }).length + manualPartsCnt
  }

  /**
   * Percent of parts in stock
   * @returns {number}
   */
  get inStock () {
    let count = this.countParts
    let res = 0
    if (count > 0) {
      for (const key in this.parts) {
        if (key === '0') {
          res += _.filter(this.parts[0], (part) => !part.isParentPart && !part.isAdditional && (!part.isDelivery && (part.stock === 1 || part.stock === 2)) && !part.isNotApplicable).length
        } else if (!this.parts[key].isParentPart && !this.parts[key].isAdditional && ((this.parts[key].stock === 1 || this.parts[key].stock === 2) && !this.parts[key].isNotApplicable)) {
          res += 1
        }
      }
      // res = res / count;
    }

    return res
  }

  get inStockWithoutPercent () {
    let count = this.countParts
    let res = 0
    if (count > 0) {
      for (const key in this.parts) {
        if (key === '0') {
          res += _.filter(this.parts[0], (part) => !part.isParentPart && !part.isAdditional && (!part.isDelivery && (part.stock === 1 || part.stock === 2) && !part.isNotApplicable)).length
        } else if (!this.parts[key].isParentPart && !this.parts[key].isAdditional && ((this.parts[key].stock === 1 || this.parts[key].stock === 2) && !this.parts[key].isNotApplicable)) {
          res += 1
        }
      }
      // res = res / count;
    }

    return res
  }

  get inStockWithoutPercentSelected () {
    let count = this.selectedParts.length

    let res = 0
    if (count > 0) {
      for (const key in this.parts) {
        if (key === '0') {
          res += _.filter(this.parts[0], (part) => _.includes(this.selectedParts, _.toString(part.originalPartId)) && !part.isParentPart && !part.isAdditional && (!part.isDelivery && (part.stock === 1 || part.stock === 2) && !part.isNotApplicable)).length
        } else if (_.includes(this.selectedParts, _.toString(this.parts[key]?.originalPartId)) && !this.parts[key].isParentPart && !this.parts[key].isAdditional && ((this.parts[key].stock === 1 || this.parts[key].stock === 2) && !this.parts[key].isNotApplicable)) {
          res += 1
        }
      }
      // res = res / count;
    }

    return res
  }

  /**
   * Is parts can be order
   *
   * @returns {boolean}
   */
  get partsCanBeOrdered () {
    return this.selectedParts.length > 0
  }

  /**
   * Get selected parts ids
   * @returns {[]}
   */
  getSelectedPartsIds () {
    let result = []
    this.selectedParts.forEach(id => {
      let part = this.getPart(id)
      if (!_.isEmpty(part)) result.push(part.id)
    })
    return result
  }

  /**
   * Is all parts with price
   * @param allParts
   * @returns {boolean}
   */
  isAllPriced (allParts) {
    let result = true
    _.forEach(allParts, part => {
      if (part.isOriginalPart && (_.isEmpty(this.parts[part.id]) || !this.parts[part.id].priceCompleted)) {
        result = false
      }
    })
    return result
  }

  /**
   * Is part selected
   * @param partId
   * @returns {boolean}
   */
  isPartSelected (partId) {
    return _.indexOf(this.selectedParts, partId) >= 0
  }

  isPartChecked (partId) {
    let result = _.some(this.checkedParts, (itm) => {
      return (Number(itm.originalPartId) === Number(partId) || Number(itm.supplierPartId) === Number(partId))
    })
    return result
  }

  /**
   * Select part
   * @param id
   */
  selectPart (id, isIgnoreLinkRule = false, isIgnoreNoStock = false) {
    if (this.rfqToSupplierStatus == 'New' || this.rfqToSupplierStatus == 'Closed') return
    if ((!_.isEmpty(this.parts[id]) && this.parts[id].price && !this.parts[id].isParentPart && !this.parts[id].isAdditional) || (!_.isEmpty(this.parts[0]) && !_.isEmpty(this.parts[0][id]) && this.parts[0][id].price && !this.parts[0].isParentPart)) {
      if (this.selectedParts.includes(id)) {
        return
      }

      let part = !_.isEmpty(this.parts[id]) ? this.parts[id] : this.parts[0][id]

      if ((part.isNoStock && !isIgnoreNoStock) || part.isNoLongerAvailable || part.isNotApplicable) {
        return
      }

      this.selectedParts.push(id)
      if (!_.isEmpty(this.parts[id])) {
        this.parts[id].suppliedBy = this.id
      } else {
        this.parts[0][id].suppliedBy = this.id
      }

      let originalPartId = 0
      if (!_.isEmpty(this.parts[id])) {
        originalPartId = this.parts[id].id
      } else {
        originalPartId = this.parts[0][id].id
      }

      let linkedParts = []
      let selectedLinkedParts = []
      _.forIn(this.parts, (p, id) => {
        if (id == 0) {
          _.forIn(this.parts[0], (mP) => {
            if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(originalPartId)) {
              linkedParts.push(mP)
            }
          })

        } else {
          if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(originalPartId)) {
            linkedParts.push(p)
          }
        }
      })
      _.forEach(linkedParts, (p) => {
        if (this.selectedParts.includes(_.toString(p.id)) || this.selectedParts.includes(_.toString(p.originalPartId))) {
          selectedLinkedParts.push(p)
        }
      })
      let isCanSelectWithCostParts = (!this.clickedParts.includes(id) && !_.isEmpty(linkedParts) && _.isEmpty(selectedLinkedParts)) || isIgnoreLinkRule

      _.forIn(this.parts, (prt, prtId) => {
        let additionalParts = null
        if (!prt.id && Object.keys(prt)[0] && prt[Object.keys(prt)[0]].id) {
          additionalParts = prt
        }

        if (prt && prt.isLinked && Number(prt.linkId) === Number(originalPartId)) {
          if (this.selectedParts.includes(prtId)) {
            return
          }
          if (!isCanSelectWithCostParts && prt.linkType === 'withCost') {
            return
          }
          this.selectedParts.push(prtId)
          if (!_.isEmpty(this.parts[prtId])) {
            this.parts[prtId].suppliedBy = this.id
          } else {
            this.parts[0][prtId].suppliedBy = this.id
          }
        } else if (!_.isEmpty(additionalParts)) {
          _.forIn(additionalParts, (additionalPrt, addPrtId) => {
            if ((Number(additionalPrt.linkId) === Number(originalPartId) && (additionalPrt.linkType === 'withNoCost' || (isCanSelectWithCostParts && additionalPrt.linkType === 'withCost'))) || (additionalPrt.type === 'delivery')) {
              if (this.selectedParts.includes(addPrtId)) {
                return
              }
              this.selectedParts.push(addPrtId)
              if (!_.isEmpty(this.parts[addPrtId])) {
                this.parts[addPrtId].suppliedBy = this.id
              } else {
                this.parts[0][addPrtId].suppliedBy = this.id
              }
            }
          })
        }
      })

      if (isCanSelectWithCostParts && !isIgnoreLinkRule) {
        this.clickedParts.push(id)
      }
    }
  }

  /**
   * Unselect part
   * @param id
   */
  removeSelectedPart (id) {
    let originalPrtId = this.parts?.[this.selectedParts?.[this.selectedParts.indexOf(id)]]?.id
    let linkedParts = []
    let selectedLinkedParts = []
    _.forIn(this.parts, (p, id) => {
      if (id == 0) {
        _.forIn(this.parts[0], (mP) => {
          if (Number(mP.linkId) > 0 && mP.linkType === 'withCost' && Number(mP.linkId) === Number(originalPrtId)) {
            linkedParts.push(mP)
          }
        })

      } else {
        if (Number(p.linkId) > 0 && p.linkType === 'withCost' && Number(p.linkId) === Number(originalPrtId)) {
          linkedParts.push(p)
        }
      }
    })
    _.forEach(linkedParts, (p) => {
      if (this.selectedParts.includes(_.toString(p.id)) || this.selectedParts.includes(_.toString(p.originalPartId))) {
        selectedLinkedParts.push(p)
      }
    })
    let isCanDeselectWithCostParts = !this.unClickedParts.includes(id) && !_.isEmpty(linkedParts) && !_.isEmpty(selectedLinkedParts) && linkedParts.length === selectedLinkedParts.length

    if (this.parts?.[this.selectedParts?.[this.selectedParts.indexOf(id)]]?.id) {
      _.forIn(this.parts, (prt, prtId) => {
        if (Number(prtId) === 0) {
          _.forIn(prt, (additionalPrt, addPrtId) => {
            console.log(originalPrtId, additionalPrt, this.selectedParts)
            if (additionalPrt && additionalPrt.isLinked && Number(additionalPrt.linkId) === Number(originalPrtId)) {
              if (additionalPrt.linkType === 'withCost') {
                if (isCanDeselectWithCostParts) {
                  let ind = this.selectedParts.indexOf(addPrtId)
                  this.selectedParts.splice(ind, 1)
                }
              } else {
                let ind = this.selectedParts.indexOf(addPrtId)
                this.selectedParts.splice(ind, 1)
              }
            }
          })
        } else {
          if (prt && prt.isLinked && Number(prt.linkId) === Number(originalPrtId)) {
            if (prt.linkType === 'withCost') {
              if (isCanDeselectWithCostParts) {
                let ind = this.selectedParts.indexOf(prtId)
                this.selectedParts.splice(ind, 1)
              }
            } else {
              let ind = this.selectedParts.indexOf(prtId)
              this.selectedParts.splice(ind, 1)
            }
          }
        }
      })
    }

    if (isCanDeselectWithCostParts) {
      this.unClickedParts.push(id)
    }
    let deliveryPartsLenght = 0
    if (this.parts[0]) {
      let key = Object.keys(this.parts[0])[0]
      if (key && this.parts[0][key] && this.parts[0][key].id) {
        _.forIn(this.parts[0], (delPrt, delPrtId) => {
          if (delPrt.type !== 'delivery') {
            return
          }
          deliveryPartsLenght++
        })
      }
    }

    if (deliveryPartsLenght && this.selectedParts.length === deliveryPartsLenght + 1) {
      _.forIn(this.parts[0], (delPrt, delPrtId) => {
        if (delPrt.type !== 'delivery') {
          return
        }
        if (this.selectedParts.includes(String(delPrtId))) {
          const index = this.selectedParts.indexOf(String(delPrtId))
          this.selectedParts.splice(index, 1)
        }
      })
    }

    const index = this.selectedParts.indexOf(id)
    this.selectedParts.splice(index, 1)
  }

  /**
   * Select all parts
   */
  selectAllParts (isIncludeNoStock = false) {
    console.log('Started rfq/model/selectAllParts', this.parts)
    Object.keys(this.parts).forEach(id => {
      if (this.parts[id].isReady) {
        this.selectPart(id)
      } else if (!isIncludeNoStock && this.parts[id].price > 0 && this.parts[id].isNoStock) {
        this.selectPart(id)
      } else if (this.parts[id].stock !== 5 && isIncludeNoStock && this.parts[id].price > 0) {
        this.selectPart(id)
      }
      // else if (this.parts[id].price > 0 && this.parts[id].linkId > 0 && this.parts[id].linkType === 'withCost') {
      //     this.selectPart(id);
      // }
    })
    if (!_.isEmpty(this.parts[0])) {
      Object.keys(this.parts[0]).forEach(id => {
        if (!this.parts[0][id].isOptional) { //Кроме Optional
          if (this.parts[0][id].isReady || this.parts[0][id].isDelivery) {
            this.selectPart(id)
          } else if (!isIncludeNoStock && this.parts[0][id].price > 0 && this.parts[0][id].isNoStock) {
            this.selectPart(id)
          } else if (this.parts[0][id].stock !== 5 && isIncludeNoStock && this.parts[0][id].price > 0) {
            this.selectPart(id)
          }
          // else if (this.parts[0][id].price > 0 && this.parts[0][id].linkId > 0 && this.parts[0][id].linkType === 'withCost') {
          //     this.selectPart(id);
          // }
        }
      })
    }
  }

  /**
   * Unselect all parts
   */
  unselectAllParts () {
    this.selectedParts = []
  }

  /**
   * Get part by id
   * @param id
   * @returns Offer || false
   */
  getOfferById (id) {
    let offer = _.find(this.offers, function (i) {
      return Number(i.id) === Number(id)
    })
    if (!offer) {
      return false
    }
    return offer
  }

  /**
   * Get part by id
   * @param id
   * @returns {*}
   */
  getPart (id) {
    let currentPart = {}
    if (!_.isEmpty(this.parts[Number(id)])) {
      currentPart = this.parts[Number(id)]
    } else if (!_.isEmpty(this.parts[0]) && !_.isEmpty(this.parts[0][Number(id)])) {
      currentPart = this.parts[0][Number(id)]
    }
    return currentPart
  }

  /**
   * Is part created
   * @param id
   * @return {boolean}
   */
  isPartCreated (id) {
    return !_.isEmpty(this.getPart(id))
  }

  get isSupplierTypeOEM () {
    return this.isSupplierTypeOEMGEN
  }

  get isSupplierTypeOEMGEN () {
    return (this.partsType === 'OEM-GEN' || this.partsType === 'OEM')
  }

  get isSupplierTypeOEMPAR () {
    return this.partsType === 'OEM-PAR'
  }

  get isSupplierTypeCERTAFTM () {
    return this.partsType === 'CERT-AFTM'
  }

  get isSupplierTypeOEMAlt () {
    return this.partsType === 'OEM-ALT'
  }

  get isSupplierTypeUSED () {
    return this.partsType === 'USED'
  }

  get isSupplierTypeAFTM () {
    return this.partsType === 'AFTM'
  }

  get isSupplierTypeRECO () {
    return this.partsType === 'RECO'
  }

  get isRfqToSupplierStatusDecline () {
    return this.rfqToSupplierStatus === 'Decline'
  }

}
