<script>
/*eslint-disable */
import NProgress from "nprogress";
import Axios from "axios";
import Email from "../../components/auth/Email";

export default {
    name: "ForgotUserDataPage",
    components: {
        Email
    },
    data() {
        return {
            userEmail: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            companyName: "",
            isFirstName: true,
            isLastName: true,
            isContactNumber: true,
            isCompanyName: true
        };
    },
    methods: {
        sendForgotRequest() {
            if(!this.isCheckFields()) return;
            NProgress.start();
            Axios({url: this.getUrl(), data: {
                email: this.userEmail,
                firstName: this.firstName,
                lastName: this.lastName,
                contactNumber: this.contactNumber,
                companyName: this.companyName
            }, method: 'POST'}).then(resp => {
                if(resp && resp.data && resp.data.status) this.$router.push('/login');
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                NProgress.done();
            });
        },
        checkFirstName(){
            this.isFirstName = !_.isEmpty(this.firstName);
        },
        checkLastName(){
            this.isLastName = !_.isEmpty(this.lastName);
        },
        checkContactNumber() {
            this.isContactNumber = !_.isEmpty(this.contactNumber);
        },
        checkCompanyName(){
            this.isCompanyName = !_.isEmpty(this.companyName);
        },
        isCheckFields(){
            this.checkFirstName();
            this.checkLastName();
            this.checkContactNumber();
            this.checkCompanyName();
            return !(!this.isFirstName || !this.isLastName || !this.isContactNumber || !this.isCompanyName);
        },
        getUrl(){
            return '/ir/auth/forgot/username/support';
        },
        getTitle(){
            return 'Username';
        },
        goToHome() {
            document.location.replace(appConfig.homeURL + '/');
            // this.$router.push('/');
        },
    }
};
</script>

<template>
    <div class="container-fluid page">
        <div class="content">
            <div class="background column">
                <img src="@/assets/img/login-background.jpg" alt="partsearch"/>
            </div>
            <div class="form-container column">
                <div class="form">
                    <div class="auth-header">
                        <div class="logo">
                            <div @click.prevent="goToHome" class="logo-text">
                                <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                            </div>
                        </div>
                        <span class="close" @click="$router.push('/')">×</span>
                    </div>
                    <form method="POST" @submit.prevent="sendForgotRequest">
                        <h2 class="header-text">Forgot your {{ getTitle() }}?</h2>
                        <p>Get Help by providing us with more information about your account and one of our team members
                            will contact you.</p>
                        <br/>
                        <p>Enter the Email Address That Was Registered With Your Account</p>
                        <div class="form-group">
                            <email v-model="userEmail"></email>
                        </div>
                        <div class="form-group">
                            <label for="firstname">First Name</label>
                            <input
                                type="text"
                                class="input-field"
                                :class="{'error':!isFirstName}"
                                placeholder="Your First Name"
                                name="firstname"
                                v-model="firstName"
                                @change="checkFirstName()"
                            />
                            <div v-if="!isFirstName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lastName">Last Name</label>
                            <input
                                type="text"
                                class="input-field"
                                :class="{'error':!isLastName}"
                                placeholder="Your Last Name"
                                name="lastName"
                                v-model="lastName"
                                @change="checkLastName()"
                            />
                            <div  v-if="!isLastName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="contactNumber">Contact Number</label>
                            <input
                                type="text"
                                class="input-field"
                                :class="{'error':!isContactNumber}"
                                placeholder="Your Contact Number"
                                name="contactNumber"
                                v-model="contactNumber"
                                @change="checkContactNumber()"
                            />
                            <div v-if="!isContactNumber" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="companyName">Company Name The Account is Registered With</label>
                            <input
                                type="text"
                                class="input-field"
                                :class="{'error':!isCompanyName}"
                                placeholder="Company Name"
                                name="companyName"
                                v-model="companyName"
                                @change="checkCompanyName()"
                            />
                            <div v-if="!isCompanyName" class="w-100 text-right">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;Field is required.
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <button :disabled="!userEmail" type="submit" class="btn input-btn">Submit to Support</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import "../../styles/login/style.css";
</style>

<style scoped>
    .page {
        height: 100vh;
    }

    @media screen and (max-width: 768px) {
        .page .content {
            height: 100%;
        }
        .page .content .form-container {
            height: 100%;
        }
        .page .form {
            height: 100%;
        }
        .page .form form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 30px;
        }
    }
</style>
