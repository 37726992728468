<template>
    <div class="repairer-supplierinvite-page">
        <div class="page-header">
            <h4>View Suppliers</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'RepairerSuppliers'}">View Suppliers</router-link>
                </li>
                <li class="breadcrumb-item active">Invite a Supplier</li>
            </ol>
        </div>
        <div class="repairer-supplierinvite-body">
            <div class="row no-gutter">
                <div class="col-12 col-lg-6 offset-lg-3">
                    <wizard-progress-bar class="mx-auto position-relative" :steps="steps" :step="currentStep"></wizard-progress-bar>
                </div>
            </div>
            <div class="divider"></div>
            <div class="row no-gutter">
                <div class="col-12 col-lg-6 offset-lg-3" v-if="currentStep === 1">
                    <div class="block mt-3" >
                        <div class="block-title mx-3 mx-lg-0">
                            <h4 class="font-18">Add Supplier Details</h4>
                            <p class="font-12">Add Supplier detail to invite</p>
                        </div>

                        <div class="block-header">
                            Supplier Details
                        </div>

                        <div class="block-body bg-white px-3">
                            <b-form-group label-cols="4" label="Business Name" label-for="input-businessname">
                                <b-form-input id="input-businessname" v-model="supplierDetails.businessName" placeholder="Enter business name"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="4" label="Type" label-for="input-type">
                                <multiselect
                                    ref="typeSelect"
                                    v-model="supplierDetails.type.value"
                                    :options="supplierDetails.type.options"
                                    :showLabels="false"
                                    :option-height="32"
                                    :max-height="203"
                                    :close-on-select="true"
                                    v-on:keyup.up="changePositionByArrowKey('typeSelect',$event)"
                                    v-on:keyup.down="changePositionByArrowKey('typeSelect',$event)"
                                >
                                </multiselect>
                            </b-form-group>
                            <b-form-group label-cols="4" label="Phone" label-for="input-phone">
                                <b-form-input id="input-phone" v-model="supplierDetails.phone" placeholder="Enter phone number"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="4" label="Email" label-for="input-email">
                                <b-form-input id="input-email" v-model="supplierDetails.email" placeholder="Enter business email"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="block-footer d-flex justify-content-end px-3 px-lg-0">
                            <router-link class="btn btn-outline-secondary" :to="{ name: 'RepairerSuppliers'}">Cancel</router-link>
                            <b-button @click="nextStep" variant="primary" class="ml-3">Next</b-button>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-8 offset-lg-2" v-if="currentStep === 2">
                    <div class="block mt-3">
                        <div class="block-title mx-3 mx-lg-0">
                            <h4 class="font-18">Invitation Summary</h4>
                            <p class="font-12">The following invitation email will be sent to the Supplier "{{ supplierDetails.businessName }}" on your behalf.</p>
                        </div>

                        <div class="block-header">
                            Invite Supplier
                        </div>

                        <div class="block-body bg-white px-3">
                            <b-form-group label-cols="3" label="To" label-for="input-to" description="Seperate additional email addresses with a comma (,) or a semicolon">
                                <b-form-input id="input-to" v-model="invitation.to" placeholder="Enter recipient email address e.g. parts@mail.com.au" class="theme-color"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="3" label="From" label-for="input-from">
                                <b-form-input id="input-from" v-model="invitation.from" class="font-12" plaintext></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="3" label="Subject" label-for="input-subject">
                                <b-form-textarea id="input-subject" v-model="invitation.subject" placeholder="Enter subject email"></b-form-textarea>
                            </b-form-group>

                            <b-form-group label-cols="3" label="Message" label-for="input-message">
                                <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                                    <div
                                        class="menububble"
                                        :class="{ 'is-active': menu.isActive }"
                                        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">

                                        <button
                                            class="menububble__button"
                                            :class="{ 'is-active': isActive.bold() }"
                                            @click="commands.bold">
                                            <i class='bx bx-bold' ></i>
                                        </button>

                                        <button
                                            class="menububble__button"
                                            :class="{ 'is-active': isActive.italic() }"
                                            @click="commands.italic">
                                            <i class='bx bx-italic' ></i>
                                        </button>

                                        <button
                                            class="menububble__button"
                                            :class="{ 'is-active': isActive.button() }"
                                            @click="commands.button">
                                            <i class='bx bx-rectangle'></i>
                                        </button>

                                    </div>
                                    </editor-menu-bubble>
                                <editor-content class="invite-message" :editor="editor"></editor-content>
                            </b-form-group>

                        </div>

                        <div class="block-footer d-flex justify-content-end px-3 px-lg-0">
                            <b-button @click="previousStep" variant="outline-primary" class="ml-3">Back</b-button>
                            <router-link class="btn btn-outline-secondary ml-3" :to="{ name: 'RepairerSuppliers'}">Cancel</router-link>
                            <b-button @click="nextStep" variant="primary" class="ml-3">Next</b-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import WizardProgressBar from "@/components/WizardProgressBar";
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';
import Button from '../../../components/tiptap/Button';
import Multiselect from 'vue-multiselect';

import {
  Blockquote,
  BulletList,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'

export default {
    name: 'supplier-invite',
    components: {
        WizardProgressBar,
        EditorContent,
        EditorMenuBubble,
        Multiselect
    },
    data() {
        return {
            currentStep: 1,
            steps: [
                { label: "Add Supplier Details" },
                { label: "Invitation Summary" }
            ],
            repairer: {
                name: 'Peter',
                businessName: 'AAA Smash Repair'
            },
            supplierDetails: {
                businessName: 'Sydney City Toyota',
                type: {
                    value: 'OEM',
                    options: [ 'OEM', 'ODM', 'EMS', 'CEM']
                },
                phone: '0281178494',
                email: 'parts@sydneycitytoyota.com.au'
            },
            invitation: {
                to: 'parts@sydneycitytoyota.com.au',
                from: 'Sales@LandRover.com.au',
                subject: ``,
                message: '',
                messageButtonText: 'Register as a Supplier',
                registerUrl: '#'
            },
            keepInBounds: true,
            editor: null
        };
    },
    mounted() {
        this.invitation.subject = `${this.repairer.name} from ${this.repairer.businessName} would like to invite Sydney City Toyota to sign-up as a supplier in PartSearch.com.au to quote on parts pricing  for a repair`;
        this.invitation.message =
                `<p>Hi ${this.supplierDetails.businessName}, </p>

                <p>I’d like to invite you to sign-up for PartSearch.com.au to quote on parts pricing on some of our
                repairs.</p>

                <p>PartSearch.com.au is an online parts pricing platform which is free to use for motor vehicle
                parts suppliers.</p>

                <p>Start quoting today with 0% transaction fees on all parts sales.</p>

                <p>Click on the Register Button to create your account today and quote on AAA Smash Repairs
                and other repairers parts pricing requests.</p>
                <p>
                <button href="${this.invitation.registerUrl}" class="btn btn-primary">${this.invitation.messageButtonText}</button>
                </p>
                <p>
                <strong>The PartSearch Team</strong></p>

                <p><small>W:PartSearch.com.au | E:info@partsearch.com.au</small></p>
                `,
        this.editor = new Editor({
            extensions: [
                new Blockquote(),
                new BulletList(),
                new HardBreak(),
                new Heading({ levels: [1, 2, 3] }),
                new ListItem(),
                new OrderedList(),
                new Link(),
                new Bold(),
                new Italic(),
                new Strike(),
                new Underline(),
                new History(),
                new Button()
            ],
            content: this.invitation.message,
            onUpdate: ({ getHTML }) => {
                this.invitation.message = getHTML();
            }
        });
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        nextStep() {
            if (this.currentStep === 2) return;
            this.currentStep += 1;
        },
        previousStep() {
            if (this.currentStep === 1) return;
            this.currentStep -= 1;
        },
        changePositionByArrowKey: function (ref) {
            let el = this.$refs[ref];
            if (el) {
                el.focus();
            }
        }
    }
};
</script>

<!--<style lang="scss">-->
<!--    @import "../../../styles/style-views";-->
<!--</style>-->
