<script>
/*eslint-disable */
import NProgress from "nprogress";

export default {
  name: "InviteCustomer",
  data() {
    return {
      useremail: "",
      message:
        "Hi Type your Customer name here,\n\nI’d like to invite you to sign-up for PartSearch.com.au for competitive\nParts Pricing on your next order.\n\nUse the link below to create you account.Place an order here and\ncompare against other suppliers for great savings on parts pricing\n\nRegards\n\n[Supplier Name]\n\n[Supplier Logo]\n\n[Supplier Business Name]\n\n[Supplier Address]"
    };
  },
  methods: {
    login: function() {
      const username = this.username;
      const password = this.password;
      NProgress.start();
      this.$store
        .dispatch("auth/login", { username, password })
        .then(() => {
          this.$store.dispatch("auth/successLogin");
          NProgress.done();
          this.$router.push("/home");
        })
        .catch(error => {
          NProgress.done();
        });
    }
  }
};
</script>

<template>
  <div class="container-fluid page">
    <br />
    <div class="row w-100 justify-content-center">
      <b-button v-b-modal.custom-modal>Launch demo modal</b-button>
    </div>
    <b-modal id="custom-modal" size="custom" title="Invite Customer">
      <template v-slot:modal-backdrop class="my-backdrop"></template>
      <template v-slot:modal-header>
        <h5 class="modal-title">Invite Customer</h5>
      </template>
      <div class="modal-invite-customer form-container">
        <form>
          <div class="form-group">
            <div class="input-row d-sm-flex flex-sm-row align-items-center">
              <label class="mr-2 mb-0">To</label>
              <input
                type="text"
                placeholder
                class="form-control email-list"
                value="Alexander Sanz@mail.com; Maudy Shorea@mail.com"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-row d-sm-flex flex-sm-row align-items-center">
              <label class="mr-2 mb-0">From</label>
              <span class="w-100">Sales@LandRover.com.au</span>
            </div>
          </div>
          <div class="form-group">
            <div class="input-row d-sm-flex flex-sm-row align-items-center">
              <label class="mr-2 mb-0">Subject</label>
              <input
                type="text"
                readonly
                value="I’d Like to invite you to Sign-Up for PartSearch.com.au for Parts Pricing"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-row d-sm-flex flex-sm-row align-items-start">
              <label class="mr-2 mb-0">Message</label>
              <textarea class="form-control text-message" type="text" :value="message" />
            </div>
          </div>
          <div class="form-group">
            <div class="input-row d-sm-flex flex-sm-row align-items-center">
              <label class="mr-2 mb-0">Send Me a Copy</label>
              <input type="text" placeholder="yourmail@mail.com" class="form-control" />
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button class="input-btn cancel" @click="cancel()">Cancel</b-button>
        <b-button class="input-btn" @click="ok()">Send</b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
@import "../../styles/modal/style.css";
.modal-invite-customer {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.modal-invite-customer .form-control.text-message {
  width: 100%;
  height: 313px;
  border-radius: 3px;
}

.modal-invite-customer .input-row label {
  width: 240px;
  font-size: 12px;
  font-weight: bold;
}

.modal-invite-customer .email-list {
  color: #29bbc1;
}

.modal-invite-customer .form-control {
  height: 31px;
  border-width: 1.5px;
  border-radius: 3px;
}

.modal-invite-customer input[readonly] {
  opacity: 1;
  color: #000000;
}

.modal-invite-customer input[readonly]:focus {
  box-shadow: none;
  border: 1.5px solid rgba(28, 31, 57, 0.1);
}

.input-btn {
  width: auto;
  font-size: 13px;
  width: 83px;
  height: 38px;
}

.modal-backdrop {
  background: red;
}
</style>
