<template>
    <div>
        <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="items"
                :fields="fields"
                :filter="filter"
                :current-page="page"
                :per-page="showPerPage"
                @filtered="value => itemsFiltered = value"
                @row-clicked="handleRowClicked">

                <template v-slot:head()="data">
                    <div v-if="!data.field.sortable">{{ data.label }}</div>
                    <div v-if="data.field.sortable" class="table-sorting">
                        {{ data.label }}<i></i>
                    </div>
                </template>

                <template v-slot:cell(action)="row">
                    <slot name="actions" :row="row.item"></slot>
                </template>
            </b-table>
        </div>

        <block-pagination
            :role="role"
            :arrayOfSomeone="items"
            :arrayOfSomeoneFiltered="filter ? itemsFiltered : items"
            :currentPage="page"
            @changedCurrentPage="changedCurrentPage"
            :perPage="showPerPage"
            @changedPerPage="changedPerPage"
        >
        </block-pagination>
    </div>
</template>

<script>
import BlockPagination from '../components/utility/block-pagination';
export default {
    props: {
        items: Array,
        fields: Array,
        filter: {
            type: String,
            default: ''
        },
        currentPage: {
            type: Number,
            default: 1
        },
        role: {
          type: String,
          default: 'Suppliers'
        },
        perPage: {
            type: [Number, Object],
            default: 10
        }
    },
    components: {
      BlockPagination,
    },
    data() {
        return {
            page: this.currentPage,
            showPerPage: this.perPage,
            itemsFiltered: [],
        };
    },
    computed: {},
    methods: {
        handleRowClicked(row) {
            this.$emit('row-clicked', row);
        },
        changedCurrentPage(value) {
          this.$emit('changedCurrentPage', value);
          this.page = value;
        },
        changedPerPage(value) {
          this.$emit('changedPerPage', value);
          this.showPerPage = value
        },
    },
    watch: {
      perPage: {
        handler(val) {
          this.showPerPage = val;
        },
        immediate: true,
      },
      currentPage: {
        handler(val) {
          this.page = val;
        },
        immediate: true,
      },
    },
}
</script>

