export const States = {
    users: {},
    isLoadedApp: false,
    isStartedReinstate: false,
    addressStates: [
        {key: "ACT", value: "Australian Capital Territory"},
        {key: "NSW", value: "New South Wales"},
        {key: "NT", value: "Northern Territory"},
        {key: "QLD", value: "Queensland"},
        {key: "SA", value: "South Australia"},
        {key: "TAS", value: "Tasmania"},
        {key: "VIC", value: "Victoria"},
        {key: "WA", value: "Western Australia"},
    ],
    addressCountries: [
        {key: "AU", value: "Australia"},
        {key: "NZ", value: "New Zealand"},
    ],
    toastOptions: {},
    toastContent: {},
    toastShowCount: 0,
    stockOptions: [
        {
            label: 'None',
            textValue: 'None',
            value: 0
        },
        {
            label: 'In Stock',
            textValue: 'InStock',
            value: 1
        },
        {
            label: 'EX Warehouse',
            textValue: 'ExStock',
            value: 6
        },
        {
            label: 'Low Stock',
            textValue: 'LowStock',
            value: 2
        },
        {
            label: 'No Stock',
            textValue: 'NoStock',
            value: 3
        },
        {
            label: 'On Back Order',
            textValue: 'OnBackOrder',
            value: 4
        },
        {
            label: 'No Longer Avail',
            textValue: 'NoLongerAvail',
            value: 5
        }
    ],
    paymentTypes: [
        {key: "credit", value: "Credit"},
        {key: "cash", value: "Cash"},
    ],
    quoteOpenDaysForRequest: 7,
    supplierBusinessTypes: [
        {
            value: 'OEM-GEN',
            label: 'OEM-GEN - Original Equipment Manufacturer - Genuine'
        },
        {
            value: 'OEM-PAR',
            label: 'OEM-PAR - Original Equipment Manufacturer - Parallel'
        },
        {
            value: 'OEM-ALT',
            label: 'OEM-ALT - Parallel Equipment Manufacturer'
        },
        {
            value: 'OEM-ALT/AFTM',
            label: 'OEM-ALT/AFTM - Parallel & Aftermarket Equipment Manufacturer'
        },
        {
            value: 'CERT-AFTM',
            label: 'CERT-AFTM - Certified Non Original Equipment Manufacturer',
        },
        {
            value: 'AFTM',
            label: 'AFTM - Aftermarket Equipment Manufacturer',
        },
        {
            value: 'AFTM/USED',
            label: 'AFTM/USED - Aftermarket Equipment Manufacturer & Wrecked Parts'
        },
        {
            value: 'USED',
            label: 'USED - Wrecked Parts',
        },
        {
            value: 'RECO',
            label: 'RECO - Reconditioned Parts'
        }
    ],
}
