
import RepairerSupplierInvite from '../views/repairers/suppliers/supplier-invite.vue';

const demoRoutes = [
  {
    path: '/demo/supplier/invite',
    name: 'RepairerSupplierInvite',
    component: RepairerSupplierInvite
  },
]

export default demoRoutes;
