<script>
/*eslint-disable */
import NProgress from "nprogress";
import Axios from "axios";

export default {
    name: "PasswordResetPage",
    data() {
        return {
            pass: "",
            retrypass: "",
            code:"",
            error: 0,
            errors: {
                'password1': '',
                'password2': ''
            },
            isLoading: false,
            isChecked: false,
            showPassword1: false,
            showPassword2: false,
        };
    },
    created() {
        this.code = this.$route.params.code;
        this.checkCode();
    },
    mounted() {
      this.setWhiteBackground();
    },
    methods: {
        setWhiteBackground () {
          let app = document.getElementById('app-wrapper');
          console.log(app, 'app')
          app.classList.add('white-background');
        },
        removeWhiteBackground () {
          let app = document.getElementById('app-wrapper');
          app.classList.remove('white-background');
        },
        checkCode () {
          Axios.post('ir/auth/check-code-to-reset-password', {
            code: this.code,
          })
          .then((r) => {
            if (!r?.data?._status) {
              this.$router.push('/login')
              this.setWhiteBackground();
            } else {
              this.isChecked = true;
              this.removeWhiteBackground();
            }
          })
          .catch((e) => {
            console.log(e)
          })
        },
        sendRequest() {
            if(!this.isFormValid() || this.isLoading) return;
            this.isLoading = true;
            this.error = 0;
            NProgress.start();
            Axios({url: '/ir/auth/reset-password', data: {
                    code: this.code,
                    password1: this.pass,
                    password2: this.retrypass
                }, method: 'POST'})
                .then(resp => {
                    if(resp && resp.data) {
                        if (resp.data.status === true) this.$router.push('/login');
                        else this.error = resp.data.error;
                    }
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                NProgress.done();
                this.isLoading = false;
            });
        },
        isFormValid(){
            let text1='',
                text2='';
            if(_.isEmpty(this.pass)) text1 = 'Please enter new password.';
            if(_.isEmpty(this.retrypass)) text2 = 'Please retype new password.';

            if(!text1 && !text2){
              if(!this.pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){ // TODO: check this is correct
                text1 = 'Password is too simple.';
              } else if(this.pass!==this.retrypass) {
                text2 = 'Passwords do not match.';
              }

            }

            this.errors.password1 = text1;
            this.errors.password2 = text2;
            return _.isEmpty(text1) && _.isEmpty(text2)
        },
        goToHome() {
            document.location.replace(appConfig.homeURL + '/');
            // this.$router.push('/');
        },
    }
};
</script>

<template>
    <div v-if="isChecked" class="container-fluid page">
        <div class="content">
            <div class="background column">
                <img src="@/assets/img/login-background.jpg" alt="partsearch"/>
            </div>
            <div class="form-container column">
                <div class="form">
                    <div class="auth-header">
                        <div class="logo">
                            <div @click.prevent="goToHome" class="logo-text">
                                <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                            </div>
                        </div>
                        <span class="close" @click="$router.push('/login')">×</span>
                    </div>
                    <form method="POST" @submit.prevent="sendRequest">
                        <h2 class="header-text">Password Reset</h2>
                        <p>Reset your password by entering you new password details below</p>
                        <br/>
                        <div class="form-group position-relative">
                            <label for="pass">New Password</label>
                            <input
                                :type="showPassword1 ? 'text' : 'password'"
                                class="input-field"
                                :class="{'error': errors.password1}"
                                placeholder="Enter New Password"
                                name="pass"
                                v-model="pass"
                            />
                            <div @click="showPassword1 = !showPassword1" class="toggle-password-btn">
                                <div class="line-password-input"></div>
                                <i v-if="showPassword1" class='bx bx-show' ></i>
                                <i v-else class='bx bx-hide' ></i>
                            </div>
                            <div class="w-100 text-right" v-if="errors.password1">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;{{errors.password1}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group position-relative">
                            <label for="pass">Retype New Password</label>
                            <input
                                :type="showPassword2 ? 'text' : 'password'"
                                class="input-field"
                                :class="{'error': errors.password2}"
                                placeholder="Retype Password"
                                name="pass"
                                v-model="retrypass"
                            />
                            <div @click="showPassword2 = !showPassword2" class="toggle-password-btn">
                                <div class="line-password-input"></div>
                                <i v-if="showPassword2" class='bx bx-show' ></i>
                                <i v-else class='bx bx-hide' ></i>
                            </div>
                            <div class="w-100 text-right" v-if="errors.password2">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;{{errors.password2}}
                                </span>
                            </div>
                            <p class="small mt-2">*Must be more than 8 characters long,contain 1 capital letter,2
                                numbers and no spaces.</p>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn input-btn">Reset My Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="load-bar" style="margin-left: 8px; margin-right: 1px;">
      <div class="loader reset-password-loader" style="margin-top: 8px;"></div>
      <h1 style="font-family: 'Times New Roman'; color: black">Loading...<br/>Please wait!</h1>
    </div>
</template>

<style>
@import "../../styles/login/style.css";
</style>

<style scoped>
    .page {
        height: 100vh;
    }

    @media screen and (max-width: 768px) {
        .page .content {
            height: 100%;
            width: 100%;
        }
        .page .content .form-container {
            height: 100%;
        }
        .page .form {
            height: 100%;
        }
        .page .form form {
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: center;
        }
    }
</style>

<style>
.white-background {
  background-color: white !important;
}
.reset-password-loader:before {
  content: '';
  height: 0px;
  width: 0px;
}
</style>
<style scoped>
.toggle-password-btn {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 20px;
  color: #8d8f9c;
}

.line-password-input {
  width: 1px;
  height: 25px;
  background-color: rgba(27, 30, 56, 0.2);
  position: absolute;
  right: 32px;
}

.form-control.input-field, .input-field {
    padding-right: 50px !important;
}
</style>
