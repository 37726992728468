<script>
/*eslint-disable */
import NProgress from "nprogress";
import Email from "../../components/auth/Email";
import Axios from "axios";

export default {
    name: "ForgotPasswordPage",
    components: {
        Email
    },
    data() {
        return {
            userEmail: "",
            isCheckEmail: true
        };
    },
    methods: {
        sendForgotRequest() {
            NProgress.start();
            Axios({url: '/ir/auth/forgot/password', data: {email: this.userEmail}, method: 'POST'})
                .then(resp => {
                    this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
                    //TODO remove after testing
                    if(!_.isEmpty(resp.data['_debug']) && !_.isEmpty(resp.data['_debug']['resetLink'])) {
                        window.location = resp.data['_debug']['resetLink'];
                    } else if (this.isCheckEmail) this.$router.push('/login');
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                NProgress.done();
            });
        },
        goToHome() {
            document.location.replace(appConfig.homeURL + '/');
            // this.$router.push('/');
        },
    }
};
</script>

<template>
    <div class="container-fluid page">
        <div class="content">
            <div class="background column">
                <img src="@/assets/img/login-background.jpg" alt="partsearch"/>
            </div>
            <div class="form-container column">
                <div class="form">
                    <div class="auth-header">
                        <div class="logo">
                            <div @click.prevent="goToHome" class="logo-text">
                                <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                            </div>
                        </div>
                        <span class="close" @click="$router.push('/')">×</span>
                    </div>
                    <form method="POST" @submit.prevent="login">
                        <h2 class="header-text">Forgot your Password?</h2>
                        <p>Dont’ worry, just enter the email address your account is registered with and we will send
                            you link to reset your password</p>
                        <br/>
                        <div class="form-group">
                            <label>Email Address</label>
                            <email v-model="userEmail"></email>
                            <p>
                                Get Help with your password.
                                <a href="#" @click.prevent="$router.push({name: 'SupportPasswordPage'})">Click here</a>
                            </p>
                        </div>
                        <div class="form-group">
                            <button :disabled="!isCheckEmail || !userEmail"
                                    type="button"
                                    @click="sendForgotRequest"
                                    class="btn input-btn">Send Password Reset Link</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import "../../styles/login/style.css";
</style>

<style scoped>
    .page {
        height: 100vh;
    }

    @media screen and (max-width: 768px) {
        .page .content {
            height: 100%;
        }
        .page .content .form-container {
            height: 100%;
        }
        .page .form {
            height: 100%;
        }
        .page .form form {
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: center;
        }
    }
</style>
