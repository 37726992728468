/*eslint-disable */
import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const state = {
  apiKeys: [],
  company_id: 0,
  companyId: 0,
  email: '',
  full_name: '',
  fullName: '',
  dmsId: null,
  group_id: 0,
  groupId: 0,
  id: '',
  type: 'none',
  firstName: '',
  lastName: '',
  username: '',
  role: '',
  companyRole: '',
  franchises: [],
  isActive: true,
  businessLocation: '',
  workPhone: '',
  mobilePhone: '',
  userPhoto: '',
  activeSessions: -1,
  lastLoggedIn: '',
  isEmailNoticeNewRfq: false,
  isEmailNoticeUpdatedPriceRequest: false,
  isSoundNoticeNewRfq: false,
  isEmailNoticeNewOrder: false,
  isSoundNoticeQuoteAssigned: false,
  isEmailNoticeQuoteAssigned: false,
  isSoundNoticeUpdatedPriceRequest: false,
  multipleBrands: [],
}

export const USER_TYPE = {
  REPAIRER: 'repairer',
  SUPPLIER: 'supplier',
}

export default {
  namespaced: true,
  mutations,
  actions,
  state,
  getters,
}
