<template>
    <div class="page invite-repairer">
        <div class="container">
            <div class="auth-header">
                <div class="logo">
                    <div @click.prevent="goToHome" class="logo-text">
                        <img src="@/assets/logos/partssearch-logo-white-backgroud.svg" class="auth-logo-svg"/>
                    </div>
                </div>
            </div>
            <div class="invite-repairer-form text-center">
                <div class="intro-text">
                    <div class="form">
                        <div class="steps-line">
                            <ul>
                                <li class="active">
                                    <span class="numb">1</span>
                                    <span class="step-title">Your Business</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">2</span>
                                    <span class="step-title">Business Profile</span>
                                </li>
                                <li class="line"></li>
                                <li>
                                    <span class="numb">3</span>
                                    <span class="step-title">Your Details</span>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <br />
                        <h1>Confirm your business details</h1>
                        <br />
                        <form method="POST" @submit.prevent="login">
                            <div class="form-group">
                                <label for="Country">Country</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    :value="'Australia'"
                                    name="Country"
                                    placeholder="Your Country"
                                />
                                <div class="w-100 text-right">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Company Type</label>
                                <multiselect
                                    :options="getOptions"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Choose type of company"
                                />
                            </div>
                            <div class="form-group">
                                <label for="businessname">Registered Business Name</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    :value="'AAA Smash Repairs'"
                                    name="businessname"
                                    placeholder="Your Business Name"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="businessnumber">Australian Business Number(ABN)</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    :value="'86 152 759 736'"
                                    name="businessnumber"
                                    placeholder="Business Numbe(ABN)"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="companynumber">Australian Company Number(ACN)</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field"
                                    :value="'152 759 736'"
                                    name="companynumber"
                                    placeholder="Company Number(ACN)"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="City">City</label>
                                <multiselect
                                    :options="getOptionsCity"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select city"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="State">State</label>
                                <multiselect
                                    :options="getOptionsState"
                                    :showLabels="false"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select state"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="postcode">Postcode</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control input-field post-code"
                                    :value="'2195'"
                                    name="businessnumber"
                                    placeholder="Your Postcode"
                                />
                                <div class="w-100 text-right" v-show="false">
                                    <span class="error-text">
                                        <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn input-btn"
                                    @click="$router.push({name: 'RepairerCreateBusinessProfile'});"
                                >Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@import "../../../../styles/repairer/invite/style.css";
</style>
<style lang="css" scoped>
form {
    max-width: 522px;
    margin: 0 auto;
}
.form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
}
.steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
}
.form h1 {
    text-align: center;
}
.invite-repairer-form {
    justify-content: flex-start;
    margin-top: 15px;
}
.form .input-btn {
    max-width: 432px;
    display: table;
    margin: 0 auto;
    margin-top: 30px;
}
.form .post-code {
    width: 109px;
}
</style>

<script>
import Multiselect from "vue-multiselect";
export default {
    name: "InviteRepairerConfirmBusinessDetails",
    components: {
        multiselect: Multiselect
    },
    computed: {
        getOptions() {
            return ["Australian Public Company", "Australian Private Company"];
        },
        getOptionsCity() {
            return ["LAKEMBA", "Sydney"];
        },
        getOptionsState() {
            return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA',];
        }
    },
    mounted() {
        this.scrollToTop()
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
      goToHome() {
        document.location.replace(appConfig.homeURL + '/');
      },
    },

};
</script>
