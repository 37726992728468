<template>
  <div class="settings-page">
    <div class="page-header">
      <h4>Advanced Settings</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">Advanced Settings</li>
      </ol>
    </div>
    <div class="settings-body">
      <tabs ref="settings-table" cache-lifetime="0" @changed="onMainTabChange"
            :options="{useUrlFragment: false, defaultTabHash: computedDefaultSettingsHash }">
        <template v-if="currentUser.id">
          <tab v-if="isShowTab" name="Integrations" id="integrations" ref="integrations" class-name="tab-content-table">
            <integrations ref="integrationsComponent"></integrations>
          </tab>
          <tab v-if="isUserTypeRepairer && isAdministrator" name="RFQ" id="repairer-rfq" ref="repairer-rfq"
               class-name="tab-content-table">
            <repairer-rfq ref="rfqComponent" @changedRfqTab="changedRfqTab"></repairer-rfq>
          </tab>
          <tab v-if="isUserTypeRepairer && isShowTab" name="Orders" id="orders" ref="orders" class-name="tab-content-table">
            <orders ref="ordersComponent"></orders>
          </tab>
          <tab v-if="isShowTab" name="Users" id="users" ref="users" class-name="tab-content-table">
            <users
                ref="usersComponent"
                :isAddNewUserClicked="isAddNewUserClicked"
                @addNewUserClicked="value => isAddNewUserClicked = value"
            >
            </users>
          </tab>
          <tab v-if="isShowTab" name="Groups" id="groups" ref="groups" class-name="tab-content-table">
            <groups ref="groupsComponent"></groups>
          </tab>
          <tab name="My Account" id="settings-account" ref="my-account" :class-name="'tab-content-table'">
            <my-account></my-account>
          </tab>
          <tab v-if="isShowTab"
               name="Business Profile"
               id="settings-business"
               :class-name="'tab-content-table'"
          >
            <business-profile
                :ref="'businessProfile'"
                @changedTab="changedBusinessTab"
            />
          </tab>
        </template>

        <template v-if="mainTabId === 'groups' || mainTabId === 'orders' || mainTabId === 'credits' || (mainTabId === 'repairer-rfq' && rfqTabId === 'purchasePriceSelector')" slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="updateSettings" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId === 'integrations'" slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button class="btn btn-primary" v-if="!isAddNewUserClicked" @click="onApiKeyCreateClick()">
              <i class="bx bx-plus"></i> Create Api Key
            </button>
          </div>
        </template>
        <template v-if="mainTabId === 'users'" slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-1" v-if="isAddNewUserClicked">
            <button class="white-save-btn" @click="onClickSaveUser()">Save</button>
            <button class="white-save-btn" @click="onClickCancelEditUser()">Cancel
            </button>
          </div>
          <div class="tabs-nav-right-btn">
            <button class="btn btn-primary" v-if="!isAddNewUserClicked && isAdministrator" @click="onClickAddNewUser()">
              <i class="bx bx-plus"></i> Add User
            </button>
          </div>
        </template>
        <template v-if="mainTabId === 'settings-business' && businessTabId === 'business-details'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button @click="onClickSaveCompanyInfo()" class="btn btn-primary primary-save-btn">Save</button>
          </div>
        </template>
        <template v-if="mainTabId === 'settings-business' && businessTabId === 'business-locations'"
                  slot="nav-item-right">
          <div class="tabs-nav-right-btn">
            <button class="white-save-btn" @click="onClickSaveCompanyInfo()">Save</button>
          </div>
          <div class="tabs-nav-right-btn">
            <button href="#" class="add-location-btn" @click="onClickAddLocation()">
              <i class="bx bx-plus"></i> Add Location
            </button>
          </div>
        </template>

      </tabs>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash'
import {mapGetters} from 'vuex'
import Integrations from './components/the-integrations'
import Orders from './components/the-orders'
import Groups from './components/the-groups'
import Users from './components/the-users';
import BusinessProfile from './components/business-profile.vue';
import MyAccount from './components/the-my-account';
import RepairerRfq from './components/repairer-rfq'

export default {
  name: 'Settings',
  components: {
    Orders,
    BusinessProfile,
    Integrations,
    Groups,
    Users,
    MyAccount,
    RepairerRfq
  },
  data() {
    return {
      mainTabId: '',
      businessTabId: '',
      rfqTabId: '',

      // users
      isAddNewUserClicked: false,
    }
  },
  mounted () {
    if (!_.isEmpty(this.currentUser.role) && this.isShowTab) {
      this.tabId = this.$refs.users.id;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser/getCurrentUserInfo',
      isUserTypeRepairer: 'currentUser/isUserTypeRepairer',
      isUserTypeSupplier: 'currentUser/isUserTypeSupplier',
      isAdministrator: 'currentUser/isAdministrator',
      isPartsManager: 'currentUser/isPartsManager',
    }),
    isShowTab () {
      return this.isAdministrator || this.isPartsManager
    },
    computedDefaultSettingsHash () {
      if (localStorage.getItem("settings.tabs.defaultTab")) {
        return localStorage.getItem("settings.tabs.defaultTab");
      }
      return "settings-business";
    },
  },
  methods: {
    changedBusinessTab(tab) {
      this.businessTabId = tab;
    },
    changedRfqTab(tab) {
      this.rfqTabId = tab;
    },
    onMainTabChange: function (tab) {
      console.log('tab', tab)
      this.mainTabId = tab.tab.id
      localStorage.setItem("settings.tabs.defaultTab", this.mainTabId);
    },

    updateSettings() {
      if (this.mainTabId === 'repairer-rfq' && this.rfqTabId === 'purchasePriceSelector') {
        this.$refs.rfqComponent.updatePurchases();
      } else if (this.mainTabId === 'orders') {
        this.$refs.ordersComponent.updateOrderSettings();
      } else if (this.mainTabId === 'groups') {
        this.$refs.groupsComponent.updateGroups();
      }
    },

    //integrations
    onApiKeyCreateClick: function () {
      this.$refs.integrationsComponent.onCreateClick()
    },

    //users methods
    onClickSaveUser: function () {
      this.$refs.usersComponent.onClickSaveUser();
    },
    onClickAddNewUser() {
      this.$refs.usersComponent.onClickAddNewUser();
    },
    onClickCancelEditUser() {
      this.$refs.usersComponent.onClickCancelEditUser();
    },

    // business profile methods
    onClickSaveCompanyInfo: function () {
      console.log('ref', this.$refs.businessProfile)
      this.$refs.businessProfile.onClickSaveCompanyInfo();
    },
    onClickAddLocation: function () {
      this.$refs.businessProfile.onClickAddLocation();
    },
  },
}
</script>
