import RepairerDashboard from "../views/repairers/dashboard/dashboard";
import RepairerRFQAdd from "../views/repairers/rfq/rfq-add";
import RepairerRFQView from "../views/repairers/rfq/rfq-view";
import RepairerPartsOrders from "../views/repairers/part-order/part-orders";
import CreditManagement from "../views/repairers/credit-management/credit-management";
import CreditManagementView from "../views/repairers/credit-management/credit-view";
import RepairerSuppliers from "../views/repairers/suppliers/suppliers";
import RepairerSupplierView from "../views/repairers/suppliers/supplier-view";
import RepairerRFQOrder from '../views/repairers/rfq/rfq-order';
import RepairerPartsOrderView from '../views/repairers/part-order/part-order-view.vue';
import RepairerRFQs from '../views/repairers/rfq/rfqs.vue'
import RepairerReports from "../views/repairers/reports/reports";
import jobView from "@/views/repairers/job/job-view.vue";
export default [
    {
        path: "/r",
        name: "RepairerDashboard",
        component: RepairerDashboard,
    },
    {
        path: '/r/rfqs',
        name: 'RepairerRFQs',
        component: RepairerRFQs
    },
    {
        path: '/r/rfq/add',
        name: 'RepairerRFQAdd',
        component: RepairerRFQAdd
    },
    {
        path: '/r/rfq/add/received/:rfq_id',
        name: 'RepairerRFQAdd',
        component: RepairerRFQAdd
    },
    {
        path: '/r/rfq/add/suppliers/:rfq_id',
        name: 'RepairerRFQAdd',
        component: RepairerRFQAdd
    },
    {
        path: '/r/rfq/add/:type',
        name: 'RepairerRFQAdd',
        component: RepairerRFQAdd
    },
    {
        path: '/r/rfq/:rfq_id',
        name: 'RepairerRFQView',
        component: RepairerRFQView,
    },
    {
        path: '/r/rfq/:rfq_id/order',
        name: 'RepairerRfqOrder',
        component: RepairerRFQOrder,
        props: true
    },
    {
        path: '/r/orders',
        name: 'RepairerPartsOrders',
        component: RepairerPartsOrders
    },
    {
        path: '/r/order/:orderId',
        name: 'RepairerPartsOrderView',
        component: RepairerPartsOrderView,
        props: true
    },
    {
        path: '/r/credits',
        name: 'CreditManagement',
        component: CreditManagement
    },
    {
        path: '/r/credit/view/:creditId',
        name: 'CreditManagementView',
        component: CreditManagementView
    },
    {
        path: '/r/suppliers',
        name: 'RepairerSuppliers',
        component: RepairerSuppliers,
        children: [
            {
                path: 'prefered',
                name: 'RepairerSuppliersPrefered',
                component: RepairerSuppliers,
                props: true,
                meta: {currentTab: 'Prefered'}
            },
            {
                path: 'all',
                component: RepairerSuppliers,
                name: 'RepairerSuppliersOther',
                props: true,
                meta: {currentTab: 'All'}
            }
        ]
    },
    {
        path: '/r/reports',
        name: 'RepairerReports',
        component: RepairerReports
    },
    {
        path: '/r/supplier/view/:supplier_id',
        name: 'RepairerSupplierView',
        component: RepairerSupplierView
    },
    {
        path: '/r/job/view/:job_id',
        name: 'RepairerJobView',
        component: jobView,
    },
];
