/*eslint-disable */
import _ from 'lodash'
import {
  SUPPLIER_RFQ_UPDATED,
  SUPPLIER_CURRENT_RFQ_UPDATE,
  SUPPLIER_RFQ_FILTER_UPDATE,
  SUPPLIER_RFQ_NEW_CURRENT_PAGE,
  SUPPLIER_RFQ_PRICED_CURRENT_PAGE,
  SUPPLIER_RFQ_LOST_CURRENT_PAGE,
  SUPPLIER_RFQ_WON_CURRENT_PAGE,
  SUPPLIER_RFQ_ORDERED_CURRENT_PAGE,
  SUPPLIER_RFQ_CLOSED_CURRENT_PAGE,
  SUPPLIER_PER_PAGE,
  SUPPLIER_RFQ_SET_SEARCH_FILTER_OBJ,
  SUPPLIER_RFQ_SET_MESSAGE_STATUS,
  SUPPLIER_RFQ_SET_TOKEN,
  SUPPLIER_RFQ_SET_VIEWING_USERS,
  SUPPLIER_RFQ_SET_ASSIGNED_USER,
  SUPPLIER_RFQ_DELETE,
  SUPPLIER_RFQ_SET_TOKENS,
  SUPPLIER_RFQ_ALL_CURRENT_PAGE,
  SUPPLIER_RFQ_SET_PRIORITY,
  SUPPLIER_RFQ_MARK_AS_READ,
  SUPPLIER_RFQ_SET_SCHEDULE_TIME,
  SUPPLIER_RFQ_QUOTE_NBRS_UPDATE,
  SUPPLIER_RFQ_REINSTALLATION,
  SUPPLIER_RFQ_QUOTE_NBRS_REMOVE,
  SUPPLIER_RFQ_QUOTE_NBRS_CLEAR,
  SUPPLIER_RFQ_SHORT_UPDATING,
  SUPPLIER_RFQ_UPDATE_PIN_IMAGE
} from '../../../mutation-types'
import Vue from "vue";

export default {
  [SUPPLIER_RFQ_UPDATE_PIN_IMAGE](state, ui) {
    state.isPinnedImages = ui
  },
  [SUPPLIER_RFQ_REINSTALLATION] (state) {
    state.filter = ''
    state.quoteNbrs = {}
    state.isPlayNotificationSound = false
    state.token = []
    state.tokens = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
    }
    state.searchFilterObj = {
      search: '',
    }
    state.isNeedRefreshTable = 0
    state.items = []
    state.rfqToSupplierCurrentlyViewing = []
    state.new = []
    state.priced = []
    state.lost = []
    state.won = []
    state.ordered = []
    state.closed = []
    // state.currentRfq = 0  //coment becuase problem after change multi view
    state.lastTimeCheck = {
      items: 0,
      new: 0,
      priced: 0,
      lost: 0,
      won: 0,
      ordered: 0,
      closed: 0,
    }
    state.perPage = 100
    state.allRFQsCurrentPage = 1
    state.newRFQsCurrentPage = 1
    state.pricedRFQsCurrentPage = 1
    state.wonRFQsCurrentPage = 1
    state.orderedRFQsCurrentPage = 1
    state.closedRFQsCurrentPage = 1
    state.lostRFQsCurrentPage = 1
  },
    [SUPPLIER_RFQ_QUOTE_NBRS_CLEAR] (state) {
      state.quoteNbrs = {}
    },
  [SUPPLIER_RFQ_QUOTE_NBRS_REMOVE] (state, ui) {
    // state.quoteNbrs = _.pullAll(state.quoteNbrs[ui.cid], ui.nbrs)
    let quoteNbrsCopy = _.cloneDeep(state.quoteNbrs)
    let removedNbr = ui?.nbrs?.[0]
    let index = _.findIndex(quoteNbrsCopy[ui.cid], (nbr) => {
      return removedNbr === nbr
    })
    if (index !== -1) {
      quoteNbrsCopy[ui.cid].splice(index, 1)
    }
    state.quoteNbrs = quoteNbrsCopy
  },
  [SUPPLIER_RFQ_QUOTE_NBRS_UPDATE] (state, ui) {
    if (!_.isEmpty(ui.quoteNumbers)) {
      let quoteNbrs = _.cloneDeep(state.quoteNbrs)
      _.forEach(ui.quoteNumbers, (nbrs, cid) => {
        if (!quoteNbrs.hasOwnProperty(cid)) {
          quoteNbrs[cid] = []
        }
        let newNumbers = _.filter(nbrs, (nbr) => {
          return !_.find(quoteNbrs[cid], (n) => {
            return n === nbr
          })
        })
        _.forEach(newNumbers, (n) => {
          quoteNbrs[cid].push(n)
        })
      })
      state.quoteNbrs = quoteNbrs
    }

    if (!_.isEmpty(ui.quoteNumbersX)) {
      let quoteNbrs = _.cloneDeep(state.quoteNbrsX)
      _.forEach(ui.quoteNumbersX, (nbrs, cid) => {
        if (!quoteNbrs.hasOwnProperty(cid)) {
          quoteNbrs[cid] = []
        }
        let newNumbers = _.filter(nbrs, (nbr) => {
          return !_.find(quoteNbrs[cid], (n) => {
            return n === nbr
          })
        })
        _.forEach(newNumbers, (n) => {
          quoteNbrs[cid].push(n)
        })
      })
      state.quoteNbrsX = quoteNbrs
    }

    if (!_.isEmpty(ui.removedQuoteNumbers)) {
      _.forEach(ui.removedQuoteNumbers, (nbrs, cid) => {
        this.commit('supplier/rfq/supplierRFQQuoteNbrsRemove', { cid: cid, nbrs: nbrs })
      })
    }
  },
  [SUPPLIER_RFQ_DELETE] (state, ui) {
    let index = _.findIndex(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (index !== -1) {
      state.items.splice(index, 1)
    }
  },
  [SUPPLIER_RFQ_SET_ASSIGNED_USER] (state, ui) {
    let rfq = _.find(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (rfq) {
      rfq.assignedTo = ui.user
    }
    // state.isNeedRefreshTable++;
  },
  [SUPPLIER_RFQ_SET_MESSAGE_STATUS] (state, ui) {
    let rfq = _.find(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (rfq) {
      rfq.hasUnreadMessages = ui.value
    }
  },
  [SUPPLIER_RFQ_SET_SCHEDULE_TIME] (state, ui) {
    let rfq = _.find(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (rfq) {
      rfq.scheduleTime = ui.scheduleTime
    }
    // state.isNeedRefreshTable++;
  },
  [SUPPLIER_RFQ_SET_PRIORITY] (state, ui) {
    let rfq = _.find(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (rfq) {
      rfq.priority = ui.priority
    }
    // state.isNeedRefreshTable++;
  },

  [SUPPLIER_RFQ_MARK_AS_READ] (state, ui) {
    let rfq = _.find(state.items, (itm) => {
      return Number(itm.id) === Number(ui.id)
    })
    if (rfq) {
      rfq.wasRead = ui.wasRead
    }
    // state.isNeedRefreshTable++;
  },

    [SUPPLIER_RFQ_SET_VIEWING_USERS](state, ui) {
        if (_.isEqual(ui, state.rfqToSupplierCurrentlyViewing)) {
          return
        }
        state.rfqToSupplierCurrentlyViewing = ui;
        _.forEach(state.items, (item, index) => {
            if (!_.isEmpty(item.viewingUsers)) {
                Vue.set(state.items[index], 'viewingUsers', item.viewingUsers.splice(item.viewingUsers.length))
                // item.viewingUsers = item.viewingUsers.splice(item.viewingUsers.length);
            }
        })
        for (let suppId in ui) {
            let supp = _.find(state.items, (itm) => {
                return Number(itm.id) === Number(suppId);
            })
            let index = state.items.indexOf(supp)
            if (supp) {
                Vue.set(state.items[index], 'viewingUsers', ui[suppId])
                // supp.viewingUsers = ui[suppId];
            }
        }
        state.isNeedRefreshTable++;
    },
    [SUPPLIER_RFQ_SET_TOKEN](state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    [SUPPLIER_RFQ_SET_TOKENS](state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key];
        }
    },
    [SUPPLIER_RFQ_SET_SEARCH_FILTER_OBJ](state, ui) {
        state.searchFilterObj = ui;
    },
    [SUPPLIER_RFQ_FILTER_UPDATE](state, ui){
        state.filter = ui;
    },
    [SUPPLIER_CURRENT_RFQ_UPDATE](state, ui){
        console.log('LOADED RFQ: %o', ui)
        if (ui && ui.part) {
            ui.parts = _.sortBy(ui.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)]);
        }
        state.currentRfq = ui;
    },
    // [SUPPLIER_RFQ_IDS_UPDATE_NEW](state, ui){
    //     state.new = _.union(state.new  ,ui);
    // },
    // [SUPPLIER_RFQ_IDS_UPDATE_PRICED](state, ui) {
    //     state.priced = _.union(state.priced  ,ui);;
    // },
    // [SUPPLIER_RFQ_IDS_UPDATE_LOST](state, ui) {
    //     state.lost = _.union(state.lost  ,ui);;
    // },
    // [SUPPLIER_RFQ_IDS_UPDATE_WON](state, ui) {
    //     state.won = _.union(state.won  ,ui);;
    // },
    // [SUPPLIER_RFQ_IDS_UPDATE_CLOSED](state, ui) {
    //     state.closed = _.union(state.closed  ,ui);;
    // },
    [SUPPLIER_RFQ_UPDATED](state, ui) {
        state.isPlayNotificationSound = false;
        if (!_.isEmpty(state.items)) {
            let isHasChanges = _.some(ui, (uiItm) => {
                let itm = _.find(state.items, (i) => {
                    return Number(i.id) === Number(uiItm.id);
                })

        return !itm && uiItm.status === 'New'
      })
      if (isHasChanges && this.getters['currentUser/isSoundNoticeNewRfq']) {
        state.isPlayNotificationSound = true
      }
    }
    state.items = _.unionBy(ui, state.items, 'id')
      state.items = _.filter(state.items, (item) => {
        return !item.mergedTo
      })
  },
  [SUPPLIER_RFQ_SHORT_UPDATING] (state, ui) {
    state.isPlayNotificationSound = false
    state.items = _.unionBy(ui, [], 'id')
    state.items = _.filter(state.items, (item) => {
      return !item.mergedTo
    })
  },
  [SUPPLIER_RFQ_PRICED_CURRENT_PAGE] (state, ui) {
    state.pricedRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_ALL_CURRENT_PAGE] (state, ui) {
    state.allRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_NEW_CURRENT_PAGE] (state, ui) {
    state.newRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_LOST_CURRENT_PAGE] (state, ui) {
    state.lostRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_WON_CURRENT_PAGE] (state, ui) {
    state.wonRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_ORDERED_CURRENT_PAGE] (state, ui) {
    state.orderedRFQsCurrentPage = ui
  },
  [SUPPLIER_RFQ_CLOSED_CURRENT_PAGE] (state, ui) {
    state.closedRFQsCurrentPage = ui
  },
  [SUPPLIER_PER_PAGE] (state, ui) {
    state.perPage = ui
  },
}
