<template>
    <div class="wizard-progress-bar">
        <div class="all">
            <div class="header d-flex align-self-center">
                <div class="header-name mr-auto align-self-center">{{ title }}</div>

            </div>
            <div class="row justify-content-center">
                <div class="d-flex">
                    <div class="steps">
                        <ul class="wizard__steps">
                            <li class="wizard__step"
                                :class="{
                             'active':  currentStep >= index,
                             'current': currentStep === index,
                            }"
                                :style="stepStyle"
                                v-for="(step, index) of steps" :key="index">
                                <span class="wizard__step__line"></span>
<!--                                <span class="wizard__step__indicator"><i class='bx bx-check'></i></span>-->
                                <span class="wizard__step__label">
                            <div>{{ step.label }}</div>
                        </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="timer align-self-center" style="margin: 20px 0 0 20px">
                        <div v-if="Number(currentCountdown) > 0">
                            Request Required By : <span class="font-weight-bold">{{ dateTime }}</span>
                        </div>
                        Request Open Time Remaining :
                        <vue-countdown :interval="1000*60" :emitEvents="false" :time="currentCountdown*1000"
                                       v-if="currentCountdown > 0">
                            <template slot-scope="props">{{ props.days }} D - {{ props.hours }} Hrs - {{
                                    props.minutes
                                }} Mins
                            </template>
                        </vue-countdown>
                        <span v-if="currentCountdown == '0'">Not Started</span>
                        <span v-if="currentCountdown == '-1'">Finished</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizard__divider">
        </div>
    </div>
</template>
<script>
    /*eslint no-param-reassign:0*/
    import VueCountdown from '@chenfengyuan/vue-countdown';
    import moment from 'moment';

    export default {
    name: 'wizard-progress-bar',
    props: {
        steps: {},
        time: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: 'Quote Pricing Progress'
        },
        previousStepLabel: {default: 'Back'},
        nextStepLabel: {default: 'Next'},
        finalStepLabel: {default: 'Save'},
        onNext: {},
        onBack: {},
        isMobileOn: {default: true},
        step: {default: 1},
        countdown: {default: 0},
    },
    watch: {
        steps: {
            handler() {
                this.parseOptions();
            },
            immediate: true,
        }
    },

    data() {
        return {
            isMounted: false,
            resizer: null,
            isMobile: false,
            options: [],
            acceptRequest: false,
            rejectRequest: false,
        };
    },
    computed: {
        currentCountdown() {
            return this.countdown;
        },
        currentStep() {
            return this.step - 1;
        },
        stepStyle() {
            return {
                width: `${100 / this.steps.length}%`,
            };
        },
        mobileArrowPosition() {
            return 'calc(50% - 14px)';
        },
        arrowPosition() {
            if (this.isMobile) {
                return this.mobileArrowPosition;
            }
            var stepSize = 100 / this.steps.length;
            var currentStepStart = stepSize * this.currentStep;
            var currentStepMiddle = currentStepStart + (stepSize / 2);
            if (this.steps.length == 1)
                return 'calc(' + currentStepMiddle + '%)';
            else
                return 'calc(' + currentStepMiddle + '% - 14px)';
        },
        currentSlot() {
            return this.steps[this.currentStep].slot;
        },
        getCurrentStep() {
            return this.currentStep;
        },
        dateTime() {
            return moment.unix(this.countdown + (new Date().getTime() / 1000)).format("h:mma - DD/MM/YYYY");
        }
    },
    methods: {
        goTo(step) {
            if (Number.isInteger(step)
                && step < this.steps.length
                && step >= 0) {
                this.currentStep = step;
            }
        },

        parseOptions() {
            this.options = [];
            for (let i = 0; i < this.steps.length; i++) {
                this.options.push(this.steps[i].options ? this.steps[i].options : {});
            }
        },

        handleResize() {
            if (this.resizer) {
                clearTimeout(this.resizer);
            }
            let param = 620;
            if (!$('body').hasClass('compact-sidebar')) {
                param = param - 220;
            }
            this.resizer = setTimeout(() => {
                this.isMobile = (this.$el.clientWidth < param) && (this.isMobileOn);
            }, 100);
        },
    },
    mounted() {
        //    this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        VueCountdown,
    }
};
</script>
