/*eslint no-unused-vars: "off"*/
export const Getters = {
    toastShowCount: state => {
      return state.toastShowCount
    },
    toastSettings: state => {
      return {
          toastContent: state.toastContent,
          toastOptions: state.toastOptions
      }
    },
    appLoadStatus: state => {
      return state.isLoadedApp
    },
    isStartedReinstate: state => {
      return state.isStartedReinstate
    },
    quoteOpenDaysForRequest: state => {
      return state.quoteOpenDaysForRequest;
    },
    addressStates: state => {
        return state.addressStates;
    },
    addressCountries: state => {
        return state.addressCountries;
    },
    paymentTypes: state => {
        return state.paymentTypes;
    },
    vehicleTransmissions: state => {
        return state.vehicleTransmissions;
    },
    getActiveMainTemplateClass: (state, getters) => {
        let cssClass = 'theme-home';
        if (getters['currentUser/isUserTypeRepairer']) {
            cssClass = 'theme-repairer';
        } else if (getters['currentUser/isUserTypeSupplier']) {
            cssClass = 'theme-supplier';
        }
        return cssClass;
    },
    stockOptions(state) {
        return state.stockOptions
    },
    getSupplierBusinessTypes: state=>{
        return state.supplierBusinessTypes
    }
};
