<script>
/* eslint-disable */
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import _ from 'lodash'

export default {
  name: 'rfq-import-quote-pdf',
  props: {
    vin: {
      type: String,
      default: '',
    },
    buildDate: {
      type: String,
      default: '',
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    closeWizard () {
      this.isLoading = false
      this.$emit('changedLoading', this.isLoading)
      this.parts = []
      this.isLoadedParts = false
      this.isError = false
      this.isHideDropzone = false
      this.valueLoader = 0
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    startLoading () {
      this.isLoading = true
      this.$emit('changedLoading', this.isLoading)
      let interval = setInterval(() => {
        if (this.valueLoader === 100 && (this.isLoadedParts || this.isError)) {
          clearInterval(interval)
          setTimeout(() => {
            this.isLoading = false
            this.isHideDropzone = true
            this.$emit('changedLoading', this.isLoading)
          }, 1000)
        } else if (this.valueLoader !== 100) {
          this.valueLoader += 2
        }
      }, 100)
    },
    sendFileUpload ($event) {
      this.startLoading()
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    errorFileUpload() {
      this.isError = true;
    },
    successFileUpload (file, response) {
      // this.$refs.PDFUpDrop.removeAllFiles();
      if (response._status) {
        this.isLoadedParts = true
        setTimeout(() => {
          let details = {
            vin: '',
            make: '',
            model: '',
            body: '',
            odometer: '',
            rego: '',
            colour: '',
            estimator: '',
            QPNumber: '',
            dom: '',
          }
          details.QPNumber = response.data.quoteNumber //Берем именно quoteNumber, потому что он универсалень для Quoute и Invoice odf
          details.vin = response.data.vehicleVin
          details.make = response.data.vehicleMake
          details.model = response.data.vehicleModel
          details.body = response.data.vehicleBodyStyle
          details.odometer = response.data.vehicleOdometer
          details.rego = response.data.vehicleRego
          details.colour = response.data.vehiclePaintCode
          details.estimator = response.data.estimateBy
          details.dom = response.data.vehicleDom
console.log('details',details)
          this.$emit('loadParts', response.data.parts, details)
        }, 1000)
      } else {
        this.isError = true
      }
    },
  },
  data: function () {
    return {
      valueLoader: 0,
      maxLoader: 100,
      isLoading: false,
      parts: [],
      isLoadedParts: false,
      isError: false,
      isHideDropzone: false,
      PDFUploadDropzoneOptions: {
        clickable: '#clickBrowsePDFBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/ir/repairer/rfq/parse-pdf',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        maxFilesize: 20,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'application/pdf',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 20MB per file. Please reduce the file size and upload again',
      },
    }
  },
}

</script>

<template>
  <div class="import-pdf-section">
    <div :class="{'hidden-vue-dropzone': isLoading}" v-if="!isHideDropzone" class="import-pdf-loader">
      <vue-dropzone
          v-on:vdropzone-complete="completeFileUpload"
          v-on:vdropzone-sending="sendFileUpload"
          v-on:vdropzone-success="successFileUpload"
          v-on:vdropzone-error="errorFileUpload"
          ref="PDFUpDrop"
          id="PDFUploadDrop"
          class="dropify-wrapper dropzoneContainer"
          :options="PDFUploadDropzoneOptions"
          :useCustomSlot="true">
        <div class="dropify-message" style="transform: translateY(0%); min-width: 125px;">
          <div class="dropzone-block">
            <i class='bx bxs-file-pdf'></i>
            <i class='bx bx-cloud-upload'></i>
            <p class="dnd-title">Drag and Drop File Here</p>
            <p class="text-or mb-0-5 dnd-or">Or</p>
          </div>
          <div class="text-sm-center mb-1 ex-image-upload-buttons">
            <button id="clickBrowsePDFBtn" type="button"
                    class="btn btn-primary waves-effect waves-light browse-btn browse-btn-files">Browse Files
            </button>
          </div>
        </div>
      </vue-dropzone>
    </div>

    <div v-if="isLoading" style="margin-top: 20px;">
      <div class="pdf-upload-loader-wrapper">
        <div class="pdf-upload-loader">
          <i class='bx bx-file'></i>
          <b-progress :value="valueLoader" :max="maxLoader" height="100%" class="pdf-upload-progress"></b-progress>
        </div>
      </div>
    </div>

    <div v-if="isError && !isLoading && valueLoader === 100" style="margin-top: 15px">
      <div class="pdf-upload-loader-wrapper">
        <div class="pdf-upload-error">
          <p>An <span>ERROR</span> has occurred converting your PDF Quote</p>
          <p>A support ticket has been raised.</p>
          <p>Sorry, we will work on resolving so it doesn't happen again!</p>
          <button @click="closeWizard" type="button"
                  class="btn btn-secondary waves-effect waves-light browse-btn browse-btn-files">Close
          </button>
        </div>
      </div>
    </div>

  </div>
</template>


<style scoped>
.vin-date {
  margin-left: 28px;
  margin-top: 15px;
  font-weight: bold;
}

.vin-date span {
  margin-right: 15px;
}

.import-pdf-loader {
  max-width: 600px;
  min-height: 300px;
  height: calc(100vh - 500px);
  max-height: 600px;
  margin: 20px auto 0px auto;
  border: 2px dashed rgba(27, 30, 56, 0.25);
}

.dropify-wrapper {
  height: 98%;
  border: 2px dashed rgba(27, 30, 56, 0.25);
  margin: 5px;
}

.bxs-file-pdf {
  font-size: 70px;
  color: #1C1F39
}

.bx-cloud-upload {
  font-size: 120px;
  color: #1C1F39
}

.bx-file {
  font-size: 300px;
  color: #1C1F39;
  position: relative;
  z-index: 9;
}

.dropzone-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85%;
}

.pdf-upload-loader-wrapper {
  max-width: 600px;
  min-height: 330px;
  height: calc(100vh - 500px);
  max-height: 600px;
  margin: 0 auto;
  border: 2px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  position: relative;
  background-color: white;
}

.pdf-upload-loader {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pdf-upload-error {
  height: 98%;
  border: 2px solid rgba(27, 30, 56, 0.25);
  margin: 5px;
  border-radius: 3px;
  background-color: #efeff1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-upload-error p {
  text-align: center;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #1C1F39;
  margin-bottom: 2rem;
}

.pdf-upload-error p span {
  color: red;
}

.import-quote-table {
  margin-top: 50px;
}

table {
  border-collapse: separate;
  border-spacing: 1em;
}

table.table.b-table > thead > tr > th {
  background-color: white !important;
  border: 1px solid #1d1d21bd;
}

table.table.b-table > thead > tr > th:last-child,
.table th, .table td:last-child {
  border: none;
}

.table th, .table td {
  background-color: white !important;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.table-line {
  height: 2px;
  background-color: #b3b3b3;
  position: absolute;
  width: calc(100vw - 300px);
}

.header-part-customer-name,
.part-customer-name {
  border: 1px solid #29BBC1 !important;
}

.theme-supplier label.checkbox > span.icon {
  border: 2px solid #9e9e9e !important;
}

.theme-supplier label.checkbox > input[type="checkbox"]:checked + span.icon {
  border: 2px solid #29BBC1 !important;
}
</style>

<style>
.hidden-vue-dropzone {
  border: 2px solid rgba(27, 30, 56, 0.25) !important;
}

.pdf-upload-progress-2 {
  width: 70%;
  height: 18px;
}

.import-pdf-section {
  padding-bottom: 30px;
  margin: 0 15px;
}

.import-pdf-section .dropzone .dz-preview {
  display: none;
}

.import-pdf-section .import-parts-context {
  width: 200px;
}

.import-pdf-section .import-parts-context ul {
  padding: 0px !important;
}

.import-pdf-section .import-parts-context li {
  padding-left: 15px !important;
}

.site-content .import-pdf-section .import-parts-context ul li:hover {
  background-color: rgba(41, 187, 193, 0.1) !important;
}

.import-pdf-loader .dz-message {
  height: 83%;
}

.import-pdf-loader .dropify-message {
  height: 100%;
}

.hidden-vue-dropzone {
  display: none;
}

.pdf-upload-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-height: 880px) {
  .bxs-file-pdf {
    font-size: 50px;
  }

  .bx-cloud-upload {
    font-size: 50px;
  }

  .bx-file {
    font-size: 150px;
  }

  .dropify-wrapper {
    height: 97%;
  }

  .dropzone-block {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .pdf-upload-error p {
    width: 90%;
  }
}

</style>
