<template>
  <div :style="getStyle()" class="block-uploader" :class="{'reverse-columns': isNeedChangePosition, 'file-uploader-component': type !== 'image', 'files-diagram-position': isDiagramsLayout, 'files-diagram-repairer': isRepairerDiagram}">
    <template v-if="!isSelectedPDFFiles">
      <div class="block-uploader files-upload" v-if="!readOnly" :class="{'width-auto': isQuotes}">
        <vue-dropzone v-on:vdropzone-file-added="addFileUpload"
                      v-on:vdropzone-success="successFileUpload"
                      v-on:vdropzone-complete="completeFileUpload"
                      v-on:vdropzone-sending="sendFileUpload"
                      ref="fileUpDrop"
                      id="fileUpDrop"
                      class="dropify-wrapper dropzoneContainer"
                      style="height: auto;"
                      :options="getConfig()"
                      :useCustomSlot="true">
          <div class="dropify-message">
          <div @click="clickOnBrowseFiles">
            <i class='bx' :class="{'bx-camera': isMobile, 'bx-cloud-upload': !isMobile}"></i>
            <p class="drop-down-text">
              <template v-if="isMobile">Take Photo</template>
              <template v-else>
                Drag and Drop
                <template v-if="type==='image'">Images</template>
                <template v-else>Files</template>
                Here
              </template>
            </p>
            </div>
          <p class="text-or">Or</p>
            <div class="text-sm-center ex-image-upload-buttons">
              <button type="button" :id="'clickBrowseFilesBtn_' + type" ref="vueDropzoneButton"
                      class="btn btn-primary waves-effect waves-light browse-btn">Browse
                <template v-if="type==='image'">Images</template>
                <template v-else>Files</template>
              </button>
              <button @click="clickCancelDropzoneFiles" type="button"
                      class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel
              </button>
            </div>
          </div>
        </vue-dropzone>
      </div>
      <div v-if="type==='image'" class="files-upload block">
        <div class="block-header bg-navyblue">
          Images
        </div>
        <image-lightbox
            :isRemove="!readOnly"
            :isEdit="!readOnly"
            :isDownload="true"
            :isCanPin="isCanPin"
            :pinnedImage="pinnedImage"
            :isPreviewImage="'true'"
            :position="''"
            :imageList="files"
            @onPinnedImage="value => $emit('onPinnedImage', value)"
            @removeImage="deleteImage"
            :path="''"
        ></image-lightbox>
      </div>
      <div v-else class="file-block block files-upload" :class="{'width-auto': isQuotes}">
        <div class="use-pagination-block-bottom">
          <b-table
              responsive
              hover
              ref="files-table"
              :items="computedFiles"
              :fields="fields"
              :current-page="currentPage"
              :selectable="isSelectable"
              @row-selected="handleRowSelected"
              :per-page="perPage">
            <template v-slot:head()="data">
              <div v-if="!data.field.sortable">{{ data.label }}</div>
              <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
            </template>
            <template #cell(date)="data">
              {{ data.value | formatDatetime }}
            </template>
            <template v-slot:cell(action)="data">

              <a
                  :href="data.item.url"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Download and View File', placement:'leftbottom'}"
                  target="_blank">
                <i class="bx bx-download"></i>
              </a>

              <a
                  href="#"
                  class="control-elem-1__item"
                  @click.prevent="deleteFile(data.item)"
                  v-if="!(readOnly && !isSupplierRfqViewPage) && isCanRemove(data.item)"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove this file', placement:'leftbottom'}">
                <i class="bx bx-trash"></i>
              </a>
            </template>
          </b-table>
        </div>
        <block-pagination
            :role="'Files'"
            :arrayOfSomeone="files"
            :arrayOfSomeoneFiltered="files"
            :currentPage="currentPage"
            @changedCurrentPage="value => currentPage = value"
            :perPage="perPage"
            @changedPerPage="value => perPage = value"
            class="files-upload"
        >
        </block-pagination>
      </div>
    </template>
    <pdf-viewer v-if="isSelectedPDFFiles" :documentHeight="documentHeight" :documentWidth="documentWidth" :documentPath="computedPdfFile && computedPdfFile.url ? computedPdfFile.url : ''" @closePdf="onClosePdf"></pdf-viewer>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import Axios from 'axios'
import _ from 'lodash'
import ImageLightbox from '@/components/image-lightbox/image-lightbox'
import { isMobile } from 'mobile-device-detect'
import BlockPagination from '../components/utility/block-pagination'

import PdfViewer from './utility/pdf-viewer'


export default {
  name: 'FileUpload',
  components: {
    vueDropzone,
    ImageLightbox,
    BlockPagination,
    PdfViewer
  },
  data () {
    return {
      fileUploadDropzoneOptions: {
        clickable: '#clickBrowseFilesBtn_' + this.type,
        url: appConfig.baseAPIURL + this.apiUrls.uploadFile,
        headers: { 'Authorization': localStorage.getItem('token') },
        params: this.params,
        method: 'post',
        timeout: 300000,
        addRemoveLinks: true,
      },
      selectedRows: [],
      fileIds: [],
      deleteFileIds: [],
      files: this.value,
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          label: 'File',
          key: 'name',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Uploaded By',
          key: 'user',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Date',
          key: 'date',
          sortable: true,
          tdClass: 'table-body font-weight-bold',
          thClass: 'table-header'
        },
        {
          label: 'Action',
          key: 'action',
          sortable: false,
          tdClass: 'table-body font-weight-bold file-upload-actions-btn',
          thClass: 'table-header'
        }
      ]
    }
  },
  props: {
    documentHeight: {
      type: Number,
      default: 500
    },
    documentWidth: {
      type: Number,
      default: 0
    },
    params: {
      type: Object,
      default: () => ({})
    },
    isCanPin: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    pinnedImage: {
      type: Object,
      default: null,
    },
    objectId: {
      type: Number,
      required: true
    },
    isNeedChangePosition: {
      type: Boolean,
      default: false,
    },
    isDiagramsLayout: {
      type: Boolean,
      default: false,
    },
    isRepairerDiagram: {
      type: Boolean,
      default: false,
    },
    isQuotes: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'image'
    },
    value: {
      type: Array,
      required: true
    },
    isSupplierRfqViewPage: {
      type: Boolean,
      default: false
    },
    apiUrls: {
      type: Object,
      default: function () {
        return {
          uploadFile: '/ir/repairer/rfq/uploadFile',
          main: '/ir/repairer/rfq/',
          removeFile: ''
        }
      }
    },
    filesAccepted: {
      type: String,
      default: 'application/pdf, .doc, .docx'
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.files = this.value
  },
  methods: {
    clickOnBrowseFiles() {
      this.$refs.vueDropzoneButton.click();
    },
    getStyle() {
      if (this.isSelectedPDFFiles && this.documentWidth) {
        return {
          width: this.documentWidth + 'px',
          maxWidth: this.documentWidth + 'px',
          flex: 'none',
          padding: '0px',
          margin: '0px'
        }
      }
      return {}
    },
    selectRow(ind) {
      this.$refs['files-table'].selectRow(ind)
    },
    onClosePdf() {
      this.$nextTick(() => {
        this.selectedRows = []
      })
      this.$emit('openPDF', false)
    },
    handleRowSelected (row) {
        let newRow = _.filter(row, (r) => {
          return !_.find(this.selectedRows, (sR) => {
            return Number(r.id) === Number(sR.id);
          })
        })

        let index = row.indexOf(newRow[0]);
        console.log(newRow, index);
        if (row.length >= 2) {
          let toUnselected = _.filter(row, (r ,ind) => {
            return ind !== index
          })
          toUnselected.forEach(item => {
            const idx = this.files.findIndex(it => item == it)
            if (idx >= 0) {
              this.$refs['files-table'].unselectRow(idx)
            }
          })
        }
        this.selectedRows = row
        this.$emit('selectedRows', row)
        this.$emit('openPDF', _.some(row, (r)  => {return r?.fileType === 'application/pdf'}))
    },
    isCanRemove(item){
       return Boolean(item && item.isOwner && item.isOwner === true);
    },
    getImagesConfig: function () {
      let config = this.fileUploadDropzoneOptions
      config.acceptedFiles = 'image/jpeg, image/jpg, image/png'
      config.thumbnailWidth = 200
      config.resizeWidth = 1500
      config.resizeQuality = 0.7
      config.maxFilesize = 5
      config.maxFiles = 15
      config.dictFileTooBig = 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      return config
    },
    getFilesConfig: function () {
      let config = this.fileUploadDropzoneOptions
      config.acceptedFiles = this.filesAccepted
      config.thumbnailWidth = 200
      config.resizeWidth = 1500
      config.resizeQuality = 0.7
      config.maxFilesize = 5
      config.maxFiles = 15
      config.dictFileTooBig = 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      return config
    },
    getConfig: function () {
      return (this.type === 'image') ? this.getImagesConfig() : this.getFilesConfig()
    },
    autoSaveFile: function () {
      let data = {}

      if (this.type === 'image') data.imageIds = this.fileIds
      else
        data.fileIds = this.fileIds

      Axios.post(this.apiUrls.main + this.objectId, data)
          .then(response => {
            if (response.data && response.data.rfq) {
              if (this.type === 'image') {
                this.files = response.data.rfq.images
              } else {
                this.$emit('updatedFiles', response.data.rfq.files)
                // this.files = (this.type === 'image') ? response.data.rfq.images : response.data.rfq.files
              }
              this.fileIds = []
            } else if (response.data && response.data.credit) {
              this.files =  response.data.credit.images
              this.fileIds = []
            }else if (response.data && response.data.order) {
              this.files =  response.data.order.files
              this.fileIds = []
            }
            this.$toast.success('File has been uploaded')
          })
          .catch(error => {
            this.$toast.error('File hasn\'t been uploaded')
            console.log(error)
          })
    },
    autoRemoveFile: function () {
      let data = {},
          remove = this.apiUrls.main + this.objectId

      if (this.type === 'image') data.deleteImageIds = this.deleteFileIds
      else
        data.deleteFileIds = this.deleteFileIds

      if (!_.isEmpty(this.apiUrls.removeFile)) remove = this.apiUrls.removeFile

      Axios.post(remove, data)
          .then(() => {
            this.$emit('removedFiles', this.deleteFileIds)
            this.deleteFileIds = []
            this.$toast.success('File has been deleted')
          })
          .catch(error => {
            this.$toast.error('File hasn\'t been deleted')
            console.log(error)
          })
    },
    autoFileImage: function () {
      let update = {}
      update.fileIds = this.fileIds
    },
    addFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successFileUpload (file, response) {
      if (response.status || response._status) {
        this.fileIds.push(response.fileId)
        this.autoSaveFile()
        let vm = this
        setTimeout(function () {
          vm.$refs.fileUpDrop.removeFile(file)
        }, 1000)
      }
    },
    sendFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },

    clickCancelDropzoneFiles: function () {
      this.$refs.fileUpDrop.removeAllFiles(true)
      this.fileIds = []
    },
    deleteImage: function (index, file) {
      this.deleteFile(file)
    },
    deleteFile: function (file) {
      if (file && file.id && file.id > 0) {
        this.deleteFileIds.push(file.id)
        this.autoRemoveFile()
        this.files = _.reject(this.files, function (item) {
          return Number(item.id) === Number(file.id)
        })
        if (_.isEmpty(this.files)) {
          this.$emit('removedLastFile')
        }
      }
    },
  },
  computed: {
    isSelectedPDFFiles() {
      return _.some(this.selectedRows, (f) => {
        return f.fileType === 'application/pdf'
      })
    },
    computedPdfFile() {
      return _.find(this.selectedRows, (f) => {
        return f.fileType === 'application/pdf'
      })
    },
    computedFiles() {
      return this.files
    },
    startPagePosition () {
      return (Number(this.currentPage) - 1) * Number(this.perPage) + 1
    },
    endPagePosition () {
      let end = (this.startPagePosition + Number(this.perPage)) - 1
      return (this.files.length < end) ? this.files.length : end
    },
    isMobile () {
      return isMobile
    }
  },
  watch: {
    value: {
      handler: function (val) {
        // if (!_.isEmpty(val) && val.length !== old?.length) {
        //   this.files = val
        // }
        this.$set(this, 'files', val)
      },
      deep: true
    },
    files: {
      handler: function (val) {
        if (!_.isEmpty(val)) this.$emit('input', val)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
i.bx-trash {
  font-size: 20px;
  color: #FF5E5E;
  cursor: pointer;
}

i.bx-download {
  font-size: 20px;
  color: #79B1BB;
  cursor: pointer;
  margin-right: 10px;
}

i.bx-camera {
  font-size: 42px;
  color: #79B1BB;
  cursor: pointer;
}

a {
  color: #212529;
}

a:hover {
  color: #79B1BB;
  text-decoration: underline;
}

.block-uploader .files-upload {
  width: 864px;
  margin-left: 15px;
  margin-top: 15px;
}

.block-uploader .files-upload {
  width: 100%;
  padding: 15px 15px 0 15px;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;

}

.files-diagram-position {
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
}

.browse-btn {
  white-space: nowrap;
}

@media screen and (min-width: 993px) {
  .block-uploader .files-upload {
    width: 864px;
    padding: unset;
    margin-left: 15px;
    margin-top: 15px;
  }

  .pagination-block-paginator {
    right: auto !important;
    left: 1000px !important;
  }
}

.reverse-columns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-right: 15px;
  width: auto !important;
}

.width-auto {
  width: 100% !important;
  margin-left: 0 !important;
}

.files-diagram-repairer .file-block {
  width: auto;
}

@media (max-width: 900px) {
  .reverse-columns {
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1466px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 764px!important;
  }
}
@media screen and (max-width: 1360px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 664px!important;
  }
}
@media screen and (max-width: 1260px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 564px!important;
  }
  .reverse-columns.file-uploader-component.file-uploader-component {
    padding-bottom: 66px;
  }
}
@media screen and (max-width: 1160px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 464px!important;
  }
}
@media screen and (max-width: 1060px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 420px!important;
  }
}
@media screen and (max-width: 1005px) {
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: 420px!important;
  }
  .reverse-columns.file-uploader-component {
    flex-direction: column-reverse;
  }
  .block-uploader.reverse-columns.file-uploader-component .files-upload {
    width: auto!important;
  }
}
</style>

<style>
.block-uploader .files-upload .vue-dropzone .dz-error-message {
  top: 0 !important;
}

.supplier-rfq-view-page .files-diagram-position .pagination-block-paginator,
.supplier-rfq-view-page .files-diagram-position .pagination-block-showing {
  bottom: auto;
}

.supplier-rfq-view-page .files-diagram-position .pagination-block {
  margin-bottom: 50px;
}

.supplier-rfq-view-page .files-diagram-position .block-uploader {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.supplier-rfq-view-page .block-uploader .b-table-row-selected {
  /*background-color: #e9f8f9 !important;*/
}
.supplier-rfq-view-page .block-uploader .table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: #e9f8f9 !important;
}
</style>
