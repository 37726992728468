<template>
  <div class="credit-view-page" @touchstart="touchClose">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>{{ pageTitle }} <span class="autosave">{{ autoSaveMessage }}</span></h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item home">
              <router-link to="/r">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="'/r/credits'">View Credit Management</router-link>
            </li>
            <li class="breadcrumb-item active">
              <span class="d-none d-lg-inline-block">View Credit Details</span>
            </li>
          </ol>
        </div>
        <div class="search-filter-in-header">
          <search-filter
              type="estimates"
              v-model="searchFilter"
              :filterValue="searchFilter"
              @onEnter="onEnter"
              @selectAdditional="redirectToRfqView"
              :placeholder="'Search'"
              :isSearch="true"
              :additionalMenu="additionalMenu"
              :debug="false"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>
      <!-- mobile options -->
      <div class="page-header-nav-options">
        <b-dropdown id="credit-view-options-mobile" text="Options" class="dropdown-options dropdown-actions" :class="{'large-options': selectedRow.length > 0}">
          <template v-slot:button-content>
            Actions
            <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
          </template>
          <b-dropdown-item
              :disabled="isDisableMarkAsReturned"
              @click="markAsReturnedModal(getSelectedPart())"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltip(), placement:'leftbottom'}">
            Mark as Returned
          </b-dropdown-item>

          <b-dropdown-item @click="printCreditNote">
            <span v-if="selectedRow.length > 1">Print Selected Parts Credit Note</span>
            <span v-else-if="selectedRow.length == 1">Print Selected Part Credit Note</span>
            <span v-else>Print Credit Note</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="credit-view-body">
      <tabs :custom-counts="computedTotalCounts">
        <tab name="Credit Part List" id="creditPartList" :class-name="'credit-view-tab-1'">
          <div class="credit-additional-block d-flex">
            <div class="credit-title col-sm-5">
              <div class="credit-title__item font-weight-bold">
                <span class="ps-label-value__label">Supplier :</span>
                <span class="ps-label-value__value">{{ credit.supplier.name }}</span>
              </div>
              <div class="credit-title__item font-weight-bold">
                <span class="ps-label-value__label">Type :</span>
                <span class="ps-label-value__value">{{ getTypes(credit.supplier.types) }}</span>
              </div>
              <div class="credit-title__item font-weight-bold">
                <span class="ps-label-value__label">Parts Dept PH :</span>
                <span class="ps-label-value__value">{{ credit.supplier.partsDepartmentPhone }}</span>
              </div>
            </div>
            <div class="credit-additional-block d-flex col-sm-7" style="justify-content: flex-end;">
              <div class="credit-title">
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">Order placed :</span>
                  <span class="ps-label-value__value">{{ formatDateTime(credit.order.createdOn) }}</span>
                </div>
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">Order Number :</span>
                  <span class="ps-label-value__value"><a @click.prevent="goToOrder" :href="'#'">{{ credit.order.number }}</a></span>
                </div>
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">VIN :</span>
                  <span class="ps-label-value__value">{{ credit.rfq.vehicle.vin }}</span>
                </div>
              </div>
              <div class="credit-title">
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">Business :</span>
                  <span class="ps-label-value__value">{{ credit.rfq.businessName }}</span>
                </div>
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">Address :</span>
                  <span class="ps-label-value__value">{{ credit.address }}</span>
                </div>
                <div class="credit-title__item font-weight-bold">
                  <span class="ps-label-value__label">Estimator :</span>
                  <span class="ps-label-value__value">{{ credit.rfq.estimator }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="wizard__divider"></div>

          <div class="d-flex justify-content-end credit-order-numbers">
            <div class="d-flex justify-content-between align-items-center credit-order-numbers-item">
              <span class="credit-order-numbers-title" style="min-width: 111px">Invoice Number</span>
              <b-form-input @focus="isInvoiceNumberInFocus = true" @blur="isInvoiceNumberInFocus = false" v-model="credit.invoiceNumber" @change="saveInvoiceNbr" class="form-control"></b-form-input>
            </div>
            <div class="d-flex justify-content-between align-items-center credit-order-numbers-item" style="margin-left: 35px;">
              <span class="credit-order-numbers-title">Order Nbr</span>
              <b-form-input v-model="credit.order.number" :disabled="true" :readonly="true"
                            class="form-control"></b-form-input>
            </div>
            <div class="d-flex justify-content-between align-items-center credit-order-numbers-item">
              <span class="credit-order-numbers-title">Credit Nbr</span>
              <b-form-input v-model="credit.manualNumber" :disabled="true" :readonly="true"
                            class="form-control"></b-form-input>
            </div>
          </div>

          <b-table
              responsive
              hover
              selectable
              :select-mode="'range'"
              ref="creditPartsTable"
              :items="creditPartsItems"
              :fields="partsFields"
              :tbody-tr-class="rowClass"
              :id="'credit-parts-table'"
              :primary-key="'id'"
              @row-clicked="handleRowClicked"
              @row-selected="handleRowSelected"
              @row-contextmenu="handleRightClick"
              class="margin-bottom-60-table">
            <template v-slot:table-colgroup="scope">
              <col class="credit-view__row credit-view__row--xl">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--xs">
              <col class="credit-view__row credit-view__row--sm">
              <col class="credit-view__row credit-view__row--lg">
              <col class="credit-view__row credit-view__row--xs">
            </template>

            <template v-slot:head(name)="data">
              <div class="d-flex">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         v-model="isCheckedAll"
                         type="checkbox"
                  >
                  <span class="icon clickable"><i
                      class='bx bx-check'></i></span>
                </label>
                <span style="padding-top: 12px;">Part Name</span>
              </div>
            </template>

            <template v-slot:cell(name)="row">
              <div class="d-flex" @touchstart="touchStart($event, row.item)" @touchend="touchEnd">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         @change="onCheckboxChange(row.item, row.item.isChecked)"
                         v-model="row.item.isChecked"
                         type="checkbox"
                  >
                  <span class="icon clickable" style="transform: translateX(10px)"><i
                      class='bx bx-check'></i></span>
                </label>
                <div class="d-flex justify-content-between areaTouch">
                  <span class="credit-table-name-text">{{ row.item.name }}</span>
                  <i
                      class='bx bx-reset'
                      @click="showModalByIcon(row.item)"
                      @touchstart="showModalByIcon(row.item)"
                      v-if="row.item.isMarkAsReturned"
                      v-b-tooltip.hover="{
                       customClass: 'ps-tooltip',
                       html: true,
                       title: formatDateTime(row.item.markAsReturned.returnedDateTime) + '<br/> Part Marked as returned - Click to View',
                       placement:'leftbottom'
                      }"></i>
                  <div class="table-name-border-bottom"></div>
                </div>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              <span
                  v-bind:class="{'color_denied': row.item.status === 'Denied','color_approved': row.item.status === 'Approved', 'color_review': row.item.status === 'UnderReview' || row.item.status === 'Cancelled'}">
                <template>{{ viewCreditStatus(row.item.status) }}</template>
              </span>
            </template>
            <template v-slot:cell(number)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch w-100 h-100">
                <span>{{row.item.number}}</span>
              </div>
            </template>
            <template v-slot:cell(qtyOrdered)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">{{row.value}}</div>
            </template>
            <template v-slot:cell(qtyReceived)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">{{Number(row.value) - Number(row.item.qtyOrdered)}}</div>
            </template>
            <template v-slot:cell(price)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span v-if="Number(row.value)">-</span>
                <span>{{ row.value | formatMoney }}</span>
              </div>
            </template>
            <template v-slot:cell(rrp)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span>{{ row.value | formatMoney }}</span>
              </div>
            </template>
            <template v-slot:cell(amount)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span v-if="Number(row.value)">-</span>
                <span>{{ row.value | formatMoney }}</span>
              </div>
            </template>
            <template v-slot:cell(amountApproved)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span v-if="row.value>0">-{{ row.value | formatMoney }}</span>
              </div>
            </template>
            <template v-slot:cell(dateCreditCreated)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span>{{ row.value | formatDatetime }}</span>
              </div>
            </template>
            <template v-slot:cell(orderNumber)="row">
              <div @touchstart="touchStart($event, row.item)" @touchend="touchEnd" class="areaTouch">
                <span>{{ credit.order.number }}</span>
              </div>
            </template>
            <template v-slot:head()="data">
              <div class="areaTouch">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:row-details="row">
              <div class="td-wrapper areaTouch">
                <credit-details
                    :ref="'credit-details-' + row.item.id"
                    :creditPart="row.item"
                    @autoSave="changedCreditComment"
                    @closeContext="closeContextForReasonAction"
                    @openContext="openMenuForReasonAction"
                    @openRowContext="event => handleRightClick(row, row.index, event)"
                >
                </credit-details>
              </div>
            </template>
          </b-table>
        </tab>
        <tab name="Images" id="images">
          <file-upload v-model="credit.images"
                       :api-urls="{uploadFile: '/ir/repairer/credit/upload-file',main: '/ir/repairer/credit/'}"
                       :object-id="Number(credit.id)" type="image"></file-upload>
        </tab>
        <tab name="Activity" id="activity">
          Activity page
        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-btn mr-4">
            <!--            <a v-if="isEditable" class="btn btn-primary clickable d-none d-lg-inline-block"-->
            <!--               @click="clickSave"-->
            <!--               style=" vertical-align: middle; padding-left:25px; padding-right:25px;">-->
            <!--              Save-->
            <!--            </a>-->
          </div>
          <!-- desktop options -->
          <div class="tabs-nav-right-options">
            <chat v-if="true" @closeChat="onCloseChat" :isShowChatInit="!!$route.params.isNeedOpenChat" :userName="credit && credit.supplier && credit.supplier.name ? credit.supplier.name : ''" :messages="chatMessages" :is-chatter-online="chatSupplierIsOnline" :typers="chatTypers" @onChatMessageSubmit="onChatMessageSubmit" @onTyping="onChatInputTyping"></chat>
            <b-dropdown id="credit-view-options-desktop" text="Options" class="dropdown-options  dropdown-actions" :class="{'large-options': selectedRows.length > 0}">
              <template v-slot:button-content>
                Actions
                <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
              </template>
              <b-dropdown-item
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getTooltip(), placement:'leftbottom'}"
                  :class="{
                    'disabled-button': isDisableMarkAsReturned
                  }"
                  @click="markAsReturnedModal(getSelectedPart())"
                  :disabled="isDisableMarkAsReturned">
                Mark as Returned
              </b-dropdown-item>

              <b-dropdown-item @click="printCreditNote">
                <span v-if="selectedRow.length > 1">Print Selected Parts Credit Note</span>
                <span v-else-if="selectedRow.length == 1">Print Selected Part Credit Note</span>
                <span v-else>Print Credit Note</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>
    <vue-context ref="menuCredit"
                 :lazy="true"
                 @close="contextClose"
                 :close-on-click="false"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar v-if="item && item.data && item.data.row && selectedRow.length == 1" key-nav>
          <b-button-group vertical class="credit-view__context-menu">
            <div
                v-if="item.data.row._showDetails && item.data.row.status === 'UnderReview' && !item.data.row.cancelCreditReason"
                @mouseover="isShowReasonForCancellation = true"
                @mouseleave="isShowReasonForCancellation = false"
            >
              <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" style="width: 210px" block>
                Cancel Credit
              </b-button>
              <div v-if="isShowReasonForCancellation" class="">
                <textarea
                    v-model="item.data.row.cancelCreditReasonDraft"
                    placeholder="Reason for Cancellation"
                    style="top: 0px"
                    class="form-control credit-cancel-reason credit-cancel-reason-position-left">
                </textarea>
                <i
                    @click="saveCancelCredit(item.data.row)"
                    style=" top: 70px;"
                    :class="{
                      'credit-cancel-save-icon-2': item.data.row.isEditCredit
                    }"
                    class="bx bx-save credit-cancel-save-icon clickable">
                </i>
              </div>
            </div>
            <template v-if="!item.data.row._showDetails">
              <b-button @click="toggleDetails(item.data.row)"
                        class="text-left font-12 font-inter--semibold"
                        variant="outline-primary" block>
                View Credit
              </b-button>
              <b-button v-if="!item.data.row.isEditCredit && item.data.row.status === 'UnderReview'"
                        @click="editCredit2(item.data.row)" class="text-left font-12 font-inter--semibold"
                        variant="outline-primary" block>
                Edit Credit
              </b-button>
            </template>
            <b-button
                v-if="!item.data.row.isEditCredit && item.data.row._showDetails && item.data.row.status === 'UnderReview'"
                @click="editCredit(item.data.row)" class="text-left font-12 font-inter--semibold"
                variant="outline-primary" block>
              Edit Credit
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" @click="printCreditNote"
                      variant="outline-primary" block>
              Print Selected Part Credit Note
            </b-button>
            <b-button
                v-if="item.data.row.status !== 'Denied' && item.data.row._showDetails && (item.data.row.status === 'Cancelled' || item.data.row.cancelCreditReason)"
                @click="undoCancelCredit(item.data.row)" class="text-left font-12 font-inter--semibold"
                variant="outline-primary" block>
              Undo Cancellation
            </b-button>
              <b-button
                  v-if="!item.data.row.isMarkAsReturned"
                  class="text-left font-12 font-inter--semibold"
                  variant="outline-primary"
                  @click="markAsReturnedModal(item.data.row)"
                  v-b-modal.mark-as-returned-modal
                  block>
                Mark as Returned
              </b-button>
          </b-button-group>
        </b-button-toolbar>
        <b-button-toolbar v-else-if="selectedRow.length > 1" key-nav>
          <b-button-group vertical class="credit-view__context-menu">
            <div
                v-if="isCanCancelCredit"
                @mouseover="isShowReasonForCancellation = true"
                @mouseleave="isShowReasonForCancellation = false">
              <b-button class="text-left font-12 font-inter--semibold" variant="outline-primary" style="width: 215px" block>
                Cancel Credit
              </b-button>
              <div v-if="isShowReasonForCancellation" class="">
                <textarea
                    style="top: 0px; left: 215px;"
                    v-model="cancelCreditReasonDraft"
                    placeholder="Reason for Cancellation"
                    class="form-control credit-cancel-reason">
                </textarea>
                <i
                    @click="saveCancelCredit()"
                    style="top: 70px"
                    class="bx bx-save credit-cancel-save-icon clickable">
                </i>
              </div>
            </div>
            <b-button
                v-if="isCanUndoCancellation"
                @click="undoCancelCredit(item.data.row)" class="text-left font-12 font-inter--semibold"
                variant="outline-primary" block>
              Undo Cancellation
            </b-button>
            <b-button class="text-left font-12 font-inter--semibold" @click="printCreditNote"
                      variant="outline-primary" block>
              Print Selected Parts Credit Note
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>

    <div>
      <div v-if="isShowLoader" :style="{left: leftForLoader + 'px', top: topForLoader + 'px'}" id="context-loader">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block; border-radius: 50%;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" r="0" fill="none" stroke="#29bbc1" stroke-width="17">
            <animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;58" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
            <animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
          </circle><circle cx="50" cy="50" r="0" fill="none" stroke="#131923" stroke-width="17">
            <animate attributeName="r" repeatCount="indefinite" dur="1.25s" values="0;58" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.625s"></animate>
            <animate attributeName="opacity" repeatCount="indefinite" dur="1.25s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.625s"></animate>
          </circle>
        </svg>
      </div>
      <ul v-if="isShowContext"
          class=""
          :style="{left: leftForTouchMenu + 'px', top: topForTouchMenu + 'px', position: 'absolute'}"
          id="context-menu-iphone">
        <template v-if="selectedRow.length > 1">
          <li
              v-if="isCanUndoCancellation"
              @touchstart="isShowReasonForCancellation = true"
              class="areaTouch context-menu-iphone-btn">
            <div class="text-left font-12 font-inter--semibold" variant="outline-primary" style="width: 215px" block>
              Cancel Credit
            </div>
            <div v-if="isShowReasonForCancellation" class="context-menu-iphone-level-2">
                <textarea
                    id="context-menu-iphone-level-2"
                    v-model="cancelCreditReasonDraft"
                    @touchend="readReasonCancellation(item, $event)"
                    style="top: 0px"
                    placeholder="Reason for Cancellation"
                    class="form-control areaTouch credit-cancel-reason">
                </textarea>
              <i
                  @click="saveCancelCredit()"
                  @touchstart="saveCancelCredit(null, $event)"
                  style="top: 70px"
                  class="bx bx-save credit-cancel-save-icon clickable">
              </i>
            </div>
          </li>
          <li
              v-if="isCanUndoCancellation"
              @click="undoCancelCredit(item)"
              @touchstart="undoCancelCredit(item, $event)"
              class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
              variant="outline-primary" block>
            Undo Cancellation
          </li>
          <li
              class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
              variant="outline-primary"
              @click="printCreditNote"
              @touchstart="printCreditNote">
            Print Selected Parts Credit Note
          </li>
        </template>
        <template v-else>
          <li
              v-if="item._showDetails && item.status === 'UnderReview' && !item.cancelCreditReason"
              @touchstart="isShowReasonForCancellation = true"
              class="areaTouch context-menu-iphone-btn">
            <div class="text-left font-12 font-inter--semibold" variant="outline-primary" style="width: 210px" block>
              Cancel Credit
            </div>
            <div v-if="isShowReasonForCancellation" class="context-menu-iphone-level-2">
                <textarea
                    id="context-menu-iphone-level-2"
                    style="top: 0px"
                    v-model="item.cancelCreditReasonDraft"
                    @touchend="readReasonCancellation(item, $event)"
                    placeholder="Reason for Cancellation"
                    :class="{
                      'credit-cancel-reason-only': item.isEditCredit,
                      'textarea-left': leftForTouchMenu == 0,
                    }"
                    class="form-control areaTouch credit-cancel-reason">
                </textarea>
              <i
                  @click="saveCancelCredit(item)"
                  @touchstart="saveCancelCredit(item, $event)"
                  style="top: 70px"
                  :class="{
                      'credit-cancel-save-icon-2': item.isEditCredit
                    }"
                  class="bx bx-save credit-cancel-save-icon clickable">
              </i>
            </div>
          </li>
          <template v-if="!item._showDetails">
            <li
                @click="toggleDetails(item)"
                @touchstart="toggleDetails(item, $event)"
                class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
                variant="outline-primary" block>
              View Credit
            </li>
            <li v-if="!item.isEditCredit && item.status === 'UnderReview'"
                @click="editCredit2(item)"
                @touchstart="editCredit2(item, $event)"
                class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
                variant="outline-primary" block>
              Edit Credit
            </li>
            <li
                class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn" @click="printCreditNote"
                variant="outline-primary" block>
              Print Selected Part Credit Note
            </li>
          </template>
          <li
              v-if="!item.isEditCredit && item._showDetails && item.status === 'UnderReview'"
              @click="editCredit(item)"
              @touchstart="editCredit(item, $event)"
              class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
              variant="outline-primary" block>
            Edit Credit
          </li>
          <li
              v-if="item.status !== 'Denied' && item._showDetails && (item.status === 'Cancelled' || item.cancelCreditReason)"
              @click="undoCancelCredit(item)"
              @touchstart="undoCancelCredit(item, $event)"
              class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
              variant="outline-primary" block>
            Undo Cancellation
          </li>
          <li
              v-if="!item.isMarkAsReturned"
              class="text-left font-12 font-inter--semibold areaTouch context-menu-iphone-btn"
              variant="outline-primary"
              @click="markAsReturnedModal(item)"
              @touchstart="markAsReturnedModal(item, $event)">
            Mark as Returned
          </li>
        </template>
      </ul>
    </div>

    <vue-context ref="menuActionForReason" :closeOnScroll="false" @open="isOpenPartContext = true"
                 @close="isOpenPartContext = false">
      <ul slot-scope="item" style="padding: 0px">
        <template>
          <b-button-group vertical class="credit-view__context-menu">
            <b-button
                v-if="item.data
                && item.data.part
                && item.data.isNeedSave
                && item.data.part.comment !== ''"
                @click="saveReason(item.data.part)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Save Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && !item.data.part.isEditCredit
                && (item.data.part.comment !== '' && item.data.part.comment !== null)
                && !item.data.isNotNeedEdit"
                @click="editReason(item.data.part)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Edit Credit
            </b-button>
            <b-button
                v-if="item.data
                && item.data.part
                && (item.data.part.comment !== '' && item.data.part.comment !== null)"
                @click="deleteReason(item.data.part)"
                class="text-left font-12 font-inter--semibold"
                variant="outline-primary"
                block>
              Delete Credit
            </b-button>
          </b-button-group>
        </template>
      </ul>
    </vue-context>

    <b-modal @hide="onCloseModal" ref="markAsReturned" id="mark-as-returned-modal" size="custom-mark-as-returned"
             title="Mark As Returned">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned form-container">
        <form>
          <div class="form-group row pr-3">
            <label class="col col-form-label">Returned By</label>
            <div class="col-7 p-0">


              <multiselect
                  ref="selectedPrtReturnedBy"
                  v-model="computedReturnedBy"
                  :options="returnedOptions"
                  :showLabels="false"
                  :disabled="isModalDisable"
                  :track-by="'key'"
                  :label="'value'"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
              ></multiselect>
            </div>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label">Date</label>
            <div class="col-7 p-0">
              <date-picker :isDisabled="isModalDisable" v-model="selectedPart.markAsReturned.rtnDate"></date-picker>
            </div>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label">Time</label>
            <div class="col-7 p-0">
              <date-picker :isDisabled="isModalDisable" v-model="selectedPart.markAsReturned.rtnTime"
                           :place-holder-text="'hh:mm'" :isTime="true"></date-picker>
            </div>
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label">Driver Name</label>
            <input
                type="text"
                :disabled="isModalDisable"
                v-model="selectedPart.markAsReturned.driverName"
                class="col-7 form-control"
            />
          </div>
          <div class="form-group row pr-3">
            <label class="col col-form-label">Driver ID Nbr</label>
            <input
                type="text"
                :disabled="isModalDisable"
                v-model="selectedPart.markAsReturned.driverID"
                class="col-7 form-control"
            />
          </div>
          <div class="form-group row pr-3 pl-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">Driver Signature</label>
            <template v-if="!isModalDisable">
              <div class="col-12 p-0 mark-as-returned-signature-block" ref="returnedSignatureBlock">
                <VPerfectSignature v-if="!isModalDisable" :stroke-options="strokeOptions" ref="signaturePad"
                  class="mark-as-returned-signature-canvas"/>
              </div>
              <a
                  v-if="!isEmptySignature"
                  class="control-elem-1__item"
                  @click.prevent="clearSignature"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i class="bx bx-trash"></i>
              </a>
            </template>
            <template v-else>
              <div class="mark-as-returned-signature-canvas">
                <img :src="selectedPart.markAsReturned.signature" alt="">
              </div>
            </template>
          </div>
          <div class="form-group row pr-3 pl-3">
            <label class="col col-form-label pl-0">Photo</label>
            <vue-dropzone
                v-if="!isModalDisable"
                ref="imgUpDropPinInage"
                id="imgUploadDropPinImage"
                class="dropify-wrapper dropzoneContainer mark-as-returned-add-photo col-12"
                :useCustomSlot=true
                v-on:vdropzone-file-added="addFileUpload"
                v-on:vdropzone-success="successFileUpload"
                v-on:vdropzone-complete="completeFileUpload"
                v-on:vdropzone-sending="sendFileUpload"
                v-on:vdropzone-removed-file="removedFile"
                :options="photoConfig"
            >
              <div id="clickBrowseImagesBtn" class="dropzone-text">
                <i class='bx' :class="{'bx-camera': isMobile, 'bx-cloud-upload': !isMobile}"></i>
                <p class="drop-down-text p-3 mb-0">
                  <template v-if="isMobile">Take Photo</template>
                  <template v-else>
                    Drag and Drop or Browse Images
                  </template>
                </p>
              </div>
            </vue-dropzone>
            <div v-else class="mark-as-returned-signature-photo-img-block col-12 p-0">
              <template v-if="selectedPart.markAsReturned.photo1">
                <img :src="selectedPart.markAsReturned.photo1.src" alt="" class="mark-as-returned-photo-img">
              </template>
              <template v-if="selectedPart.markAsReturned.photo2">
                <img :src="selectedPart.markAsReturned.photo2.src" alt="" class="mark-as-returned-photo-img">
              </template>
              <template v-if="selectedPart.markAsReturned.photo3">
                <img :src="selectedPart.markAsReturned.photo3.src" alt="" class="mark-as-returned-photo-img">
              </template>

            </div>
          </div>
          <div class="form-group row pr-3 pl-3">
            <label class="col col-form-label pl-0">Comments</label>
            <textarea :disabled="isModalDisable" v-model="selectedPart.markAsReturned.comment"
                      class="col-12 form-control mark-as-returned-text-message" type="text"></textarea>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <template v-if="isModalDisable">
          <b-button class="input-btn cancel mr-2" @click="cancel()">Close</b-button>
        </template>
        <template v-else>
          <b-button class="input-btn cancel mr-2" @click="cancel()">Cancel</b-button>
          <b-button class="input-btn" @click="markAsReturn">Confirm</b-button>
        </template>
      </template>
    </b-modal>

    <b-modal ref="printModal" id="print-modal" size="custom-undo-part-line-change"
             title="Print Credit Note Check">
      <template v-slot:modal-backdrop class="my-backdrop"></template>

      <div class="modal-mark-as-returned form-container">
        <form>
          <div class="form-group row">
            <p class="pl-3 pr-3" style="margin-bottom: 0px">You are missing the suppliers Invoice number to print this form.</p>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ confirm, cancel }">
        <b-button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Close</b-button>
      </template>
    </b-modal>


  </div>
</template>

<script>
/*eslint-disable */
import { VueContext } from 'vue-context'
import CreditDetails from './credit-view/credit-view-details'
import FileUpload from '../rfq/rfq-view/file-upload'
import Axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import * as dayjs from 'dayjs'
import DatePicker from '../../../components/datePicker'
import Multiselect from 'vue-multiselect'
import vueDropzone from 'vue2-dropzone'
import { appConfig } from '../../../config'
import { VPerfectSignature } from 'v-perfect-signature'
import { isMobile } from 'mobile-device-detect'
import Chat from '../../../components/utility/chat/chat'
import {subscribeToCreditChannel, subscribeToRfqChannel} from "@/centrifuge";
import SearchFilter from '../../../components/utility/search-filter/search-filter';

export default {
  name: 'CreditView',
  data () {
    return {
      _chat: null,
      chatMessages: [],
      onlineStatusIntervalId: null,
      chatSupplierIsOnline: false,
      isShowModal: false,
      chatTypers: {},
      selectedRows: [],
      selectedRow: [],
      searchFilter: '',
      isOpenPartContext: false,
      isSelectedManualy: false,
      isInvoiceNumberInFocus: false,
      isEmptySignature: true,
      rtnDate: null,
      rtnTime: null,
      isShowLoader: false,
      interval: null,
      isShowContext: false,
      cancelCreditReasonDraft: null,
      topForLoader: 0,
      topForTouchMenu: 0,
      leftForLoader: 0,
      leftForTouchMenu: 0,
      touchStartTime: 0,
      item: null,
      isUnderCreditRequest: false,
      autoSaveMessage: '',
      selectedRowIndex: null,
      isShowReasonForCancellation: false,
      photoConfig: {
        clickable: '#clickBrowseImagesBtn',
        resizeWidth: 1500,
        resizeQuality: 0.7,
        capture: 'camera',
        url: appConfig.baseAPIURL + '/ir/repairer/credit/upload-file',
        //      url: appConfig.baseAPIURL + '/ir/user/photo/upload',
        headers: { 'Authorization': localStorage.getItem('token') },
        method: 'post',
        maxFilesize: 50,
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: true,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      returnedOptions: [
        {
          key: 'DriverSupplier',
          value: 'Driver - Supplier',
        },
        {
          key: 'DriverCourier',
          value: 'Driver - Courier',
        },
        {
          key: 'OurDriver',
          value: 'Our Driver',
        },],
      strokeOptions: {
        size: 4,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      signature: '',
      selectedPart: {
        markAsReturned: {}
      },
      isModalDisable: false,
      callComputed: 0,
      isHoldCtrl: false,
      isHoldShift: false,
      credit: {
        id: null,
        number: '',
        address: '',
        status: null,
        invoiceNumber: '',
        subStatus: 'None',
        amount: 0,
        images: [],
        order: {
          id: 0,
          createdOn: null,
          number: '',
        },
        parts: [],
        rfq: {
          id: 0,
          estimator: '',
          businessName: '',
          vehicle: {
            make: '',
            model: '',
            series: '',
            rego: '',
          },

        },
        supplier: {
          name: '',
          type: '',
          partsDeptPH: '',
        },
      },
      isEditable: true,
      partsFields: [
        {
          label: 'Part Name',
          key: 'name',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable credit-part-name-transparent',
          thClass: 'font-13 font-inter--bold part-name-sticky',
          class: 'font-inter',
          stickyColumn: true
        },
        {
          label: 'Part Number',
          key: 'number',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Order Number',
          key: 'orderNumber',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Credit Amt',
          key: 'amount',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-cost',
          thClass: 'font-13 font-inter--bold column-cost',
          class: 'font-inter'
        },
        {
          label: 'Qty Order',
          key: 'qtyOrdered',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-qty-order',
          thClass: 'font-13 font-inter--bold column-qty-order',
          class: 'font-inter'
        },
        {
          label: 'Qty Rtn',
          key: 'qtyReceived',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable column-qty-rcvd',
          thClass: 'font-13 font-inter--bold column-qty-rcvd',
          class: 'font-inter'
        },
        {
          label: 'Unit List',
          key: 'rrp',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Unit Net',
          key: 'price',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Amount Approved',
          key: 'amountApproved',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Date Credit Created',
          key: 'dateCreditCreated',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },
        {
          label: 'Credit Status',
          key: 'status',
          sortable: true,
          tdClass: 'font-12 font-inter--semibold clickable text-nowrap',
          thClass: 'font-13 font-inter--bold',
          class: 'font-inter'
        },

      ],
      isLoadedPartOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      creditsFromUpdate: 'repairer/credit/creditsFromUpdate',
      getterTimezone: 'currentCompany/getTimezone',
      companyInfo: 'currentCompany/getDetails',
      gettersAllCredits: 'repairer/credit/getAllCredits',
      currentUser: 'currentUser/getCurrentUserInfo',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter)) {
        return []
      }
      let data = _.filter(this.gettersAllCredits, (r) => {
        return _.startsWith(_.trim(_.toLower(r.number)), _.trim(_.toLower(this.searchFilter)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.number
        if (itm.supplierName) {
          label +=  ' - '
        }

        if (itm.supplierName) {
          label += itm.supplierName + ' '
        }
        result.push({
          value: itm.id,
          label: label,  number: itm.number,
          rfqId: itm.rfqId,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
        if (!_.includes(addedJobViewIds, r.jobViewId)) {
          let l = 'JOB VIEW'
          if (r.number) {
            l = r.number + ' - JOB VIEW'
          }
          if (r.rfqId != r.jobViewId) {
            if (result.length == 1) {
              jobViewOptions.push({
                value: r.rfqId,
                label: l,
                isJobView: true
              })
              addedJobViewIds.push(r.number)
            }
          } else {
            jobViewOptions.push({
              value: r.rfqId,
              label: l,
              isJobView: true
            })
            addedJobViewIds.push(r.number)
          }
        }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    unviewedMessagesIds() {
      let ids = [];
      for(let m of this.chatMessages) {
        if(m.messageId && !m.viewed && m.companyId != this.companyInfo.id) {
          ids.push(m.messageId)
        }
      }
      return ids;
    },
    isCanUndoCancellation() {
      return _.some(this.selectedRow, (r) => {
        return r.status !== 'Denied' && r._showDetails && (r.status === 'Cancelled' || r.cancelCreditReason)
      })
    },
    isCanCancelCredit() {
      return _.some(this.selectedRow, (r) => {
        return r._showDetails && r.status == 'UnderReview' && !r.cancelCreditReason
      })
    },
    isCheckedAll: {
      get: function () {
        if (this.credit.parts.length == 0) {
          return false
        }
        let result = _.filter(this.credit.parts, function (item) {
          return item.isChecked == false
        })

        if (result && result.length > 0) {
          return false
        }

        return true
      },
      set: function (check) {
        _.forEach(this.credit.parts, (prt) => {
          prt.isChecked = check

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(prt.id)
          })

          if (check && !isSelectedPart) {
            this.selectedRow.push(prt)
          } else if (!check && isSelectedPart) {
            let ind = _.findIndex(this.selectedRow, (r) => {
              return Number(r.id) == Number(prt.id)
            })
            this.selectedRow.splice(ind, 1)
          }
          this.callComputed++
        })
      }
    },
    isDisableMarkAsReturned () {
      return this.selectedRow.length !== 1 || (this.selectedRow.length === 1 && this.selectedRow[0].isMarkAsReturned)
    },
    computedReturnedBy: {
      get () {
        try {
          let item = _.find(this.returnedOptions, (i) => {
            return i.key === this.selectedPart.markAsReturned.returnedBy
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set (val) {
        if (this.selectedPart.markAsReturned) {
          this.selectedPart.markAsReturned.returnedBy = val.key
        }
      }
    },
    pageTitle () {
      return `Credit Nbr - ${this.credit && this.credit.number ? this.credit.number : ''} | ${this.credit && this.credit.rfq && this.credit && this.credit.rfq.vehicle && this.credit.rfq.vehicle.make || ''}  - ${this.credit && this.credit.rfq && this.credit && this.credit.rfq.vehicle && this.credit.rfq.vehicle.rego || ''}`
    },
    mobileOptionsClass () {
      if (this.pageTitle.length >= 62 && this.pageTitle.length <= 69) {
        return 'top-15'
      }
      return ''
    },
    creditPartsItems () {
      this.callComputed++
      return this.credit.parts
    },
    computedTotalCounts() {
      let counts = {
        creditPartList: 0,
        images: 0,
      }
      counts.creditPartList = this.credit.parts.length
      counts.images = this.credit.images.length
      return counts
    },
    isMobile () {
      return isMobile
    },
  },
  methods: {
    redirectToRfqView(itm) {
      if (itm.isJobView) {
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({name: 'CreditManagementView', params: {creditId: itm.value}});
      }
    },
    onEnter() {
      setTimeout(() => {
        let credit = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })
        if (credit && !_.isEmpty(this.searchFilter)) {
          this.$router.push({name: 'RepairerJobView', params: {job_id: credit.value}});
        }
      }, 500)
    },
    onCloseChat() {
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
    },
    onChatMessage (data) {
      if(data.type === 'newMessage'){
        this.chatMessages.push(data)
        if(data.messageId && data.companyId != this.companyInfo.id){
          this.markMessagesAsViewed([data.messageId]);
        }
      }
      if(data.type === 'supplierIsOnline'){
        this.chatSupplierIsOnline = true;
        this.supplierLeavedTheChat();
      }
      /*
      {
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      }
       */
      if(data.type === 'typingInChat' && data.id !== this.currentUser.id){
        this.chatTypers[data.id] = {
          name: data.name,
          microtime: Date.now(),
        }
      }
    },
    sendChatMessage(text){
      if(!text || !text.trim()){
        return new Promise((resolve) => {
          resolve(false)
        });
      }
      return Axios.post('/centrifuge/send-message-to-credit', {
        creditId: this.credit.id,
        message: text.trim(),
      })
    },
    onChatMessageSubmit({message,resolve}){
      if(this.unviewedMessagesIds.length > 0){
        this.markMessagesAsViewed(this.unviewedMessagesIds);
        _.forEach(this.unviewedMessagesIds, (id) => {
          let index = _.findIndex(this.chatMessages, (m) => {
            return m.messageId === id
          })
          if (index !== -1) {
            this.$nextTick(() => {
              let obj = _.cloneDeep(this.chatMessages[index])
              obj.viewed = true
              this.$set(this.chatMessages, index, obj)
            })
          }
        })
      }
      this.sendChatMessage(message).then(res => {
        resolve(res.data?._status)
      }).catch(() => {
        resolve(false)
      })
    },
    markMessagesAsViewed(messageIds = []){
      return Axios.post('/centrifuge/mark-chat-messages-as-viewed', {
        messageIds
      })
    },
    onChatInputTyping(){
      this._chat.publish({
        type: 'typingInChat',
        id: this.currentUser.id,
        name: this.currentUser.firstName,
      })
    },
    handleRowSelected (rows) {
      if (this.isSelectedManualy) {
        setTimeout(() => {
          this.isSelectedManualy = false
        }, 10)
        // this.isSelectedManualy = false;
        return
      }
      this.selectedRow = rows
      _.forEach(rows, row => {
        let index = _.findIndex(this.credit.parts, (prt) => {
          return Number(prt.id) == Number(row.id)
        })
        if (index !== -1) {
          this.$nextTick(() => {
            if (this.credit.parts[index].isChecked && (!this.isHoldShift && !this.isHoldCtrl)) {
              this.$set(this.credit.parts[index], 'isChecked', false)
            } else {
              this.$set(this.credit.parts[index], 'isChecked', true)
            }
          })
        }
      })
      this.$refs.creditPartsTable.clearSelected()

      this.callComputed++

      setTimeout(() => {
        _.forEach(this.credit.parts, (part, index) => {
          if (part.isChecked) {
            let isSelectedPart = _.find(this.selectedRow, (r) => {
              return Number(r.id) == Number(part.id)
            })
            if (!isSelectedPart) {
              this.selectedRow.push(part)
            }
          }
        })
      }, 10)
    },
    handleRowClicked (row, index, event) {
      setTimeout(() => {
        let checkedRows = _.filter(this.credit.parts, (prt) => {
          return prt.isChecked
        })
        if (checkedRows.length == 1) {
          let ind = _.findIndex(this.credit.parts, (prt) => {
            return Number(prt.id) == Number(checkedRows[0].id)
          })
          if (ind !== 1) {
            if (!this.$refs.creditPartsTable.selectedRows[ind]) {
              this.isSelectedManualy = true
              this.$refs.creditPartsTable.selectRow(ind)
              this.$set(this.credit.parts[ind], 'isChecked', true)
            } else {
              this.$refs.creditPartsTable.unselectRow(ind)
              this.$set(this.credit.parts[ind], 'isChecked', false)
            }
          }
        }
      }, 100)
      //
      // const orderTable = this.$refs.creditPartsTable
      // const rowSelected = () => orderTable.isRowSelected(index)
      // const hasDetail = this.partCanRequestCredit(this.credit.parts[index])
      //
      // const el = event.srcElement
      // if (el && (el.cellIndex === 2 || el.parentElement.cellIndex === 2 || el.parentElement.parentElement.cellIndex === 2)) {
      //   //console.log('icon cell detected')
      //   this.$nextTick(() => {
      //     orderTable.unselectRow(index)
      //   })
      // }

    },
    onCheckboxChange(item, value) {
      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) == Number(item.id)
      })
      if (index !== -1) {
        // if (value) {
        //   this.$refs.orderTable.selectRow(index)
        // } else {
        //   this.$refs.orderTable.unselectRow(index)
        // }

        this.$set(this.credit.parts[index], 'isChecked', value)

        let isSelectedPart = _.find(this.selectedRow, (r) => {
          return Number(r.id) == Number(item.id)
        })

        if (value && !isSelectedPart) {
          this.selectedRow.push(item)
        } else if (!value && isSelectedPart) {
          let ind = _.findIndex(this.selectedRow, (r) => {
            return Number(r.id) == Number(item.id)
          })
          this.selectedRow.splice(ind, 1)
        }

      }
    },
    checkCtrlAndShift(e) {
      if (e.which == 17) {
        this.isHoldCtrl = true
      } else if (e.which == 16) {
        this.isHoldShift = true
      }
    },
    removeCtrlAndShift() {
      this.isHoldCtrl = false
      this.isHoldShift = false
    },
    getTypes(types) {
      if (types?.length == 1) {
        return types[0]
      } else if (types?.length > 1) {
        return 'Multiple'
      }
      return ''
    },
    saveInvoiceNbr() {
      NProgress.start()
      Axios.post(`/ir/repairer/credit/${this.$route.params.creditId}`, { invoiceNumber: this.credit.invoiceNumber })
          .then(response => {
            if (response.data._status) {
            }
          })
      .finally(() => {
        NProgress.done();
      })
    },
    printCreditNote() {
      this.$refs.menuCredit.close()
      if (_.isEmpty(this.credit.invoiceNumber)) {
        this.$refs.printModal.show()
      } else {
        let creditId = this.$route.params.creditId;
        let token = localStorage.getItem('token');

        let parts = '';
        if (!_.isEmpty(this.selectedRow)) {
          parts = '?ids=';
          _.forEach(this.selectedRow, (p, ind) => {
            parts += p.id
            if (ind + 1 !== this.selectedRow.length) {
              parts += ','
            }
          })
        }

        window.open(appConfig.baseAPIURL + `/ir/repairer/credit/${creditId}/print-note${parts}?tz=${this.getterTimezone}&at=${token}`);
      }
    },
    handleResize() {
      if (!this.isShowModal) {
        return
      }
      setTimeout(() => {
        this.setCanvasWidthAndHeight()
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.inputPointsHandler()
        }
      }, 100)
    },
    setCanvasWidthAndHeight() {
      let signatureCanvas = document.getElementsByClassName('mark-as-returned-signature-canvas')
      if (signatureCanvas && signatureCanvas[0]) {
        let width = this.$refs.returnedSignatureBlock.offsetWidth;
        signatureCanvas[0].width = width
        signatureCanvas[0].style.width = width + 'px'
        signatureCanvas[0].height = 100
        signatureCanvas[0].style.height = '100px'
      }
    },
    getTooltip () {
      if (this.selectedRow.length === 0) {
        return 'Please choose one part'
      }
      if (this.selectedRow.length !== 1) {
        return 'Please choose only one part'
      }
      if (this.selectedRow[0].isMarkAsReturned) {
        return 'This part already marked as return'
      }
      return ''
    },
    getSelectedPart() {
      return _.find(this.credit.parts, (prt) => {
        return Number(prt.id) === Number(this.selectedRow[0]?.id)
      })
    },
    openAllSelectedParts () {
      _.forEach(this.credit.parts, (prt, index) => {
        prt._showDetails = true;
      })
    },
    goToOrder () {
      this.$router.push('/r/order/' + this.credit.order.id)
    },
    onCloseModal () {
      this.isModalDisable = false
      this.isShowModal = false;
      this.$nextTick(() => {
        this.clearSelectedRows()
      })
    },
    checkSignature () {
      if (this?.$refs?.signaturePad) {
        this.isEmptySignature = this.$refs.signaturePad.isEmpty()
      }
    },
    markAsReturn () {
      if (_.isEmpty(this.selectedPart.markAsReturned.rtnDate) || _.isEmpty(this.selectedPart.markAsReturned.rtnTime) || _.isEmpty(this.selectedPart.markAsReturned.returnedBy)) {
        this.$toast.error('Returned By, Date and Time are required fields')
        return
      }

      let signaturePad = this.$refs.signaturePad
      let signature = null
      if (signaturePad) {
        console.log(signaturePad, 'signature')
        signature = signaturePad.toDataURL()
      }

      let jsonData = {
        markAsReturned: {
          returnedDateTime: dayjs(this.selectedPart.markAsReturned.rtnDate + ' ' + this.selectedPart.markAsReturned.rtnTime, 'DD/MM/YYYY hh:mmA').format(),
          driverName: this.selectedPart.markAsReturned.driverName,
          returnedBy: this.selectedPart.markAsReturned.returnedBy,
          driverID: this.selectedPart.markAsReturned.driverID,
          signature: signature ? signature : null,
          comment: this.selectedPart.markAsReturned.comment,
          photo1: this?.selectedPart?.markAsReturned?.photos?.[0]?.id ? this.selectedPart.markAsReturned.photos[0].id : null, //integer
          photo2: this?.selectedPart?.markAsReturned?.photos?.[1]?.id ? this.selectedPart.markAsReturned.photos[1].id : null, //integer
          photo3: this?.selectedPart?.markAsReturned?.photos?.[2]?.id ? this.selectedPart.markAsReturned.photos[2].id : null, //integer
        },
      }

      Axios.post(`/ir/repairer/credit/${this.$route.params.creditId}/part/${this.selectedPart.id}/edit`, jsonData)
          .then((r) => {
            if (r?.data?._status) {
              let ind = _.findIndex(this.credit.parts, (prt) => {
                return Number(prt.id) === Number(this.selectedPart.id)
              })
              if (ind !== -1) {
                this.$set(this.credit.parts[ind], 'markAsReturned', r.data.markAsReturned)
                this.$set(this.credit.parts[ind], 'isMarkAsReturned', r.data.isMarkAsReturned)
              }
            }
          })
      console.log(jsonData)
      this.$refs.markAsReturned.hide()
    },
    addFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    completeFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successFileUpload (file, response) {
      this.isNeedRemoveImg = true
      if (response.status || response._status) {
        if (this.selectedPart.markAsReturned.photos.length === 3) {
          console.log(this.$refs.imgUpDropPinInage,)
          this.isNeedRemoveImg = false
          this.$refs.imgUpDropPinInage.removeFile(file)
          this.$toast.error('The limit is three pictures')
        } else {
          this.selectedPart.markAsReturned.photos.push({
            dataUrl: file.dataURL,
            id: response.fileId
          })
          console.log(this.selectedPart.markAsReturned.photos, 'photos added')
        }
      }
    },
    removedFile (file) {
      if (!this.isNeedRemoveImg) {
        this.isNeedRemoveImg = true
      } else {
        let removeIndex = _.find(this.selectedPart.markAsReturned.photos, (p) => {
          return p.dataUrl === file.dataUrl
        })
        if (removeIndex !== -1) {
          this.selectedPart.markAsReturned.photos.splice(removeIndex, 1)
        }
      }
    },
    sendFileUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    clickCancelDropzoneFiles: function () {
      this.$refs.fileUpDrop.removeAllFiles(true)
      this.fileIds = []
    },
    clearSignature () {
      let signaturePad = this.$refs.signaturePad
      if (signaturePad) {
        signaturePad.clear()
      }
    },
    contextClose () {
      this.isShowReasonForCancellation = false
      this.clearSelectedRows()
    },
    formatDateTime (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY || hh:mmA') : ''
    },
    formatDate (val) {
      return (val) ? dayjs(val).format('DD/MM/YYYY') : ''
    },
    changedCreditComment (data) {
      data = data[0]
      Axios.post(`/ir/repairer/credit/${this.$route.params.creditId}/part/${data.creditId}/edit`, { comment: data.reason, })
          .then(response => {
            if (response.data._status) {
              if (response.data.creditComment) {
                let part = _.find(this.credit.parts, (p) => {
                  return Number(p.id) === Number(data.creditId);
                })
                if (part && part.repairerSide) {
                  part.repairerSide.creditReason = response.data.creditComment
                }
              }
              this.$toast.success('The Reason for Credit has been saved')
            }
          })
    },
    editCredit2 (row, event) {
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      this.toggleDetails(row)
      setTimeout(() => {
        this.editCredit(row)
      }, 200)
    },
    editCredit (row, event) {
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) === Number(row.id)
      })
      if (index !== -1) {
        this.$set(this.credit.parts[index], 'isEditCredit', true)
      }
      this.$refs.menuCredit.close()
      let component = this.$refs['credit-details-' + row.id]
      if (component) {
        setTimeout(() => {
          component.focusInReasonForCredit()
        }, 200)
      }
    },
    saveCancelCredit (row, event) {
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      let data = {
        // cancelCreditReason: row.cancelCreditReasonDraft,
        // partsIds: [row.id]
      }
      if (this.selectedRow.length > 1) {
        data = {
          cancelCreditReason: this.cancelCreditReasonDraft,
          partsIds: []
        }
        _.forEach(this.selectedRow, (r) => {
          data.partsIds.push(r.id)
        })
      } else if (this.selectedRow.length == 1) {
        data =  {
          cancelCreditReason: row.cancelCreditReasonDraft,
          partsIds: [row.id]
        }
      }
      console.log('saveCancelCredit', row)
      Axios.post(`/ir/repairer/credit/${this.$route.params.creditId}/cancel-parts`, data)
          .then(response => {
            if (response.data._status) {
              if (this.selectedRow.length > 1) {
                _.forEach(this.selectedRow, (r) => {
                  let prtIndex = _.findIndex(this.credit.parts, (p) => {
                    return Number(p.id) === Number(r.id)
                  })
                  if (prtIndex !== -1) {
                    this.credit.parts[prtIndex].cancelCreditReason = this.cancelCreditReasonDraft
                    this.credit.parts[prtIndex].status = 'Cancelled'
                  }
                })
                this.cancelCreditReasonDraft = null
              } else {
                row.cancelCreditReason = row.cancelCreditReasonDraft
                row.status = 'Cancelled'
              }
              this.credit.status = response.data.creditStatus
              this.$toast.success('The credit for this part has been canceled')
            }
          })
      this.$refs.menuCredit.close()
    },
    undoCancelCredit (row, event) {
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      let data = {
        partsIds: []
      }
      _.forEach(this.selectedRow, (r) => {
        data.partsIds.push(r.id)
      })
      console.log('Undo Cancel', row)

      Axios.post(`/ir/repairer/credit/${this.$route.params.creditId}/undo-cancel-parts`, data)
          .then(response => {
            if (response.data._status) {
              _.forEach(this.selectedRow, (r) => {
                let prtIndex = _.findIndex(this.credit.parts, (p) => {
                  return Number(p.id) === Number(r.id)
                })
                if (prtIndex !== -1) {
                  this.credit.parts[prtIndex].cancelCreditReason = ''
                  this.credit.parts[prtIndex].status = 'UnderReview'
                }
              })
              // row.cancelCreditReason = ''
              // row.status = 'UnderReview'
              this.credit.status = response.data.creditStatus
              this.$toast.success('The cancelation of credit for this part has been removed')
            }
          })
      this.$refs.menuCredit.close()
    },
    disableDefaultRightClickOn (elSelector) {
      // const noContext = document.querySelector(elSelector)
      // noContext.addEventListener('contextmenu', e => {
      //   e.preventDefault()
      // })
    },
    openMenuForReasonAction (data) {
      if (data.part.status !== 'UnderReview') {
        return
      }
      this.isOpenPartContext = true
      let partContext = this.$refs.menuCredit
      if (partContext && partContext.show) {
        partContext.close()
      }

      if (!(data.part.comment !== '' && data.isNeedSave)
          && !(data.part.comment !== '' && data.part.comment !== null && !data.isNotNeedEdit)
          && !(data.part.comment !== '' && data.part.comment !== null)) {
        setTimeout(() => {
          this.isOpenPartContext = false
        }, 100)
        return
      }

      this.isOpenPartContext = true

      this.$nextTick(() => {
        this.$refs.menuActionForReason.open(data.event, {
          part: data.part,
          type: data.type,
          isNotSavedChanges: data.isNotSavedChanges,
          isNeedSave: data.isNeedSave,
          isNotNeedEdit: data.isNotNeedEdit
        })
      })
    },
    closeContextForReasonAction () {
      this.$refs.menuActionForReason.close()
    },
    saveReason (part) {
      let el = this.$refs['credit-details-' + part.id]
      if (el) {
        el.saveReason()
      }
    },
    editReason (part) {

      let el = this.$refs['credit-details-' + part.id]

      let index = _.findIndex(this.credit.parts, (prt) => {
        return Number(prt.id) === Number(part.id)
      })
      if (index !== -1) {
        this.$set(this.credit.parts[index], 'isEditCredit', true)
      }

      if (el) {
        el.editReason()
      }
    },
    deleteReason (part) {
      let el = this.$refs['credit-details-' + part.id]
      if (el) {
        el.deleteReason()
      }
    },
    dateFormat () {
      return dayjs(this.dateDue).format('DD/MM/YYYY - hh:mmA')
    },
    toggleDetails (row, event) {
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      this.$set(row, '_showDetails', true)
      this.$refs.menuCredit.close()
    },
    showModalByIcon (part) {
      this.selectedPart.markAsReturned = part.markAsReturned
      this.selectedPart.markAsReturned.rtnDate = dayjs(part.markAsReturned.returnedDateTime).format('DD/MM/YYYY')
      this.selectedPart.markAsReturned.rtnTime = dayjs(part.markAsReturned.returnedDateTime).format('hh:mmA')
      this.isModalDisable = true
      this.$refs.markAsReturned.show()
      this.isShowModal = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.setCanvasWidthAndHeight()
        }, 100)
      })
    },
    readReasonCancellation(part, event) {
      // console.log('readReasonCancellation - ' + this.isShowReasonForCancellation)
      // if (event) {
      //   event.preventDefault()
      //   this.isShowContext = true;
      //   this.isShowReasonForCancellation = true;
      // }
      // console.log('isShowContext - ' + this.isShowContext)
      // console.log('this.isShowReasonForCancellation - ' + this.isShowReasonForCancellation)
      event?.targetTouches?.[0]?.target.focus()
    },
    markAsReturnedModal (part, event) {
      this.$refs.menuCredit.close()
      if (event) {
        event.preventDefault()
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }

      this.selectedPart = part
      this.$set(this.selectedPart, 'markAsReturned', {
        rtnDate: dayjs(new Date()).format('DD/MM/YYYY'),
        rtnTime: dayjs(new Date()).format('hh:mmA'),
        signature: null,
        driverID: null,
        driverName: null,
        photos: [],
        photo1: null,
        photo2: null,
        photo3: null,
        comment: null,
        returnedBy: ''
      })

      this.isModalDisable = false
      this.$refs.markAsReturned.show()
      this.isShowModal = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.setCanvasWidthAndHeight()
        }, 100)
      })
    },

    viewCreditStatus (status) {
      if (status === 'Approved') {
        return 'Approved'
      } else if (status === 'UnderReview') {
        return 'Under Review'
      } else if (status === 'Denied') {
        return 'Denied'
      } else if (status === 'Cancelled') {
        return 'Request Cancelled'
      } else {
        return ''
      }
    },
    clickSave (data) {
      return
      // let id = this.order.id
      // Axios.post('/ir/repairer/order/' + id, data)
      //     .then(response => {
      //       if (response.data._status) {
      //         if (response.data.update && response.data.update.isPartStatusesChanged) {
      //           //    this.loadCredit(id)
      //
      //         }
      //         if (response.data.comments) {
      //           let parts = response.data.comments
      //           for (let partId in parts) {
      //             let orderPart = _.find(this.order.parts, (prt) => {
      //               return Number(prt.id) === Number(partId)
      //             })
      //             console.log(orderPart, 'orderPart', parts[partId])
      //             if (orderPart) {
      //               let newPart = parts[partId]
      //               orderPart.commentByName = newPart.commentByName
      //               orderPart.commentDate = newPart.commentDate
      //             }
      //           }
      //         }
      //         // process requestCredit
      //         //return this.saveChangedPartCredit(id)
      //       } else {
      //         this.$toast.error('Order changes have not been saved')
      //         // this.changedPartStatus = data.partStatus
      //       }
      //     })
      //     .then(() => {
      //       // this.loadCredit(id)
      //       this.$toast.success('Order changes have been saved')
      //     })
      //     .catch(error => {
      //       console.log(error)
      //       this.$toast.error('Order changes have not been saved')
      //     })
    },

    getNameClass: function (item) {
      let status = item.status
      let elem = _.find(this.statusList, { id: status })
      if (elem) {
        return elem.nameClass
      }
      return ''
    },
    getIconClass: function (item) {
      //console.log('item', item)
      let status = item.status
      //console.log('status', status)
      let elem = _.find(this.statusList, { id: status })
      // console.log('elem', elem)
      if (elem) {
        return elem.iconClass
      }
      return ''
    },
    loadCredit: function (id, callback = false) {
      Axios.get('/ir/repairer/credit/' + id,)
          .then(response => {
            if (callback) {
              callback(response.data.credit)
              return
            }
            this.credit = response.data.credit
            this.credit.parts.forEach(part => {
              this.$set(part, 'isChecked', false)
            })
            if(response.data.chatMessages){
              this.chatMessages = response.data.chatMessages
              if(this.unviewedMessagesIds.length > 0){
                this.markMessagesAsViewed(this.unviewedMessagesIds);
              }
            }
            this.openAllSelectedParts()
          })
          .catch(error => {
            this.$toast.error('Page not found')
            //this.$router.push('/r/credits')
            console.log(error)
          })
          .finally(() => {
            this.isLoadedPartOrder = true
          })
    },
    handleRightClick (row, index, e) {
      console.log('context menu open', this.isOpenPartContext)
      if (this.isOpenPartContext) {
        return
      }
      this.$nextTick(() => {
        if (!row.isChecked) {
          this.$set(this.credit.parts[index], 'isChecked', true)
          this.callComputed++

          let isSelectedPart = _.find(this.selectedRow, (r) => {
            return Number(r.id) == Number(row.id)
          })
          if (!isSelectedPart) {
            this.selectedRow.push(row)
          }
        }
      })
      e.preventDefault()
      let part = row
      if (row.item && row.item.id) {
        part = row.item
      }

      // this.clearSelectedRows()
      // this.selectedRowIndex = index
      // this.$set(this.credit.parts[index], 'isSelected', true)

      this.$refs.menuCredit.open(e, { row: part, index: index })
      // when right click selected, only a single row can be selected at one time
      // this.$refs.creditPartsTable.clearSelected()
      // this.$refs.creditPartsTable.selectRow(index)
    },
    rowClass (item, type) {
      if (item && type === 'row') {
        if (item.isSelected || item.isChecked) {
          return 'row-selected'
        }
      }
    },
    clearSelectedRows () {
      if (this.selectedRowIndex !== null) {
        const table = this.$refs.creditPartsTable
        this.$nextTick(() => {
          table.unselectRow(this.selectedRowIndex)
        })
        this.$set(this.credit.parts[this.selectedRowIndex], 'isSelected', false)
      } else {
        const table = this.$refs.creditPartsTable
        _.forEach(this.credit.parts, (prt, ind) => {
          this.$nextTick(() => {
            table.unselectRow(ind)
          })
          this.$set(this.credit.parts[ind], 'isSelected', false)
        })
      }
    },
    handleRequestCreditButton (data, event) {
      return
      let partId = null
      if (data.row.id) {
        partId = data.row.id
      } else if (data.row.item.id) {
        partId = data.row.item.id
      }
      Axios.post('/ir/repairer/credit/add', { orderId: this.order.id, orderPartId: data.row.id })
          .then(response => {
            if (response.data._status) {
              if (response.data.credit) {
                let partIndex = _.findIndex(this.order.parts, prt => {
                  return Number(prt.id) === Number(data.row.id)
                })
                console.log(partIndex, 'index')
                if (partIndex !== -1) {
                  this.order.parts[partIndex].credit = response.data.credit
                }
              }
            } else {
              // this.$toast.error('Request Credit have not been saved')
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('Request Credit have not been saved')
          })
    },

    partCanRequestCredit (part) {
      return part.status === 'CancelPart'
          || part.status === 'ReturnDamagedPart'
          || part.status === 'IncorrectPartOrdered'
          || part.status === 'IncorrectPartDelivered'
          || part.status === 'DamagedPartExWarehouse'
          || part.status === 'NoLongerNeeded'
          || part.status === 'ChangeInRepairMethod'
    },
    handleIncorrectPartOrderedButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          _save.push({ partId: el.id, status: 'IncorrectPartOrdered' })
          el.status = 'IncorrectPartOrdered'
          el._showDetails = true
        })
        this.selectedRow = []
        this.$refs.creditPartsTable.clearSelected()
      } else {
        data.row.status = 'IncorrectPartOrdered'
        data.row._showDetails = true
        _save.push({ partId: data.row.id, status: 'IncorrectPartOrdered' })
      }
      this.clickSave(_save)
    },
    handleIncorrectPartButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          _save.push({ partId: el.id, status: 'IncorrectPartDelivered' })
          el.status = 'IncorrectPartDelivered'
          el._showDetails = true
        })
        this.selectedRow = []
        this.$refs.creditPartsTable.clearSelected()
      } else {
        data.row._showDetails = true
        data.row.status = 'IncorrectPartDelivered'
        _save.push({ partId: data.row.id, status: 'IncorrectPartDelivered' })
      }
      this.clickSave(_save)
    },
    savePartReceivedButton () {
      this.handlePartReceivedButton()
    },
    handlePartReceivedButton (data, event) {
      let _save = []
      if (this.selectedRow.length > 1) {
        let self = this
        this.selectedRow.forEach(function (el) {
          if (el._showDetails) {
            // el._showDetails = false
          }
          _save.push({ partId: el.id, status: 'Received' })
          el.status = 'Received'
          el.comment = null
          el.commentByName = null
          el.commentDate = null
          el.qtyRcv = el.qty
        })
        this.selectedRow = []
        this.$refs.creditPartsTable.clearSelected()
      } else {
        console.log('data', data, data.row.detailsShowing)
        if (data.row._showDetails) {
          // data.row._showDetails = false
        }
        data.row.status = 'Received'
        data.row.comment = null
        data.row.commentByName = null
        data.row.commentDate = null
        data.row.qtyRcv = data.row.qty
        _save.push({ partId: data.row.id, status: 'Received' })
      }
      this.clickSave(_save)
    },
    touchStart (event, item) {
      event.preventDefault()
      console.log('START TOUCH')
      document.body.style.WebkitUserSelect = "none"

      try {
        if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
          this.isShowContext = false
        }
      } catch (err) {
        this.isShowContext = false
      }

      this.touchStartTime = new Date()
      this.topForLoader = event.touches[0].pageY
      if (event.touches[0].clientY + 50 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 160
      } else if (event.touches[0].clientY + 100 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 120
      } else if (event.touches[0].clientY + 150 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 70
      } else {
        this.topForTouchMenu = event.touches[0].pageY
      }
      this.leftForLoader = event.touches[0].pageX

      if (event.touches[0].pageX + 385 > window.screen.width && window.screen.width > 385) {
        this.leftForTouchMenu = window.screen.width - 385
      } else if (window.screen.width < 385) {
        this.leftForTouchMenu = 0
      } else {
        this.leftForTouchMenu = event.touches[0].pageX
      }
      console.log('start', event, item)
      this.item = item
      this.isShowLoader = true
    },
    touchEnd (event) {
      event.preventDefault()
      console.log('end', event)
      this.isShowLoader = false
      let touchEndTime = new Date()
      let touchDelay = (touchEndTime - this.touchStartTime) / 700
      if (touchDelay >= 0.7) {
        this.isShowContext = true
        console.log('this.isShowContext', this.isShowContext)
      }
      document.body.style.WebkitUserSelect = ""
    },
    touchClose (event) {
      if (event?.targetTouches?.[0]?.target?.parentElement?.parentElement?.id !== 'context-menu-iphone' && event?.targetTouches?.[0]?.target.id !== 'context-menu-iphone-level-2') {
        this.isShowContext = false;
        this.isShowReasonForCancellation = false;
      }
      console.log('touchClose - ', event)
    },
  },
  created: function () {
    (async () => {
      this._chat = await subscribeToCreditChannel(this.$route.params.creditId, {
        onPublication: this.onChatMessage,
        onSubscribed: () => {
          this.onlineStatusIntervalId = setInterval(() => {
            this._chat.publish({ type: 'repairerIsOnline' })
          }, 2000)
        },
        onUnsubscribed: () => {
          if(this.onlineStatusIntervalId !== null){
            clearInterval(this.onlineStatusIntervalId)
          }
        },
      })
    })()
  },
  mounted () {
    this.loadCredit(this.$route.params.creditId)
    this.disableDefaultRightClickOn('.credit-view-page .table')
    window.addEventListener('keydown', this.checkCtrlAndShift)
    window.addEventListener('keyup', this.removeCtrlAndShift)
    this.supplierLeavedTheChat = _.debounce(function () {
      this.chatSupplierIsOnline = false;
    }, 5000)
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.checkCtrlAndShift)
    window.removeEventListener('keyup', this.removeCtrlAndShift)
    if(this._chat){
      console.log('UNSUBSCRIBING!',this._chat);
      this._chat.unsubscribe();
      console.log('UNSUBSCRIBED!');
    }
    clearInterval(this.interval);
  },
  watch: {
    creditsFromUpdate: {
      handler (val) {
        let isNeedUpdate = !!_.find(val, (itm) => {
          return Number(itm.id) === Number(this.credit.id)
        })
        if (isNeedUpdate) {
          this.loadCredit(this.$route.params.creditId, (credit) => {
            console.log(this.credit, credit)
            if (!this.credit || !credit) {
              return
            }
            if (this?.credit?.status !== credit?.status) {
              this.credit.status = credit.status
            }
            if (this?.credit?.manualNumber !== credit?.manualNumber) {
              this.credit.manualNumber = credit.manualNumber
            }
            if (!_.isEqual(this?.credit?.images, credit?.images)) {
              this.credit.images = credit.images
            }

            if (!this.isInvoiceNumberInFocus  && !_.isEqual(this?.credit?.invoiceNumber, credit?.invoiceNumber)) {
              this.credit.invoiceNumber = credit.invoiceNumber
            }

            _.forEach(credit.parts, (p) => {
              let originalPart = _.find(this.credit.parts, (prt) => {
                return Number(prt.id) === Number(p.id)
              })
              if (originalPart) {
                if (!_.isEqual(p.isChecked, originalPart.isChecked)) {
                  p.isChecked = originalPart.isChecked;
                }
              }
            })

            this.credit.parts = credit.parts

            let selectedRowCopy = _.cloneDeep(this.selectedRow)
            _.forEach(this.credit.parts, (part, index) => {
              if (part.isChecked) {
                let selectedPartIndex = _.findIndex(this.selectedRow, (r) => {
                  return Number(r.id) == Number(part.id)
                })
                if (selectedPartIndex == -1) {
                  selectedRowCopy.push(part)
                } else {
                  selectedRowCopy[selectedPartIndex] = part
                }
              }
            })
            this.selectedRow.splice(0, this.selectedRow.length)
            _.forEach(selectedRowCopy, (p) => {
              this.selectedRow.push(p)
            })

            this.openAllSelectedParts()
          })
        }
        console.log('watch.getIsNeedUpdate', isNeedUpdate)
      },
      immediate: true,
    }
  },
  components: {
    VueContext,
    CreditDetails,
    FileUpload,
    dayjs,
    DatePicker,
    Multiselect,
    vueDropzone,
    Chat,
    SearchFilter,
    VPerfectSignature,
    isMobile,
  },
}
</script>

<style lang="scss" scoped>
.disabled-button {
  opacity: 0.7 !important;
}

.disabled-button:hover {
  background-color: #bdbdbd4d;
}
.credit-view {
  &__row {
    &--xl {
      width: 20%;
      min-width: 300px;
    }

    &--lg {
      width: 10%;
      min-width: 150px;
    }

    &--md {
      width: 7%;
      min-width: 150px;
    }

    &--sm {
      width: 5%;
      min-width: 110px;
    }

    &--xs {
      width: 3%;
      min-width: 80px;
    }
  }

  &__context-menu {
    .btn {
      border: none;
      padding: 15px 15px 11px 15px;
      transition-duration: 150ms;
    }

    .theme-repairer & .btn:hover {
      color: #070707;
    }

    .theme-supplier & .btn:hover {
      color: #29BBC1;
    }
  }
}

.credit-view-page {
  // right click menu container
  .v-context {
    width: auto !important;
    border: none;
    box-shadow: rgba(27, 30, 56, 0.15);
  }

  .table.b-table > tbody > .table-active,
  .table.b-table > tbody > .table-active > th,
  .table.b-table > tbody > .table-active > td {
    background-color: rgba(46, 169, 255, 0.1);
  }

  .table.b-table > thead > tr > [aria-sort="none"],
  .table.b-table > thead > tr > [aria-sort="descending"],
  .table.b-table > thead > tr > [aria-sort="ascending"],
  .table [role='columnheader'][aria-label='Status Id'] {
    padding: 15px 15px 17px 10px !important;
    background-color: #EFEFF1 !important;
    top: -1px;
    border: 0;
    box-sizing: border-box;
  }

  .b-table-sticky-header {
    max-height: 60vh;
    border-bottom: 1px solid rgb(175, 177, 179);
  }

  .dropdown-item {
    padding: 13px 4px 15px 15px !important;
  }

  // custom dot on buildings icon
  .bx-buildings {
    color: #1B1E38;
    position: relative;

    &:after {
      content: '';
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xMC4wMDEgMTYuNDEzTDYuMjg4IDEyLjcwOEw3LjcgMTEuMjkyTDkuOTk5IDEzLjU4N0wxNS4yOTMgOC4yOTNMMTYuNzA3IDkuNzA3TDEwLjAwMSAxNi40MTNaIiBmaWxsPSIjMkRDQTczIi8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      background-size: contain;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: white;
      border-radius: 50%;
      top: 0;
      right: 0;
    }

    &--no-dot:after {
      display: none;
    }
  }

  @media screen and (max-width: 991px) {
    .b-table-sticky-header {
      max-height: 50vh;
    }
  }

  /* overwrite */
  [role='cell'] .text-primary,
  .bx.text-primary {
    color: #63BCFF !important
  }

  // table modifications
  // to make border works on sticky column (firefox)
  .table {
    border-collapse: separate;
    border-spacing: 0;
  }

  // to fix blue color on selected row,
  // when scrolled horizontally, so it is not transparent
  .b-table-row-selected.table-active .b-table-sticky-column {
    background-color: #D6EEFF !important;
  }

  .b-table-row-selected.table-active + .b-table-details {
    background-color: #D6EEFF !important;
  }

  // row border
  .table.b-table td {
    border-color: rgba(27, 30, 56, 0.1);
    vertical-align: top;
  }

  // dont use different shade on freeze column
  .table.b-table.table-hover > tbody > tr:hover:not(.b-table-row-selected) > .table-b-table-default {
    background: inherit
  }

  // horizontal scrollbar
  .table-responsive {
    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b8c0;
    }

    scrollbar-width: thin;
    scrollbar-color: #b7b8c0;
  }
}

.credit-title {
  margin: 15px 0 12px 0;

  &__item {
    min-width: 360px;
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.ps-label-value__label {
  display: inline-block;
  width: 118px;
  white-space: nowrap;
  color: #a0a1a7;
  font-weight: 700;
}

.ps-label-value__value {
  font-weight: 700;
}

.credit-footer {
  //padding-left: 20%;
  &__whitespace {
    display: inline-block;
    width: 19%;
    min-width: 230px;
  }

  &__eta-label,
  &__eta-date {
    opacity: 0.5;
  }

  &__eta-date {
    margin-left: 50px;
  }

  @media screen and (max-width: 991px) {
    &__whitespace {
      width: 20%;
      min-width: 155px;
    }
  }
}

/* table column width */
.credit-th {
  &--part-list {
    min-width: 200px;
    width: 15%;
  }

  @media screen and (max-width: 991px) {
    &--part-list {
      min-width: 130px;
    }
  }
}

/* show details */
.credit-view-page tr.show-detail.b-table-row-selected {
  background-color: white !important;

  td,
  td.b-table-sticky-column {
    background-color: white !important;
  }
}

@media (max-width: 1245px) {
  .credit-view-page .credit-additional-block {
    display: block !important;
  }
}

@media (max-width: 764px) {
  .credit-view-page .credit-additional-block:not(:first-child) {
    display: none !important;
  }

  .credit-view-page .credit-additional-block .credit-title {
    max-width: 100%;
  }
}

.credit-view-page .credit-view-body .tabs-component {
  border-bottom: 10px solid #f1f1f7;
}
</style>

<style scoped>
.dropdown-actions >>> button span{
  border-color: #7bbec0 !important;
}
.dropdown-actions >>> button{
  background-color: #7bbec0 !important;
  color: #fdfdfd !important;
}
.dropdown-actions >>> button:hover{
  background-color: #afd7d8 !important;
  color: #3f3f3f !important;
}
.credit-order-numbers {
  padding: 15px;
}

.credit-order-numbers .credit-order-numbers-item:last-child {
  margin-left: 35px;
}

.credit-order-numbers input {
  max-width: 150px;
}

.credit-order-numbers .credit-order-numbers-title {
  min-width: 72px;
  font-weight: 700;
}

@media (max-width: 450px) {
  .credit-order-numbers {
    flex-direction: column;
  }

  .credit-order-numbers .credit-order-numbers-item:last-child {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.credit-cancel-save-icon {
  font-size: 24px;
  position: absolute;
  left: 348px;
  top: 110px;
}

.credit-cancel-save-icon-2 {
  top: 68px;
}

.credit-cancel-reason {
  height: 100px;
  width: 200px;
  position: absolute;
  left: 204px;
  top: 80px;
  resize: none;
}
.credit-cancel-reason-position-left{
  left: 210px;
}

.credit-cancel-reason-only {
  top: -2px;
}

.raised-credit-request-sub-menu {
  right: -121px;
  top: 84px;
}

.part-sub-context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.autosave {
  font-size: 13px;
  margin-left: 100px;
}

.credit-view-body >>> td,
.credit-view-body >>> th {
  width: auto;
}

.credit-view-body >>> .column-part-name {
  width: auto;
}

.credit-view-body >>> .column-qty-order {
  max-width: 66px;
  width: 66px;
}

.credit-view-body >>> .column-qty-rcvd {
  max-width: 66px;
  width: 66px;
}

.credit-view-body >>> .column-credit {
  max-width: 80px;
  width: 80px;
}


.options-mobile .bx-down-arrow {
  padding-top: 10px;
  padding-left: 5px;
}

.options-mobile.dropdown-options.show .dropdown-arrow .bx-down-arrow {
  transform: rotate(180deg) translateX(-5px) translateY(-10px) !important;
}
</style>

<style>
.V3 .theme-repairer .credit-view-page .part-name-sticky {
  position: sticky !important;
  left: 0px;
  z-index: 999;
  background-color: #e3e4e7 !important;
}

.theme-repairer .credit-view-page #credit-view-options-mobile.large-options .dropdown-menu {
  left: -100px !important;
  width: 220px;
  transform: translate3d(-29px, 39px, 0px) !important;
}

.theme-repairer .credit-view-page #credit-view-options-desktop.large-options .dropdown-menu {
  left: -41px !important;
  width: 220px;
  transform: translate3d(-29px, 39px, 0px) !important;
}
.credit-view-page .table.b-table .b-table-row-selected.table-active {
  background-color: #e5f6f5 !important;
}

.credit-view-page .table.b-table .b-table-row-selected.table-active + .b-table-details {
  background-color: #e5f6f5 !important;
}
</style>

<style>
.theme-repairer .credit-view-page .b-table-row-selected.table-active .table-name-border-bottom {
  background-color: #a6b2b9;
}

.theme-repairer .credit-view-page .table.b-table > tbody > tr.row-selected > .table-b-table-default {
  background-color: rgb(233, 248, 249) !important;
}

.theme-repairer .credit-view-page .table.b-table > tbody > tr.row-selected > .table-b-table-default:hover {
  background-color: rgb(233, 248, 249) !important;
}

@supports (position: sticky) {

  .theme-repairer .credit-view-page .table.b-table .b-table-row-selected.table-active .b-table-sticky-column {
    background-color: #e9f8f9 !important;
  }
  .theme-repairer .credit-view-page .table.b-table.table-hover > tbody > tr.table-active:hover td.b-table-sticky-column  {
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075)) !important;
    background-repeat: no-repeat;
  }
}

.modal-mark-as-returned .mark-as-returned-add-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 100px;
  cursor: pointer;
}

.modal-mark-as-returned .mark-as-returned-add-photo i {
  font-size: 40px;
  margin-bottom: 0 !important;
}

.modal-custom-mark-as-returned .modal-title {
  color: #fff;
  margin-left: 15px;
}

.modal-custom-mark-as-returned {
  max-width: 500px !important;
  margin: 15rem auto !important;
}

.mark-as-returned-text-message {
  height: 100px !important;
}

.modal-custom-mark-as-returned .modal-body {
  padding-bottom: 0;
}

.modal-custom-mark-as-returned .modal-footer {
  flex-wrap: nowrap !important;
  padding-top: 0;
}

.modal-custom-mark-as-returned .modal-header {
  background-color: #79B1BB;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-custom-mark-as-returned .modal-content {
  border-radius: 3px !important;
}

.modal-custom-mark-as-returned .input-btn {
  font-size: 1rem !important;
  height: auto !important;
  padding: 0.75rem 1rem !important;
  width: auto !important;
  background-color: #79B1BB !important;
  border-color: #79B1BB !important;
}

.modal-custom-mark-as-returned .cancel {
  background-color: #fff !important;
  border-color: rgba(28, 31, 57, .25) !important;
}

.modal-custom-mark-as-returned .input-btn:focus {
  box-shadow: none !important;
}

.mark-as-returned-signature {
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.mark-as-returned-signature:hover + .control-elem-1__item {
  opacity: 1;
}

.modal-custom-mark-as-returned .mark-as-returned-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}

.mark-as-returned-signature-canvas:hover + .control-elem-1__item,
.mark-as-returned-signature-block:hover + .control-elem-1__item {
  opacity: 1;
}

.modal-custom-mark-as-returned .dropzone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .modal-custom-mark-as-returned {
    margin: 8rem auto !important;
  }
  .credit-view-page .context-menu-iphone-level-2 .credit-cancel-save-icon {
    left: 330px;
  }
  .theme-repairer .credit-view-page ul.dropdown-menu {
    transform: translate3d(-58px, 39px, 0px) !important;
  }
}

@media screen and (max-width: 385px) {
  .credit-view-page .context-menu-iphone-level-2 .credit-cancel-save-icon {
    left: 165px;
  }
}

.modal-custom-mark-as-returned .control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 34px;
  right: 20px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}

.modal-custom-mark-as-returned .control-elem-1__item:hover {
  opacity: 1;
}

.modal-custom-mark-as-returned .control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.theme-repairer .credit-view-page .bx-reset {
  padding-right: 15px;
  font-size: 20px;
  color: #2e6ca5;
}

.modal-custom-mark-as-returned .dropzone .dz-preview, {
  width: 120px;
  height: 120px;
}

.modal-custom-mark-as-returned .mark-as-returned-photo-img {
  width: 120px;
  height: 120px;
  margin: 0 10px 0 10px;
}

.modal-custom-mark-as-returned .mark-as-returned-signature-photo-img-block {
  height: 148px;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-custom-mark-as-returned .dropify-wrapper .dz-message,
.modal-custom-mark-as-returned .dropify-message {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
}

.modal-custom-mark-as-returned .dropify-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-custom-mark-as-returned .dropzone .dz-preview .dz-remove {
  margin-left: 7px;
  bottom: 7px;
}

.modal-custom-mark-as-returned .dropzone .dz-preview .dz-details {
  padding: 1em 1em;
}

.modal-custom-mark-as-returned .dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 0.5rem
}

.modal-custom-mark-as-returned .dropzone .dz-preview .dz-image img {
  /*width: 100%;*/
  width: 116px;
  height: 116px;
}

.margin-bottom-60-table table{
  margin-bottom: 60px !important;
}

.credit-view-page .credit-part-name-transparent {
  background-color: transparent !important;
}
</style>

<style>
.credit-management__context-menu button:hover {
  background-color: rgba(46,169,255,0.1) !important;
}

.areaTouch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#context-loader {
  position: absolute;
  z-index: 9999;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

#context-loader svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

#context-loader svg .context-loader-background {
  fill: none;
  stroke: #e2eff0;
  stroke-width: 1.8;
}

#context-loader .context-loader-progress {
  fill: none;
  stroke: #1b1e3812;
  stroke: #5e79ff;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: 1.8;
  transition: all 5s ease-in-out;
}

.context-loader-progress.active {
  animation: dashOffset 1s forwards;
}

#context-menu-iphone {
  position: absolute;
  background: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  border-radius: 3px;
  z-index: 100;
}

#context-menu-iphone li {
  display: flex;
  text-decoration: none;
  text-align: left;
  padding: 15px 15px 11px 15px;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

#context-menu-iphone li:hover {
  background-color: rgba(46,169,255,0.1) !important;
  color: #79B1BB;
  font-weight: bold;
}

#context-menu-iphone li .textarea-left {
  left: 0;
}
</style>
