<template>
  <tabs ref="credits-tabs" cache-lifetime="0">
    <tab name="Policies" id="credits-policies" ref="credits-policies" class-name="tab-content-table">
      <div class="col-md-6 settings-credit-policies-page">
        <div class="group-title">
          Credit Processing Policy
        </div>
        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Enable Credit Processing Policy</span> <br/>
            <p class="notifications-info-text">Create and apply a policy in line with your business Credit processing Terms and Conditions.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 143px;">
              <b-form-checkbox
                  v-model="settings.credits.policies.enableCreditProcessingPolicy"
                  @change="changedBrand"
                  name="check-button"
                  switch
                  class="switch-checkbox"
              ></b-form-checkbox>
              <div class="status-text">
                <span v-if="settings.credits.policies.enableCreditProcessingPolicy" class="form-text">Active</span>
                <span v-else class="form-text">Not Active</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-7">
            <span class="notifications-info-title">Apply to All Customers</span> <br/>
            <p class="notifications-info-text">Selecting <strong>Yes</strong> applies to all customers across all RFQ's (Default). Selecting <strong>No</strong>
              allows you set this by customer in the customers settings page.</p>
          </label>
          <div class="d-flex justify-content-end col-12 col-lg-5 apply-to-all-customers-checkboxes" style="place-items: center; padding-bottom: 20px; padding-right: 15px;">
            <div class="text-right row justify-content-end" style="padding-right: 15px;">
              <label style="cursor: pointer" class="form-check-inline radio">
                <input class="form-check-input" type="radio"
                       name="remember"
                       :value="true"
                       @change="changedBrand"
                       v-model="settings.credits.policies.applyAllCustomers"
                       aria-label="Same as registered business address"
                >
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text received-quote">Yes</span>
              </label>
            </div>
            <div class="text-right row justify-content-end">
              <label style="cursor: pointer" class="form-check-inline radio">
                <input class="form-check-input" type="radio"
                       name="remember"
                       :value="false"
                       @change="changedBrand"
                       v-model="settings.credits.policies.applyAllCustomers"
                       aria-label="Same as registered business address"
                >
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text received-quote">No</span>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Reminder - Require Photos with Credit Request</span> <br/>
            <p class="notifications-info-text">Enable this to remind customers that photos are required with every Credit request for processing.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 143px;">
              <b-form-checkbox
                  name="check-button"
                  switch
                  @change="changedBrand"
                  :class="{
                    'disabled-switch-checkbox': !settings.credits.policies.applyAllCustomers
                   }"
                  :disabled="!settings.credits.policies.applyAllCustomers"
                  v-model="computedRequirePhoto"
                  class="switch-checkbox"
              ></b-form-checkbox>
              <div class="status-text">
                <span v-if="computedRequirePhoto" class="form-text">Active</span>
                <span v-else class="form-text">Not Active</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Reasons for Return to Display Photo</span> <br/>
            <p class="notifications-info-text">Photos requests can be automatically displayed to the customer if they choose a reason for return. Select the Reason category to have your Photo requirement displayed.</p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px; width: 140px">
            <div class="d-flex justify-content-between" style="width: 140px;">
              <multiselect
                  class="settings-rfq-response-time-multiselect credit-make-multiselect credit-multiselect-height settings-credit-reason-for-return-multiselect"
                  :showLabels="false"
                  :option-height="29"
                  style="min-width: 140px"
                  :options="['Incorrect Parts Delivered', 'Incorrect Parts Ordered',  'Damaged Parts In Transport', 'Damaged Aprts Ex Warehouse', 'No Longer Needed', 'Change In Repair Method']"
                  v-model="computedReasonForReturnToDisplayPhoto"
                  :disabled="!settings.credits.policies.applyAllCustomers"
                  @change="changedBrand"
                  :close-on-select="true"
                  :max-height="250"
                  :multiple="true"
                  placeholder="None"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label col-lg-6">
            <span class="notifications-info-title">Credit Return Period</span> <br/>
            <p class="notifications-info-text">Set the number of days that a customer has to return a part for a Credit.</p>
            <p class="notifications-info-text">
              If an attempt to request a credit is outside of nbr of days then they will be notified as per your notice below.
            </p>
          </label>
          <div class="col-12 col-lg-6 notifications-form-checkbox" style="padding-top: 15px;">
            <div class="d-flex justify-content-between" style="width: 140px;">
              <b-form-input @change="changedBrand" v-model="computedCreditReturnPeriod" :disabled="!settings.credits.policies.applyAllCustomers" type="text" class="form-control"></b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-12 col-form-label">
            <span class="notifications-info-title">Credit Return Terms & Conditions</span> <br/>
            <p class="notifications-info-text">Add the credit return Terms and Conditions information you would like to appear when a customer creates a credit request outside of the return period.</p>
          </label>
          <div class="col-12 notifications-form-checkbox" style="padding-top: 15px; margin-bottom: 50px;">
            <b-textarea @change="changedBrand" v-model="computedCreditReturnPeriodNotice" :disabled="!settings.credits.policies.applyAllCustomers" style="height: 155px"></b-textarea>
          </div>
        </div>

      </div>
    </tab>
  </tabs>
</template>

<script>
import Axios from "axios";
import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "the-credit",
  components:{
    Multiselect,
  },
  data () {
    return {
      testReason: [],
      settings: {
        credits: {
          policies: {
            applyAllCustomers: false,
            creditReturnPeriod: null,
            creditReturnPeriodNotice: null,
            enableCreditProcessingPolicy: false,
            reasonForReturnToDisplayPhoto: false,
            requirePhotosEveryCreditRequest: false,
          }
        }
      }
    }
  },
  props: {
    propsSettings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyId: {
      type: [Number, String],
      default: () => {
        return null
      }
    },
  },
  computed: {
    ...mapGetters({
      getterSettings: 'settings/getSettings',
      isMultipleLocations: 'settings/isMultipleLocations',
    }),
    getSettings () {
      return this.isMultipleLocations ? _.cloneDeep(this.propsSettings) : _.cloneDeep(this.getterSettings)
    },
    computedRequirePhoto: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return false;
        } else {
          return this.settings.credits.policies.requirePhotosEveryCreditRequest
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.requirePhotosEveryCreditRequest = value;
        }
      }
    },
    computedCreditReturnPeriod: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return '';
        } else {
          return this.settings.credits.policies.creditReturnPeriod
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.creditReturnPeriod = value;
        }
      }
    },
    computedReasonForReturnToDisplayPhoto: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return [];
        } else {
          return this.settings.credits.policies.reasonForReturnToDisplayPhoto
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.reasonForReturnToDisplayPhoto = value;
        }
      }
    },
    computedCreditReturnPeriodNotice: {
      get () {
        if (!this.settings.credits.policies.applyAllCustomers) {
          return '';
        } else {
          return this.settings.credits.policies.creditReturnPeriodNotice
        }
      },
      set (value) {
        if (this.settings.credits.policies.applyAllCustomers) {
          this.settings.credits.policies.creditReturnPeriodNotice = value;
        }
      }
    },
  },
  methods: {
    changedBrand() {
      let result = _.cloneDeep(this.propsSettings)
      result.credits = this.settings.credits
      this.$emit('changedBrand', {value: result, isSettings: true, companyId: this.companyId})
    },
    updateCreditSettings() {
      Axios.post('/ir/company/update-settings/' + this.companyId, {
        settings: this.settings
      })
          .then(response => {
            if (response.data && response.data._status) {
              this.$toast.success("The Credits Settings have been updated");
              this.$store.dispatch('settings/loadSettings')
            } else {
              this.$toast.error("The Credits Settings haven't been updated");
            }
          })
          .catch(error => {
            this.$toast.error("The Credits Settings haven't been updated");
            console.log(error);
          });
    },
    loadSettings () {
      this.settings = {
        credits: {
          policies: {
            applyAllCustomers: this.getSettings?.credits?.policies?.applyAllCustomers ? this.getSettings.credits.policies.applyAllCustomers : false,
            creditReturnPeriod: this.getSettings?.credits?.policies?.creditReturnPeriod ? this.getSettings.credits.policies.creditReturnPeriod : null,
            creditReturnPeriodNotice: this.getSettings?.credits?.policies?.creditReturnPeriodNotice ? this.getSettings.credits.policies.creditReturnPeriodNotice : null,
            reasonForReturnToDisplayPhoto: this.getSettings?.credits?.policies?.reasonForReturnToDisplayPhoto ? this.getSettings.credits.policies.reasonForReturnToDisplayPhoto : [],
            enableCreditProcessingPolicy: this.getSettings?.credits?.policies?.enableCreditProcessingPolicy ? this.getSettings.credits.policies.enableCreditProcessingPolicy : false,
            requirePhotosEveryCreditRequest: this.getSettings?.credits?.policies?.requirePhotosEveryCreditRequest ? this.getSettings.credits.policies.requirePhotosEveryCreditRequest : false,
          }
        }
      }
    }
  },
  watch: {
    getterSettings: {
      handler() {
        if (!this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
    propsSettings: {
      handler() {
        if (this.isMultipleLocations) {
          this.loadSettings();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.loadSettings();
  },
}
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .apply-to-all-customers-checkboxes {
    justify-content: flex-start !important;
  }
}

.group-title {
  height: 48px;
  background-color: rgba(27, 30, 56, 0.12) !important;
  color: #1C1F39 !important;
  font-size: 13px !important;
  font-weight: bold;
  line-height: 18px;
  padding: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
}

.form-check-inline.radio {
  width: auto;
}
.settings-credit-reason-for-return-multiselect >>> .multiselect__content-wrapper{
  width: 190px;
  position: absolute;
  right: -25px;
}

</style>

<style>
.settings-credit-reason-for-return-multiselect .multiselect__tags {
  min-width: 140px;
  height: auto !important;
}
.settings-credit-policies-page .disabled-switch-checkbox .custom-control-label {
  cursor: default;
}
.settings-credit-policies-page .disabled-switch-checkbox .custom-control-label:hover {
  cursor: default;
}
</style>
