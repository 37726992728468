/* eslint-disable */
import {States} from '../../../store/states';

export default class Offer {
    constructor({comment, id, price, firstPrice = null, status, qty, number = '', rrp = 0, firstRrp=null, name = '',
                    gstTax = 10, originalPartId = 0, stock = 0, altPartName = '',
                    stockComment = '', linkId = null, type = '',
                    deliveryName = '', deliveryZone = '', listOrderNumber, oePartNumber = null,
                    optionalParentPartId= null,optionalLink=null, purchasePriceFigures = null,  isManualByOemSupplier = false, nonReturnable = false,
                    groupSelectId = null,
                    rebate = null,
                }) {
        this.id = Number(id);
        this.price = Number(price);
        this.firstPrice = (firstPrice) ? Number(firstPrice) : null
        this.status = status;
        this.qty = qty ? Number(qty) : 1;
        this.comment = comment;
        this.oePartNumber = oePartNumber;
        this.rebate = rebate;
        this.name = name;
        this.nonReturnable = nonReturnable;
        this.partNumber = (number)?number.toUpperCase():'';
        this.rrp = rrp;
        this.firstRrp = (firstRrp) ?? null
        this.gstTax = gstTax;
        this.stock = Number(stock);
        this.linkId = Number(linkId);
        this.stockComment = stockComment;
        this.purchasePriceFigures = purchasePriceFigures
        this.alternativePartName = altPartName;
        this.isManualByOemSupplier = isManualByOemSupplier;
        this.originalPartId = Number(originalPartId); // || Number(partId);
        //this.isManual = isManual;  /moved to method
        this.listOrderNumber = listOrderNumber;
        this.type = type;
        if(type === 'delivery'){
            this.deliveryName = deliveryName;
            this.deliveryZone = deliveryZone;
        }
        if (type === 'optional'){
            this.optionalParentPartId=optionalParentPartId; //double of optionalLink
            this.optionalLink=optionalLink;
        }
        this.groupSelectId = groupSelectId;
    }

    /**
     * Get part number
     * @return {string|string}
     */
    get number(){
        return (this.partNumber)?this.partNumber.toUpperCase():''
    }

    /**
     * Get alt part name
     * @return {string|string}
     */
    get altPartName(){
        return (this.alternativePartName)?this.alternativePartName.toUpperCase():''
    }

    /**
     *  Is price set
     * @returns {boolean}
     */
    get priceCompleted() {
        return this.price >= 0;
    }

    /**
     * Percent a part in stock
     * @returns boolean
     */
    get isInStock() {
        return (this.stock === 1 || this.stock === 2 || this.stock === 6) ;
    }
    /**
     * Percent a part in stock
     * @returns boolean
     */
    get isNoStock() {
        return (this.stock === 3) ;
    }
    /**
     * Is type delivery
     * @return {boolean}
     */
    get isDelivery() {
        return this.type === 'delivery';
    }

    /**
     * Is type optional
     * @return {boolean}
     */
    get isOptional() {
        return this.type === 'optional';
    }

    /**
     * Is type manual
     * @return {boolean}
     */
    get isManual() {
        return this.type === 'manual';
    }

    /**
     * Is type Original Part from Repairer
     * @return {boolean}
     */
    get isOriginalPart() {
        return !this.isManual && !this.isOptional && !this.isDelivery;
    }

    /**
     * Return price with count
     * @return {number}
     */
    get priceWithCount() {
        return Number(this.price) * this.qty;
    }

    /**
     * Get total part price
     * @returns number
     */
    get total() {
        return this.priceWithCount + this.gst;
    }

    /**
     * Get gst sum
     * @return {number}
     */
    get gst() {
        return this.gstTax * (this.priceWithCount / 100);
    }

    /**
     * Rrp value for OEM
     * @returns string
     */
    rrpOEM() {
        if (this.rrp === -1){
            return '';
        }
        this.rrp = Number(this.rrp);
        return this.rrp.toFixed(2);
    }

    /**
     * Get selected option
     * @return {{label: string, value: number}}
     */
    get selectedOption() {
        let result = JSON.parse(JSON.stringify(States.stockOptions[0]));
        _.forEach(States.stockOptions, (option) => {
            if (option.value === this.stock && this.stock !== 0) result = JSON.parse(JSON.stringify(option));
        });
        result.comment = this.stockComment;
        return result;
    }

    /**
     * Check is part linked
     * @return {boolean}
     */
    get isLinked() {
        return Number(this.linkId) > 0;
    }

    /**
     * Set selected option
     * @param val
     */
    set selectedOption(val) {
        this.stock = val.value;
        if (!_.isEmpty(val.comment)) this.stockComment = val.comment;
    }

    /**
     *  Set part number
     * @param val
     */
    set number(val){
        this.partNumber = val;
    }

    /**
     *  Set alt part name
     * @param val
     */
    set altPartName(val){
        this.alternativePartName = val;
    }

    /**
     * flagged
     * when other than 'in stock' status selected
     */
    get flagged() {
        return  this.selectedOption.value !== 1 && this.selectedOption.value !== 4 && this.selectedOption.value !== 5 && this.selectedOption.value !== 6 && this.selectedOption.value !== 0; // NOT 'in stock' and Ex stock are flagged
    }

    /**
     * isReady
     *
     * determine if part can be ordered
     * it is only with status:
     * - 1 - 'in stock'
     * - 2 - 'low stock'
     * - 6 - 'Ex stock'
     * ref: states.js
     */
    get isReady() {
        return this.selectedOption.value === 1 || this.selectedOption.value === 6 || this.selectedOption.value === 2 || this.selectedOption.value === 4 || this.selectedOption.value === 0;
    }

    getSupplier() {

    }

    /**
     * isOfferSelected
     *
     * @param Supplier currentSupplier
     * @return boolean
     */
    isOfferSelected(currentSupplier) {
        let selectedParts = currentSupplier.selectedParts;
        let status = false;
        _.forEach(selectedParts, (id) => {
       //     console.log('Number(id)', Number(id));
       //     console.log('Number(this.id)', Number(this.id));
            if (Number(id) === Number(this.id)) {
                status = true;
                return status;
            }
        });
        return status;
    }

    /**
     * hasOptional - checked if this Part has Optional part
     *
     * @param Supplier currentSupplier
     * @return boolean
     */
    hasOptional(currentSupplier) {
        let offers = currentSupplier.offers;
        let status = false;
        _.forEach(offers, (offer) => {
            //if (this.id == "3918") {
            //    console.log('3918Number(originalPartId)', Number(offer.originalPartId));
            //    console.log('3918Number(this.optionalLink)', Number(this.optionalLink));
            //}
            if (Number(this.originalPartId) > 0 && Number(this.originalPartId) === Number(offer.optionalLink)) {
               // console.log('hasOptional', offer.id)
                status = true;
                return status;
            }
        });
        return status;
    }

    /**
     * isSelectedOfferOfPart - checked if original Part or Optional Part of original Part selected
     *
     * @param Supplier currentSupplier
     * @return boolean
     */
    isSelectedOfferOfPart(currentSupplier) {
        let offers = currentSupplier.offers;
        let status = false;
        _.forEach(offers, (offer) => {
            if (Number(this.id) !== Number(offer.id)) {  // не текущий оффер
               // console.log('not current offer', offer.id);
                if (this.isOptional) {
                    if (Number(this.optionalLink) > 0 && (Number(this.optionalLink) === Number(offer.originalPartId) || Number(this.optionalLink) === Number(offer.optionalLink))) {
                     //   console.log('checked Optional', offer.id);
                        if (offer.isOfferSelected(currentSupplier)) {
                           // console.log('Optional selected', offer.id);
                            status = true;
                            return status;
                        }
                    }
                } else {
                    if (Number(this.originalPartId) > 0 && (Number(this.originalPartId) === Number(offer.originalPartId) || Number(this.originalPartId) === Number(offer.optionalLink))) {
                       // console.log('checked Not optional', offer.id);
                        if (offer.isOfferSelected(currentSupplier)) {
                          //  console.log('not optional selected', offer.id);
                            status = true;
                            return status;
                        }
                    }
                }
            }
        });

        return status;
    }


}
