import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class Button extends Mark {
    get name() {
        return 'button'
    }

    get defaultOptions() {
        return {
            openOnClick: true,
        }
    }

    get schema() {
        return {
            attrs: {
                class: {
                    default: 'btn btn-primary'
                }
            },
            incluesive: false,
            parseDOM: [
                {
                    tag: 'button',
                    getAttrs: dom => ({
                        class: dom.getAttribute('class'),
                    })
                }
            ],
            toDOM: node => ['button', {
                ...node.attrs,
                class: 'btn btn-primary',
            }, 0]
        }
    }

    commands({ type }) {
        return () => toggleMark(type)
    }
}