<template>
  <div class="details-credits-page">
    <div class="page-header">
      <h4>Customer</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link to="/">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">View Customer</li>
        <li class="breadcrumb-item active">Customer Details</li>
      </ol>
      <!-- <div class="page-header-nav-btn">
        <router-link to="/customers/new" class="btn btn-primary">
          <i class="ti-plus"></i>New Customer
        </router-link>
      </div>-->
    </div>
    <div class="box box-block bg-white cards">
      <tabs>
        <tab name="Credits" id="details-credits" ref="creditsTab" :class-name="'tab-content-table'">
          <div class="row">
            <div class="col-md-12">
              <div class="box bg-white">
                <b-table
                  responsive
                  hover
                  :items="credits"
                  :fields="creditsFields"
                  :filter="creditFilter"
                  @filtered="onCreditFiltered"
                ></b-table>
              </div>
            </div>
          </div>
        </tab>

        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input
              v-model="creditFilter"
              type="text"
              class="form-control search-input"
              placeholder="Search here"
              debounce="500"
            ></b-form-input>
          </div>
          <div class="tabs-nav-right-btn">
            <router-link to="/credits/save" class="btn btn-primary">Save</router-link>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Axios from "axios";
import Paginate from "vuejs-paginate";
import { VueContext } from "vue-context";
import _ from "lodash";
// import Paginate from 'vuejs-paginate'
// import DataTables from './utility/data-tables'

export default {
  name: "CustomersDetailsCredits",
  data: function() {
    return {
      credits: [
        {
          id: 1111,
          creditNumber: "345TVT",
          orderNbr: "328HJAA",
          vin: "XXXXX92902903",
          estimator: "Winifred Webster",
          partsInterpreter: "Rosalie Edwards",
          partQty: 20,
          value: "$1,250.00",
          status: "Open"
        },
        {
          id: 222,
          creditNumber: "345TVT",
          orderNbr: "328HJAA",
          vin: "XXXXX92902903",
          estimator: "Winifred Webster",
          partsInterpreter: "Rosalie Edwards",
          partQty: 20,
          value: "$1,250.00",
          status: "Open"
        },
        {
          id: 333,
          creditNumber: "345TVT",
          orderNbr: "328HJAA",
          vin: "XXXXX92902903",
          estimator: "Winifred Webster",
          partsInterpreter: "Rosalie Edwards",
          partQty: 20,
          value: "$1,250.00",
          status: "Open"
        },
        {
          id: 4444,
          creditNumber: "345TVT",
          orderNbr: "328HJAA",
          vin: "XXXXX92902903",
          estimator: "Winifred Webster",
          partsInterpreter: "Rosalie Edwards",
          partQty: 20,
          value: "$1,250.00",
          status: "Open"
        }
      ],

      creditFilter: "",

      creditFiltered: [],

      creditsFields: [
        {
          label: "Credit Number",
          key: "creditNumber",
          sortable: true,
          tdClass: "clickable",
          thClass: "bg-navyblue"
        },
        {
          label: "Order Nbr",
          key: "orderNbr",
          sortable: true,
          tdClass: "clickable",
          thClass: "bg-navyblue"
        },
        {
          label: "VIN",
          key: "vin",
          sortable: true,
          tdClass: "clickable",
          thClass: "bg-navyblue"
        },
        {
          label: "Estimator",
          key: "estimator",
          sortable: true,
          tdClass: "text-center clickable",
          thClass: "text-center bg-navyblue"
        },
        {
          label: "Parts Interpreter",
          key: "partsInterpreter",
          sortable: true,
          tdClass: "text-center clickable",
          thClass: "text-center bg-navyblue"
        },
        {
          label: "Part Qty",
          key: "partQty",
          sortable: true,
          tdClass: "clickable",
          thClass: "bg-navyblue"
        },
        {
          label: "Value",
          key: "value",
          sortable: true,
          tdClass: "text-center clickable",
          thClass: "text-center bg-navyblue"
        },
        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: "text-center clickable",
          thClass: "text-center bg-navyblue"
        }
      ]
    };
  },
  methods: {
    onCreditFiltered: function(fi) {
      this.creditFiltered = fi;
    }
  },
  mounted: function() {
    this.$refs.creditsTab.isActive = true;
  },
  components: {
    Paginate,
    VueContext
  }
};
</script>

<style scoped>
.ti-plus {
  margin-right: 10px;
}
.btn-primary.ml-1.pull-right {
  height: 37px;
  display: flex;
  align-items: center;
  padding: auto 10px;
  padding: 0px 13px;
  border-radius: 3px;
}
</style>
