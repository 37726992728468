/*eslint-disable */
import {
  CURRENTUSER_LOAD_INFO,
  CURRENTUSER_UPDATE_USER_AVATAR,
  CURRENTUSER_LOGOUT,
  LOAD_API_KEYS,
  CURRENTUSER_REINSTALLATION,
  CURRENTUSER_UPDATE_FRANCHISES
} from '../../mutation-types'

export default {
  [CURRENTUSER_UPDATE_FRANCHISES](state, ui) {
    state.franchises = ui;
  },
  [CURRENTUSER_LOAD_INFO] (state, ui) {
    state.company_id = ui.company_id
    state.companyId = ui.companyId
    state.franchises = ui.franchises
    state.email = ui.email
    state.full_name = ui.full_name
    state.fullName = ui.fullName
    state.lastName = ui.lastName
    state.firstName = ui.firstName
    state.group_id = ui.group_id
    state.dmsId = ui.dmsId
    state.groupId = ui.groupId
    state.id = ui.id
    state.type = ui.type
    state.username = ui.username
    state.role = ui.role
    state.companyRole = ui.companyRole
    state.isActive = ui.isActive
    state.businessLocation = ui.businessLocation
    state.workPhone = ui.workPhone
    state.mobilePhone = ui.mobilePhone
    state.userPhoto = ui.userPhoto
    state.lastLoggedIn = ui.lastLoggedIn
    state.activeSessions = ui.activeSessions
    state.isEmailNoticeNewRfq = ui.isEmailNoticeNewRfq
    state.isEmailNoticeUpdatedPriceRequest = ui.isEmailNoticeUpdatedPriceRequest
    state.isEmailNoticeNewOrder = ui.isEmailNoticeNewOrder
    state.isSoundNoticeNewRfq = ui.isSoundNoticeNewRfq
    state.isSoundNoticeUpdatedPriceRequest = ui.isSoundNoticeUpdatedPriceRequest
    state.multipleBrands = ui?.multiBrands
    state.isEmailNoticeQuoteAssigned = ui.isEmailNoticeQuoteAssigned
    state.isSoundNoticeQuoteAssigned = ui.isSoundNoticeQuoteAssigned
  },
  [LOAD_API_KEYS] (state, ui) {
    state.apiKeys = ui
  },
  [CURRENTUSER_REINSTALLATION] (state) {
    state.apiKeys = []
    state.company_id = 0
    state.companyId = 0
    state.email = ''
    state.full_name = ''
    state.fullName = ''
    state.group_id = 0
    state.groupId = 0
    state.id = ''
    state.type = 'supplier'
    state.firstName = ''
    state.lastName = ''
    state.username = ''
    state.role = ''
    state.companyRole = ''
    state.dmsId = null
    state.isActive = true
    state.businessLocation = ''
    state.workPhone = ''
    state.mobilePhone = ''
    state.userPhoto = ''
    state.dmsId = null
    state.activeSessions = -1
    state.lastLoggedIn = ''
    state.isEmailNoticeNewRfq = false
    state.isEmailNoticeUpdatedPriceRequest = false
    state.isEmailNoticeNewOrder = false
    state.isSoundNoticeNewRfq = false
    state.isSoundNoticeUpdatedPriceRequest = false
    state.multipleBrands = []
    state.franchises = []
  },
  [CURRENTUSER_UPDATE_USER_AVATAR] (state, ui) {
    state.userPhoto = ui
  },
  [CURRENTUSER_LOGOUT] (state) {
    state = {
      company_id: 0,
      companyId: 0,
      email: '',
      full_name: '',
      fullName: '',
      group_id: 0,
      groupId: 0,
      id: '',
      type: 'none',
      firstName: '',
      lastName: '',
      username: '',

      role: '',
      companyRole: '',
      isActive: true,
      businessLocation: '',
      workPhone: '',
      mobilePhone: '',
      userPhoto: '',
      activeSessions: -1,
      lastLoggedIn: '',
      isEmailNoticeNewRfq: false,
      isEmailNoticeQuoteAssigned: false,
      isSoundNoticeQuoteAssigned: false,
      isEmailNoticeUpdatedPriceRequest: false,
      isEmailNoticeNewOrder: false,
      isSoundNoticeNewRfq: false,
      isSoundNoticeUpdatedPriceRequest: false,
      multipleBrands: [],
      franchises: [],
    }
  },

}
