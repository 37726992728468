/* eslint-disble */
export const Mutations = {
  showToast(state, ui) {
    state.toastOptions = ui.toastOptions;
    state.toastContent = ui.toastContent;
    state.toastShowCount++
  },
  changeLoadStatus (state, ui) {
    state.isLoadedApp = ui;
  },

  setReinstateStatus (state, ui) {
    state.isStartedReinstate = ui;
  },

  loadUsers(state, ui) {
    state.users = ui;
  },
  loadDashboardSummary(state, ui) {
    state.dashboardSummary = ui;
  },

  addFileIdInCardEdit (state, id) {
    state.fileIdsInCardEdit.push(id)
  },

  updateFileIdsInCardEdit (state, idArray) {
    state.fileIdsInCardEdit = idArray;
  },

  clearFileIdsInCardEdit (state) {
    state.fileIdsInCardEdit = [];
  },

  addFileInCardEdit (state, file) {
    state.filesInCardEdit.push(file);
  },

  removeFileIdInCardEdit(state, id) {
    state.fileIdsInCardEdit.splice(id, 1);
  },

  removeFileInCardEditById (state, id) {
    state.filesInCardEdit.splice(id, 1);
  },

  unshiftFileInCardEdit (state, file) {
    state.filesInCardEdit.unshift(file);
  },

  updateFiles (state, files) {
    state.filesInCardEdit = files;
  },

  updateDataInCardEdit (state, {uid, v}) {
    state.updateData[uid] = v;
  },

  deleteUpdateDataByField (state, field) {
    delete state.updateData[field];
  },

  clearUpdateDataByField (state, field) {
    state.updateData[field] = null;
  }
}
