//AUTH
export const AUTH = 'auth';
export const AUTH_LOGIN_REQUEST = 'authLoginRequest';
export const AUTH_LOGIN_SUCCESS = 'authLoginSuccess';
export const AUTH_LOGIN_FAILD = 'authLoginFaild';
export const AUTH_TOKEN_EXPIRE = 'authTokenExpire';
export const AUTH_LOGOUT_REQUEST = 'authLogoutRequest';
export const AUTH_LOGOUT_SUCCESS = 'authLogoutSuccess';
export const AUTH_LOGOUT_FAILD = 'authLogoutFaild';
export const AUTH_GOT_401 = 'authGot401';

export const CURRENTUSER_LOAD_INFO = 'currentUserLoadInfo';
export const CURRENTUSER_UPDATE_FRANCHISES = 'currentUserUpdateFranchises';
export const CURRENTUSER_REINSTALLATION = 'reinstallation';
export const CURRENTUSER_UPDATE = 'currentUserUpdate';
export const CURRENTUSER_UPDATE_USER_AVATAR = 'currentUserUpdateUserAvatar';
export const CURRENTUSER_LOGOUT = 'currentUserLogout';
export const LOAD_API_KEYS = 'loadApiKeys';

export const CURRENTCOMPANY_LOAD_INFO = 'currentCompanyLoadInfo';
export const CURRENTCOMPANY_APPEND_NEW_LOCATION = 'currentCompanyAppendNewLocation';
export const CURRENTCOMPANY_LOAD_DETAILS = 'currentCompanyLoadDetails';
export const CURRENTCOMPANY_LOAD_CONTACTS = 'currentCompanyLoadContacts';
export const CURRENTCOMPANY_LOAD_LOCATIONS = 'currentCompanyLoadLocations';
export const CURRENTCOMPANY_LOGOUT = 'currentCompanyLogout';
export const CURRENTCOMPANY_CHANGE_RFQ_TIME_NOTIFICATION = 'updateRfqTimeNotification';
export const CURRENTCOMPANY_CHANGE_RFQ_IMPORT_QUOTE_OPTIONS = 'updateRfqImportQuoteOptions';
export const CURRENTCOMPANY_REINSTALLATION = 'reinstallation';
export const CURRENTCOMPANY_SET_TIMEZONE = 'setTimeZone';

export const REPAIRER_CREDIT_IDS_UPDATE_APPROVED = 'repairerCreditIdsUpdateApproved';
export const REPAIRER_CREDIT_IDS_UPDATE_DENIED = 'repairerCreditIdsUpdateDenied';
export const REPAIRER_CREDIT_IDS_UPDATE_UNDER_REVIEW = 'repairerCreditIdsUpdateUnderReview';
export const REPAIRER_CREDIT_UPDATED = 'repairerCreditUpdated';
export const REPAIRER_CREDIT_ALL_CURRENT_PAGE = 'repairerCreditAllCurrentPage';
export const REPAIRER_CREDIT_UNDER_CURRENT_PAGE = 'repairerCreditUnderCurrentPage';
export const REPAIRER_CREDIT_APPROVED_CURRENT_PAGE = 'repairerCreditApprovedCurrentPage';
export const REPAIRER_CREDIT_DENIED_CURRENT_PAGE = 'repairerCreditDeniedCurrentPage';
export const REPAIRER_CREDIT_CANCELLED_CURRENT_PAGE = 'repairerCreditCancelledCurrentPage';
export const REPAIRER_CREDIT_PER_PAGE = 'repairerCreditPerPage';
export const REPAIRER_CREDIT_FILTER = 'repairerCreditFilter';

export const PART_LIST_LOAD_INFO = 'partListLoadInfo';
export const PART_LIST_REINSTALLATION = 'reinstallation';
export const BRAND_LIST_LOAD = 'brandListLoad';
export const BRAND_LIST_REINSTALLATION = 'reinstallation';


export const REPAIRER_RFQ_IDS_ALL_STATUS_REQUEST_SEND = '';
export const REPAIRER_RFQ_IDS_ALL_STATUS_REQUEST_SUCCESS = '';
export const REPAIRER_RFQ_IDS_ALL_STATUS_REQUEST_FAILD = '';

export const REPAIRER_RFQ_IDS_UPDATE_RECEIVED = 'repairerRFQIdsUpdateReceived';
export const REPAIRER_RFQ_IDS_UPDATE_SUBMITTED = 'repairerRFQIdsUpdateSubmitted';
export const REPAIRER_RFQ_IDS_UPDATE_PRICED = 'repairerRFQIdsUpdatePriced';
export const REPAIRER_RFQ_IDS_UPDATE_ORDERED = 'repairerRFQIdsUpdateOrdered';
export const REPAIRER_RFQ_IDS_UPDATE_CANCELLED = 'repairerRFQIdsUpdateCancelled';
export const REPAIRER_ORDERS_FILTER_UPDATE = 'repairerOrdersFilterUpdate';
export const REPAIRER_SET_CURRENT_ORDER_ID = 'repairerSetCurrentOrderId';
export const REPAIRER_SET_IS_NEED_UPDATE = 'repairerSetIsNeedUpdate';
export const REPAIRER_UPDATE_CURRENT_ORDER = 'repairerUpdateCurrentOrder';

export const REPAIRER_ORDER_IDS_UPDATE_ALL = 'repairerOrderIdsUpdateAll';
export const REPAIRER_ORDER_IDS_UPDATE_OPEN = 'repairerOrderIdsUpdateOpen';
export const REPAIRER_ORDER_UPDATED = 'repairerOrderUpdated';
export const REPAIRER_ORDERS_UPDATE_ALL = 'repairerOrdersUpdateAll';
export const REPAIRER_ORDERS_PER_PAGE = 'repairerOrdersPerPage';
export const REPAIRER_CURRENT_ORDERS_CURRENT_PAGE = 'repairerCurrentOrdersCurrentPage';
export const REPAIRER_COMPLETED_ORDERS_CURRENT_PAGE = 'repairerCompletedOrdersCurrentPage';
export const REPAIRER_BACK_ORDERS_CURRENT_PAGE = 'repairerBackOrdersCurrentPage';
export const REPAIRER_ORDERS_SET_TOKEN = 'setToken';
export const REPAIRER_ORDERS_SET_TOKENS = 'setTokens';
export const REPAIRER_ORDERS_SET_SEARCH_FILTER_OBJ = 'setSearchFilterObj';

export const REPAIRER_RFQ_UPDATE_REQUEST_SEND = '';
export const REPAIRER_RFQ_UPDATE_REQUEST_SUCCESS = '';
export const REPAIRER_RFQ_FILTER_UPDATE = 'repairerRFQFilterUpdate';
export const REPAIRER_RFQ_UPDATE_REQUEST_FAILD = '';
export const REPAIRER_RFQ_UPDATED = 'repairerRFQUpdated';
export const REPAIRER_RFQ_DELETE = 'repairerRFQDelete';
export const REPAIRER_RFQ_STATUS = 'repairerRFQUpdateStatus';
export const REPAIRER_RFQ_SET_SEARCH_FILTER_OBJ = 'repairerSetSearchFilterObj';
export const REPAIRER_RFQ_SET_TOKEN = 'setToken';
export const REPAIRER_CURRENT_RFQ_UPDATE = 'repairerCurrentRFQUpdate';

export const REPAIRER_SET_MESSAGES = 'repairerSetMessages';
export const SUPPLIER_SET_MESSAGES = 'supplierSetMessages';
export const REPAIRER_UPDATE_MESSAGES = 'repairerUpdateMessages';
export const REPAIRER_UPDATE_FILES = 'repairerUpdateFiles';
export const SUPPLIER_UPDATE_FILES = 'supplierUpdateFiles';
export const REPAIRER_UPDATE_ACTIVITY = 'repairerUpdateActivity';
export const REPAIRER_SET_ACTIVITY = 'repairerSetActivity';
export const SUPPLIER_UPDATE_MESSAGES = 'supplierUpdateMessages';
export const SUPPLIER_UPDATE_ACTIVITY = 'supplierUpdateActivity';
export const SUPPLIER_SET_ACTIVITY = 'supplierSetActivity';
export const SUPPLIER_DASHBOARD_REINSTALLATION = 'supplierDashboardReinstallation';
export const REPAIRER_MARK_MESSAGE_AS_READ = 'repairerMarkMessageAsRead';
export const SUPPLIER_MARK_MESSAGE_AS_READ = 'supplierMarkMessageAsRead';

export const REPAIRER_SUPPLIER_UPDATE_REQUEST_SEND = '';
export const REPAIRER_SUPPLIER_UPDATE_REQUEST_SUCCESS = '';
export const REPAIRER_SUPPLIER_UPDATE_REQUEST_FAILD = '';
export const REPAIRER_SUPPLIER_IDS_UPDATE_PREFERED = 'repairerSupplierIdsUpdatePrefered';
export const REPAIRER_SUPPLIER_IDS_UPDATE_SPECIALIST = 'repairerSupplierIdsUpdateSpecialist';
export const REPAIRER_SUPPLIER_IDS_UPDATE_OTHER = 'repairerSupplierIdsUpdateOther';
export const REPAIRER_SUPPLIER_UPDATED = 'repairerSupplierUpdated';
export const REPAIRER_TIER_UPDATED = 'repaireTierUpdated';
export const REPAIRER_INSURER_UPDATED = 'repaireInsurerUpdated';
export const REPAIRER_INSURER_ADD_NEW = 'repaireInsurerAddNew';
export const REPAIRER_SUPPLIER_REMOVED = 'repairerSupplierRemoved'
export const REPAIRER_SUPPLIER_FULL_UPDATED = 'repairerSupplierFullUpdated';
export const REPAIRER_SUPPLIER_FULL_UPDATED_SUPPLIER_GROUP = 'repairerSupplierFullUpdatedSupplierGroup';
export const REPAIRER_SUPPLIER_PER_PAGE = 'repairerSupplierPerPage';
export const REPAIRER_SUPPLIER_PER_PAGE2 = 'repairerSupplierPerPage2';
export const REPAIRER_SUPPLIER_FILTER = 'repairerSupplierFilter';
export const REPAIRER_SUPPLIER_ALL_CURRENT_PAGE = 'repairerSupplierAllCurrentPage';
export const REPAIRER_SUPPLIER_PREFERED_CURRENT_PAGE = 'repairerSupplierPreferedCurrentPage';
export const REPAIRER_SUPPLIER_TIER_CURRENT_PAGE = 'repairerSupplierTierCurrentPage';

export const REPAIRER_RFQ_RECEIVED_CURRENT_PAGE = 'repairerRFQReceivedCurrentPage';
export const REPAIRER_RFQ_SUBMITTED_CURRENT_PAGE = 'repairerRFQSubmittedCurrentPage';
export const REPAIRER_RFQ_PRICED_CURRENT_PAGE = 'repairerRFQPricedCurrentPage';
export const REPAIRER_RFQ_ORDERED_CURRENT_PAGE = 'repairerRFQOrderedCurrentPage';
export const REPAIRER_RFQ_CANCELLED_CURRENT_PAGE = 'repairerRFQCancelledCurrentPage';
export const REPAIRER_RFQ_SET_ASSIGNED_USER = 'setAssignedUser';
export const REPAIRER_RFQ_SET_MESSAGE_STATUS = 'setMessageStatus';
export const REPAIRER_PER_PAGE = 'repairerPerPage';

export const LOAD_CURRENT_USER = 'loadCurrentUser';

export const SETTINGS_FETCH_ALL_USERS = 'settingsFetchAllUsers';
export const SETTINGS_UPDATE_VISIBLE_ALL_USERS = 'settingsUpdateVisibleAllUsers';
export const SETTINGS_SET_SELECTED_USER = 'settingsSetSelectedUser';
export const SETTINGS_APPEND_NEW_USER = 'settingsAppendNewUser';
export const SETTINGS_CLEAR_USERS = 'settingsClearUsers';
export const REINSTALLATION_USERS = 'reinstallation'

export const SETTINGS_UPDATED = 'allSettingsUpdated';
export const SETTINGS_REINSTALLATION = 'reinstallation';
export const SET_MULTIPLE_BRANDS = 'setMultipleBrands';
export const SETTINGS_PRICE_SELECTORS_UPDATED = 'allSettingsPriceSelectorsUpdated';
export const SETTINGS_RFQ_REMOVE_PART_FROM_NIL_SUPPLY_LIST = 'settingsRfqRemovePartFromNilSupply'
export const SETTINGS_RFQ_ADD_PART_FROM_NIL_SUPPLY_LIST = 'settingsRfqAddPartFromNilSupply'

export const SUPPLIER_CREDIT_IDS_UPDATE_APPROVED = 'supplierCreditIdsUpdateApproved';
export const SUPPLIER_CREDIT_IDS_UPDATE_DENIED = 'supplierCreditIdsUpdateDenied';
export const SUPPLIER_CREDIT_IDS_UPDATE_UNDER_REVIEW = 'supplierCreditIdsUpdateUnderReview';
export const SUPPLIER_CREDIT_UPDATED = 'supplierCreditUpdated';
export const SUPPLIER_CREDIT_SHORT_UPDATING = 'supplierCreditShortUpdating';
export const SUPPLIER_CREDIT_ALL_CURRENT_PAGE = 'supplierCreditAllCurrentPage';
export const SUPPLIER_CREDIT_UNDER_CURRENT_PAGE = 'supplierCreditUnderCurrentPage';
export const SUPPLIER_CREDIT_APPROVED_CURRENT_PAGE = 'supplierCreditApprovedCurrentPage';
export const SUPPLIER_CREDIT_DENIED_CURRENT_PAGE = 'supplierCreditDeniedCurrentPage';
export const SUPPLIER_CREDIT_CANCELLED_CURRENT_PAGE = 'supplierCreditCancelledCurrentPage';
export const SUPPLIER_CREDIT_PER_PAGE = 'supplierCreditPerPage';
export const SUPPLIER_CREDIT_FILTER = 'supplierCreditFilter';
export const SUPPLIER_CREDIT_SET_TOKEN = 'setToken';
export const SUPPLIER_CREDIT_SET_TOKENS = 'setTokens';
export const SUPPLIER_CREDIT_REINSTALLATION = 'reinstallation';
export const SUPPLIER_CREDIT_SET_SEARCH_FILTER_OBJ = 'setSearchFilterObj';

export const SUPPLIER_CUSTOMERS_LOAD_RFQ = 'supplierCustomersLoadRfq';
export const SUPPLIER_CUSTOMERS_UPDATED = 'supplierCustomersUpdated';
export const SUPPLIER_CUSTOMERS_SHORT_UPDATING = 'supplierCustomersShortUpdating';
export const SUPPLIER_CUSTOMERS_PER_PAGE = 'supplierCustomersPerPage';
export const SUPPLIER_CUSTOMER_REINSTALLATION = 'reinstallation';
export const SUPPLIER_CUSTOMERS_FILTER = 'supplierCustomersFilter';
export const SUPPLIER_CUSTOMERS_ALL_CURRENT_PAGE = 'supplierCustomersAllCurrentPage';
export const SUPPLIER_CUSTOMERS_PREFERED_CURRENT_PAGE = 'supplierCustomersPreferedCurrentPage';

export const SUPPLIER_RFQ_IDS_UPDATE_NEW = 'supplierRFQIdsUpdateNew';
export const SUPPLIER_RFQ_IDS_UPDATE_PRICED = 'supplierRFQIdsUpdatePriced';
export const SUPPLIER_RFQ_IDS_UPDATE_LOST = 'supplierRFQIdsUpdateLost';
export const SUPPLIER_RFQ_IDS_UPDATE_WON = 'supplierRFQIdsUpdateWon';
export const SUPPLIER_RFQ_IDS_UPDATE_CLOSED = 'supplierRFQIdsUpdateClosed';
export const SUPPLIER_RFQ_UPDATED = 'supplierRFQIdsUpdated';
export const SUPPLIER_RFQ_SHORT_UPDATING = 'supplierRFQShortUpdating';
export const SUPPLIER_RFQ_QUOTE_NBRS_UPDATE = 'supplierRFQQuoteNbrsUpdate';
export const SUPPLIER_RFQ_QUOTE_NBRS_CLEAR = 'supplierRFQQuoteNbrsClear';
export const SUPPLIER_RFQ_QUOTE_NBRS_REMOVE = 'supplierRFQQuoteNbrsRemove';
export const SUPPLIER_RFQ_DELETE = 'supplierRFQDelete';
export const SUPPLIER_RFQ_FILTER_UPDATE = 'supplierRFQFilterUpdate';
export const SUPPLIER_CURRENT_RFQ_UPDATE = 'supplierCurrentRFQUpdate';
export const SUPPLIER_RFQ_PRICED_CURRENT_PAGE = 'supplierRFQPricedCurrentPage';
export const SUPPLIER_RFQ_ALL_CURRENT_PAGE = 'supplierRFQAllCurrentPage';
export const SUPPLIER_RFQ_WON_CURRENT_PAGE = 'supplierRFQWonCurrentPage';
export const SUPPLIER_RFQ_ORDERED_CURRENT_PAGE = 'supplierRFQOrderedCurrentPage';
export const SUPPLIER_RFQ_NEW_CURRENT_PAGE = 'supplierRFQNewCurrentPage';
export const SUPPLIER_RFQ_CLOSED_CURRENT_PAGE = 'supplierRFQClosedCurrentPage';
export const SUPPLIER_RFQ_LOST_CURRENT_PAGE = 'supplierRFQLostCurrentPage';
export const SUPPLIER_PER_PAGE = 'supplierPerPage';
export const SUPPLIER_RFQ_SET_SEARCH_FILTER_OBJ = 'setSearchFilterObj';
export const SUPPLIER_RFQ_SET_TOKEN = 'setToken';
export const SUPPLIER_RFQ_SET_TOKENS = 'setTokens';
export const SUPPLIER_RFQ_SET_VIEWING_USERS = 'setViewingUsers';
export const SUPPLIER_RFQ_SET_ASSIGNED_USER = 'setAssignedUser';
export const SUPPLIER_RFQ_SET_MESSAGE_STATUS = 'setMessageStatus';
export const SUPPLIER_RFQ_SET_PRIORITY = 'setPriority';
export const SUPPLIER_RFQ_MARK_AS_READ = 'markAsRead';
export const SUPPLIER_RFQ_SET_SCHEDULE_TIME = 'setScheduleTime';
export const SUPPLIER_RFQ_REINSTALLATION = 'reinstallation';
export const SUPPLIER_RFQ_UPDATE_PIN_IMAGE = 'updatePinImage';

export const SUPPLIER_ORDER_IDS_UPDATE_OPEN = 'supplierRFQIdsUpdateOpen';
export const SUPPLIER_ORDER_IDS_UPDATE_PROCESSING = 'supplierRFQIdsUpdateProcessing';
export const SUPPLIER_ORDER_IDS_UPDATE_PARTS_ON_ROUTE = 'supplierRFQIdsUpdatePartsShipped';
export const SUPPLIER_ORDER_IDS_UPDATE_PARTS_DELIVERED = 'supplierRFQIdsUpdatePartsDelivered';
export const SUPPLIER_ORDER_IDS_UPDATE_COMPLETED = 'supplierRFQIdsUpdateCompleted';
export const SUPPLIER_ORDERS_UPDATED = 'supplierOrdersIdsUpdated';
export const SUPPLIER_ORDERS_SHORT_UPDATING = 'supplierOrdersShortUpdating';
export const SUPPLIER_REINSTALLATION = 'reinstallation';
export const SUPPLIER_ORDERS_FILTER_UPDATE = 'supplierOrdersFilterUpdate';
export const SUPPLIER_ORDERS_SET_TOKEN = 'setToken';
export const SUPPLIER_ORDERS_SET_SEARCH_FILTER_OBJ = 'setSearchFilterObj';
export const SUPPLIER_ORDERS_SET_ORDER_STATUS = 'setOrderStatus';
export const SUPPLIER_ORDERS_SET_TOKENS = 'setTokens'

export const SUPPLIER_ORDERS_ALL_CURRENT_PAGE = 'supplierOrdersAllCurrentPage';
export const SUPPLIER_ORDERS_OPEN_CURRENT_PAGE = 'supplierOrdersOpenCurrentPage';
export const SUPPLIER_ORDERS_PARTIAL_DELIVERY_CURRENT_PAGE = 'supplierOrdersPartialDeliveryCurrentPage';
export const SUPPLIER_ORDERS_PROCESSING_CURRENT_PAGE = 'supplierOrdersProcessingCurrentPage';
export const SUPPLIER_ORDERS_ROUTE_CURRENT_PAGE = 'supplierOrdersRouteCurrentPage';
export const SUPPLIER_ORDERS_DELIVERED_CURRENT_PAGE = 'supplierOrdersDeliveredCurrentPage';
export const SUPPLIER_ORDERS_COMPLETED_CURRENT_PAGE = 'supplierOrdersCompletedCurrentPage';
export const SUPPLIER_ORDERS_PER_PAGE_ALL = 'supplierOrdersPerPageAll';
export const SUPPLIER_ORDERS_PER_PAGE_OPEN = 'supplierOrdersPerPageOpen';
export const SUPPLIER_ORDERS_PER_PAGE_PARTIAL_DELIVERY = 'supplierOrdersPerPagePartialDelivery';
export const SUPPLIER_ORDERS_PER_PAGE_PROCESSING = 'supplierOrdersPerPageProcessing';
export const SUPPLIER_ORDERS_PER_PAGE_ROUTE = 'supplierOrdersPerPageRoute';
export const SUPPLIER_ORDERS_PER_PAGE_DELIVERED = 'supplierOrdersPerPageDelivered';
export const SUPPLIER_ORDERS_PER_PAGE_COMPLETED = 'supplierOrdersPerPageCompleted';
